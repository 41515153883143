import { FC, useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { useQueryClient } from "react-query";

import SuccessAnimation from "../../assets/files/Stars.json";
import { ReactComponent as FailedTransferIcon } from "../../assets/images/svg/failed-transfer.svg";
import { ReactComponent as PendingTransferIcon } from "../../assets/images/svg/pending-transfer-icon.svg";
import { ReactComponent as CloseModalIcon } from "../../assets/images/svg/close-icon.svg";
import { ModalContainer } from "../../modal/ModalContainer";
import BlueButton from "../../component/Button/BlueButton";
import { currencyFormat } from "../../helpers/formatter";

type stateType = {
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  status: string;
  setStatus: (newState: string) => void;
  transactionData: any;
  setTransactionData: (newState: object) => void;
  setUploadStage: (newState: string) => void;
  setViewTransfer: (newState: boolean) => void;
  onSubmit: any;
  loading: boolean;
  totalAmount: number;
  setSelectedAccount: (newState: string) => void;
};

const BulkTransferStatus: FC<stateType> = ({
  showModal,
  setShowModal,
  status,
  setStatus,
  transactionData,
  setTransactionData,
  setUploadStage,
  setViewTransfer,
  onSubmit,
  loading,
  totalAmount,
  setSelectedAccount,
}) => {
  const [transferStatus, setTransferStatus] = useState("");
  const queryClient = useQueryClient();

  const handleClose = () => {
    setShowModal(false);
    // if (transactionData?.message === "processing") {
    setShowModal(false);
    setViewTransfer(false);
    setUploadStage("");
    setSelectedAccount('')
    queryClient.invalidateQueries("getBulkTransferList");
    queryClient.invalidateQueries("getGroupedTransferList");
    // }
  };

  useEffect(() => {
    if (
      transactionData &&
      transactionData?.message?.toLowerCase()?.includes("success")
    ) {
      setTransferStatus("success");
    } else if (
      (transactionData &&
        transactionData?.message === "Transfer is being processed") ||
      (transactionData &&
        transactionData?.message?.toLowerCase() === "processing") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes("processed")) ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes("processing")) ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes("pending"))
    ) {
      setTransferStatus("processing");
    } else setTransferStatus("error");
  }, [transactionData]);

  const renderStatus = () => {
    if (
      transactionData &&
      transactionData?.message?.toLowerCase()?.includes("success")
    ) {
      return <SuccessfulTransaction />;
    }

    if (
      (transactionData &&
        transactionData?.message === "Transfer is being processed") ||
      (transactionData &&
        transactionData?.message?.toLowerCase() === "processing") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes("processed")) ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes("processing")) ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes("pending"))
    ) {
      return <PendingTransaction />;
    } else return <FailedTransaction />;
  };

  const SuccessfulTransaction = () => {
    return (
      <div className="w-full lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 flex flex-col items-center text-center p-10 rounded-[8px] bg-white">
        <div className="flex items-center justify-center h-auto relative">
          <Player
            autoplay
            controls
            loop
            src={SuccessAnimation}
            style={{
              width: "200px",
              height: "120px",
            }}
          />
          <p className="absolute top-[30%] right-[25%] text-2xl text-aellaBlue">
            Successful
          </p>
        </div>
        <div
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={handleClose}
          // onClick={() => setShowModal(false)}
        >
          <CloseModalIcon />
        </div>
        <p className="text-[#0B1E4B] text-2xl mt-8">Transaction Successful</p>
        <p className="text-[#5B5B5B] text-base mt-4 font-light">
          Your transfer total amount of ${currencyFormat(totalAmount)} <br />{" "}
          has been sent successfully.
        </p>
        <BlueButton
          title="View Transfer Details"
          onClick={handleClose}
          className="px-12 text-[#F3F5FC] mt-10"
        />

        <p
          onClick={handleClose}
          className="mt-5 text-[#2054D2] font-medium cursor-pointer text-base"
        >
          Go Back
        </p>
      </div>
    );
  };

  const PendingTransaction = () => {
    return (
      <div className="w-full lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 flex flex-col items-center text-center p-10 rounded-[8px] bg-white">
        <PendingTransferIcon />
        <div
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={handleClose}
        >
          <CloseModalIcon />
        </div>
        <p className="text-[#0B1E4B] text-2xl mt-8">Transaction Processing</p>
        <p className="text-[#5B5B5B] text-base mt-4 font-light">
          Your transaction is still being processed, <br /> you will be notified
          when it is successful!
        </p>
        <BlueButton
          title="Back Home"
          onClick={handleClose}
          className="px-12 text-[#F3F5FC] mt-10"
        />
      </div>
    );
  };

  const FailedTransaction = () => {
    return (
      <div className="w-full lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 flex flex-col items-center text-center p-10 rounded-[8px] bg-white">
        <div className="flex items-center justify-center h-auto relative">
          <FailedTransferIcon />
        </div>
        <div
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={handleClose}
        >
          <CloseModalIcon />
        </div>
        <p className="text-[#0B1E4B] text-2xl mt-8">Transaction Unsuccessful</p>
        <p className="text-[#5B5B5B] text-base mt-4 font-light">
          Your transfer was unsuccessful!
        </p>
        <BlueButton
          title={loading ? "Sending" : "Retry"}
          disabled={loading}
          onClick={onSubmit}
          className="px-12 text-[#F3F5FC] mt-10"
        />
      </div>
    );
  };

  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        handleClose();
        setSelectedAccount("");
        // resetMyForm();
      }}
    >
      {renderStatus()}
    </ModalContainer>
  );
};

export default BulkTransferStatus;
