import React from "react";

export default function WithdrawalAccountCard({ details }: any) {
  const { bank_logo, account_name, bank_name, account_number } = details;
  return (
    <>
      <div className="flex items-center border border-[#C5D4F6] rounded-lg py-[13px] px-[18px] mt-2 cursor-pointer">
        <img src={bank_logo} alt="" className="w-[40px] h-[40px]" />
        <div className="flex flex-col pl-4 ">
          <div className="text-[15px] font-[300] leading-[17px] text-[#011C34] capitalize">
            {account_name}
          </div>
          <div className="text-[14px] font-[100] leading-[150%] text-[#333333] pt-[5px]">
            {bank_name}: {account_number}
          </div>
        </div>
      </div>
    </>
  );
}
