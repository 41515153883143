import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getMerchantDetails = async () => {
  // const { userId } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_DETAILS}`
  );
  return data;
};

export const useMerchantDetails = ({ userId }: { userId: string }) => {
  return useQuery(
    ["getMerchantDetails", { userId }],

    getMerchantDetails,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: userId ? true : false,
    }
  );
};
