import { useState } from "react";
import { useForm } from "react-hook-form";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../helpers/yupValidators";
import * as yup from "yup";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { RegistrationFormData } from "../../interfaces/login";
import { Loader } from "../../component/Loader/Loader.component";
import { postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";

const schema = yup.object().shape({
  email: yupValidators.email,
});
interface IResetPassword {
  setStep: (newState: number) => void;
  setState: any;
  state: {
    email: string;
    newPassword: string;
    confirmNewPassword: string;
  };
}


const ResetStepOne: React.FC<IResetPassword> = ({
  setStep,
  setState,
  state,
}) => {
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({ email }: { email: string }) => {
    setLoading(true);

    const reqBody = { email_or_phone_number: email };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL1}${apiEndpoints.INITIATE_RESET_PASSWORD}`,
        reqBody
      );
      setStep(2);
      setState({
        ...state,
        email,
      });
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <div className="md:pt-10 lg:pt-[120px] md:pr-10 pl-6 lg:pr-[196px]">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-7">
            <h3 className="text-[26px] md:text-[32px] lg:leading-[38px] font-[300] pb-2">
              Recover Password
            </h3>
            <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
              Forgot your password? Don’t worry! <br></br>
              You can recover it using the email linked to your account.
              <br></br> Enter your email below and we'll send instructions to
              reset your password.
            </p>
          </div>
          <div className="">
            <CustomInputField
              type="email"
              maxLength={128}
              placeholder="User@gmail.com"
              label="Work Email"
              showRequiredIcon
              {...register("email")}
            />
          </div>
          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center w-full flex justify-center mt-[30px] cursor-pointer"
            disabled={loading}
          >
            Reset Password
            <span className="pl-2">
              <NextIcon />{" "}
            </span>
            {loading && <Loader />}
          </button>
          <p className="flex items-center justify-center my-8 text-[16px] lg:leading-[20px] font-[300]">
            <span className="text-aellaGray pr-2">Remember your password?</span>
            <Link className="text-aellaBlue" to={ROUTES.LOGIN}>
              Sign In
            </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default ResetStepOne;
