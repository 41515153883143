import Drawer from "../../../../component/Drawer/Drawer";
import CustomInputField from "../../../../component/CustomHTMLElements/CustomInputField";
import { phoneRegExp, yupValidators } from "../../../../helpers/yupValidators";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../component/Loader/Loader.component";

const schema = yup.object().shape({
  merchantName: yupValidators.firstName,
  customerFirstName: yupValidators.firstName,
  customerLastName: yupValidators.firstName,
  merchantAddress: yup
    .string()
    .trim()
    .required("Please enter your phone number")
    .min(5, "Email must have at least 5 characters")
    .max(128, "Email cannot have more than 128 characters"),
  customerEmail: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .min(5, "Email must have at least 5 characters")
    .max(128, "Email cannot have more than 128 characters"),
  phoneNumber: yup
    .string()
    .trim()
    .required("Please enter your phone number")
    .test(
      "len",
      "phone number must be exactly 11 characters",
      (val) => val?.length === 11
    )
    .matches(phoneRegExp, "Invalid Phone Number"),
});

export type createType = {
  customerFirstName: string;
  customerLastName: string;
  customerEmail: string;
  phoneNumber: string;
  merchantName: string;
  merchantAddress: string;
};
export default function CreateSubAccountDrawer({
  isOpen,
  setIsOpen,
  onSubmit,
  setLoading,
  loading,
}: {
  isOpen: boolean;
  loading: boolean;
  setIsOpen: (newState: boolean) => void;
  setLoading: (newState: boolean) => void;
  onSubmit: (obj: createType) => void;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<createType>({
    resolver: yupResolver(schema),
  });
  const submit = (payload: createType) => {
    setLoading(true);
    onSubmit(payload);
  };
  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        <div className="flex justify-between items-center border-b pb-[17px]">
          <div className="text-[#0B1E4B] font-[200]  text-[24px] leading-[29px] tracking-[0.2px]">
            Create New Sub-account
          </div>
          <div
            onClick={() => {
              setIsOpen(false);
            }}
            className="cursor-pointer"
          >
            <svg
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="32" height="32" rx="16" fill="white" />
              <rect
                x="11.7305"
                y="18.6338"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="9.59766"
                y="20.7671"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 11.7305 14.3672)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 9.59766 12.2334)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 13.8633 16.5005)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 15.9961 18.6338)"
                fill="#6D6D6D"
              />
              <rect
                x="18.1328"
                y="12.2334"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="20.2617"
                y="10.1001"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 18.1328 20.7671)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 20.2617 22.9009)"
                fill="#6D6D6D"
              />
            </svg>
          </div>
        </div>
        <div className="mt-2 mb-[32px]">
          <p className="text-[16px] lg:leading-[24px] font-[200] text-aellaGray">
            Provide the information below to create a new sub account.
          </p>
        </div>
        <div className="flex flex-col">
          <form onSubmit={handleSubmit(submit)}>
            <p className="text-[16px] leading-[16px] font-[200] tracking-[0.03em] mb-2.5">
              Sub Account Details
            </p>
            <div className="w-full flex flex-col border border-[#EDEFF5] pt-6 px-6 rounded-lg">
              <div className="w-full">
                <div className="w-full">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    placeholder="Enter Name"
                    label="Name"
                    errors={errors.merchantName}
                    showRequiredIcon
                    {...register("merchantName")}
                  />
                </div>
              </div>
              <div className="w-full">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  placeholder="Enter address"
                  label="Address"
                  showRequiredIcon
                  errors={errors.merchantAddress}
                  {...register("merchantAddress")}
                />
              </div>
            </div>
            <p className="text-[16px] leading-[16px] font-[200] tracking-[0.03em] mt-6 mb-2.5">
              User Details
            </p>
            <div className="w-full flex flex-col border border-[#EDEFF5] pt-6 px-6 rounded-lg">
              <div className="w-full ">
                <div className="flex justify-between items-center">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    placeholder="Enter first name"
                    label="First Name"
                    errors={errors.customerFirstName}
                    showRequiredIcon
                    {...register("customerFirstName")}
                  />
                  <div className="pl-2">
                    <CustomInputField
                      type="text"
                      maxLength={128}
                      placeholder="Enter last name"
                      label="Last Name"
                      showRequiredIcon
                      errors={errors.customerLastName}
                      {...register("customerLastName")}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <CustomInputField
                    type="email"
                    maxLength={128}
                    placeholder="Enter email address"
                    label="Email Address"
                    showRequiredIcon
                    errors={errors.customerEmail}
                    {...register("customerEmail")}
                  />
                </div>
              </div>

              <div className="w-full">
                <CustomInputField
                  type="number"
                  maxLength={11}
                  placeholder="08100000000"
                  label="Phone Number"
                  errors={errors.phoneNumber}
                  showRequiredIcon
                  {...register("phoneNumber")}
                  hasIcon="+234"
                />
              </div>
            </div>

            <div className="flex justify-end px-2.5">
              <button
                type="submit"
                className="bg-aellaBlue rounded text-white py-[15px] px-[25px] items-center flex justify-center cursor-pointer mt-8"
              >
                Save
                {loading && <Loader />}
              </button>
            </div>
          </form>
        </div>
      </>
    </Drawer>
  );
}
