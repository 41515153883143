import { useEffect, useState } from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import Pagination from "../../component/Pagination/Pagination.component";
import { useTransfersData } from "./Transfers";
import { formatMoney } from "../../helpers/formatMoney";
import { useBulkList } from "../../hooks/useTransfersList";
import EmptyTransfer from "./EmptyTransfer";
import { SecondaryLoader } from "../../component/Loader/SecondaryLoader";

const BulkTransfer = () => {
  const {
    setShowAction,
    setViewTransfer,
    setSelectedBulkTransfer,
    merchantDetails,
    uploadStage,
    viewTransfer,
  } = useTransfersData();
  const [listRes, setListRes] = useState<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(13);
  const [searchParams, setSearchParams] = useSearchParams({});

  const { data: bulkTransferList, isLoading: bulkIsLoading } = useBulkList({
    page: currentPage,
    limit: postsPerPage,
  });

  useEffect(() => setShowAction(false), []);

  useEffect(() => {
    const newData = [];
    if (bulkTransferList?.data?.data?.length > 0) {
      for (let data of bulkTransferList?.data?.data) {
        if (
          data?.transaction_type === null ||
          data?.transaction_type === undefined
        ) {
          if (data?.description) {
            let transaction_type = {} as any;
            let desObj = JSON.parse(data?.description);
            transaction_type.name = desObj?.mode;
            data.transaction_type = transaction_type;
          } else {
            data.transaction_type = "N/A";
          }
        }
        newData.push(data);
      }
      bulkTransferList.data.data = newData;
      setListRes(bulkTransferList);
    }
  }, [bulkTransferList]);

  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.page) {
      setCurrentPage(Number(currentParams?.page));
    }

    if (!currentParams?.page && !currentParams?.size) {
      setSearchParams({
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
      });
    }
  }, [searchParams]);

  if (bulkIsLoading) {
    return <SecondaryLoader count={10} />;
  }

  if (!bulkIsLoading && !bulkTransferList?.data?.data?.length) {
    return <EmptyTransfer setShowAction={setShowAction} />;
  }

  if (!bulkIsLoading && !uploadStage && !viewTransfer) {
    return (
      <div
        onClick={() => setShowAction(false)}
        className="bg-[#F6F8FA]  w-auto md:w-full h-full overflow-y-auto overflow-x-auto md:overflow-x-hidden hide_scrollbar mt-3 md:mt-0"
      >
        <div className="mx-4 md:mx-10 mt-4 mb-14 2xl:mb-20">
          <div className="flex items-center bg-[#F7F9FC] w-[140%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-base border-b">
            <p className="w-[14rem] md:w-[25%]">Number of Recipients</p>
            <p className="w-[10rem] md:w-[26%]">Total Amount</p>
            <p className="w-[10rem] md:w-[30%]">Created By</p>
            <p className=" w-[14rem] md:flex-1 text-right">Date Created</p>
          </div>
          {bulkTransferList?.data?.data?.map((list: any) => {
            const { group_id, no_recipient, total_amount, transaction_date } =
              list;
            return (
              <div
                key={group_id}
                onClick={() => {
                  setSelectedBulkTransfer(list);
                  setViewTransfer(true);
                }}
                className="flex items-center bg-white w-[140%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
              >
                <p className="w-[14rem] md:w-[25%]">{no_recipient}</p>
                <p className="w-[10rem] md:w-[26%]">{`₦${formatMoney(
                  total_amount
                )}`}</p>
                <p className={`w-[10rem] md:w-[30%] capitalize`}>
                  {merchantDetails?.first_name?.toLowerCase() +
                    " " +
                    merchantDetails?.last_name?.toLowerCase()}
                </p>
                <p className=" w-[14rem] md:flex-1 text-right">
                  {moment(transaction_date).format("DD MMM YYYY, LT")}
                </p>
              </div>
            );
          })}

          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={bulkTransferList?.data?.total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            totalPages={bulkTransferList?.data?.total}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default BulkTransfer;
