import Drawer from "../../../component/Drawer/Drawer";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { phoneRegExp, yupValidators } from "../../../helpers/yupValidators";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import CustomTextArea from "../../../component/CustomHTMLElements/CustomTextArea";
import attachmentIcon from "../../../assets/images/svg/attachment-blue-icon.svg";
import deleteIcon from "../../../assets/images/svg/delete-icon.svg";
import { AcceptDisputeType } from "../Tab/DisputesDetails";
import fileIcon from "../../../assets/images/svg/file-blue-icon.svg";
import { Loader } from "../../../component/Loader/Loader.component";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";
import { truncateString } from "../../../helpers/formatter";
import { useEffect } from "react";

const refundOptions = [
  { id: "Full Payment", name: "Full Payment" },
  { id: "Partial Payment", name: "Partial Payment" },
];
const schema = yup.object().shape({
  customerName: yupValidators.firstName,
  email: yup
    .string()
    .trim()
    .email("Please enter a valid email address")
    .min(5, "Email must have at least 5 characters")
    .max(128, "Email cannot have more than 128 characters"),
  phoneNumber: yup
    .string()
    .trim()
    .required("Please enter your phone number")
    .test(
      "len",
      "phone number must be exactly 11 characters",
      (val) => val?.length === 11
    )
    .matches(phoneRegExp, "Invalid Phone Number"),
  details: yup.string().trim().required("Please enter the deivery details"),
});

export default function AcceptDisputeDrawer({
  isOpen,
  setIsOpen,
  attachment,
  setAttachment,
  amount,
  submitHandler,
  loading,
  merchantName,
}: {
  isOpen: boolean;
  loading: boolean;
  setIsOpen: (newState: boolean) => void;
  attachment: string[];
  setAttachment: (newState: any) => void;
  submitHandler: (newState: object) => void;
  merchantName: any;
  amount: string;
}) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<AcceptDisputeType>({
    resolver: yupResolver(schema),
  });

  const refundOptionSelected = watch("refundOption");
  const deliveryDate = watch("deliveryDate");

  useEffect(() => {
    setValue("amount", amount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  const onFileChange = async ({ e, id }: { e: any; id: string }) => {
    const fileName = e.target.files[0].name;
    const fileAcceptType = e.target.files[0].type;
    const isValidFile =
      fileAcceptType.startsWith("image/png") ||
      fileAcceptType.startsWith("image/jpg") ||
      fileAcceptType.startsWith("image/jpeg");
    if (!isValidFile) {
      toast.error(
        "Invalid file extension. Kindly upload a png, jpeg or jpg file"
      );
      return (e.target.value = null);
    }

    const fileBase64 = await convertToBase64(e.target.files[0]);
    const extension = fileName.split(".").pop();

    let newFile = {} as {
      fileName: string;
      extension: string;
      photo: string;
      id: string;
    };
    newFile = {
      fileName,
      extension,
      photo: fileBase64,
      id,
    };

    let newFiles = [...attachment, newFile];
    setAttachment(newFiles);
  };

  const deleteFile = (id: string) => {
    const remainingRequests = attachment.filter((file: any) => file.id !== id);
    setAttachment([...remainingRequests]);
  };

  const onSubmit = async ({
    customerName,
    customerEmail,
    phoneNumber,
    deliveryDate,
    deliveryAddress,
    refundOption,
    details,
    amount,
    reason,
  }: AcceptDisputeType) => {
    const attachments = attachment.map((file: any) => file.photo);
    const req = {
      customer_name: customerName,
      customer_email: customerEmail,
      phone_number: phoneNumber,
      delivery_date: deliveryDate,
      delivery_address: deliveryAddress,
      refundOption,
      details,
      amount: Number(amount),
      reason,
      attachments,
      authorized_by: merchantName,
    };
    submitHandler(req);
  };

  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        <div className="flex justify-between items-center border-b pb-[17px]">
          <div className="text-[#0B1E4B] font-[200]  text-[24px] leading-[29px] tracking-[0.2px]">
            Accept Dispute
          </div>
          <div
            onClick={() => {
              setIsOpen(false);
              setAttachment([]);
            }}
            className="cursor-pointer"
          >
            <svg
              width="32"
              height="33"
              viewBox="0 0 32 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect y="0.5" width="32" height="32" rx="16" fill="white" />
              <rect
                x="11.7305"
                y="18.6338"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="9.59766"
                y="20.7671"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 11.7305 14.3672)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 9.59766 12.2334)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 13.8633 16.5005)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 15.9961 18.6338)"
                fill="#6D6D6D"
              />
              <rect
                x="18.1328"
                y="12.2334"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                x="20.2617"
                y="10.1001"
                width="2.13343"
                height="2.13343"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 18.1328 20.7671)"
                fill="#6D6D6D"
              />
              <rect
                width="2.13343"
                height="2.13343"
                transform="matrix(1 0 0 -1 20.2617 22.9009)"
                fill="#6D6D6D"
              />
            </svg>
          </div>
        </div>
        <div className="mt-8 mb-[12px] mx-2.5">
          <p className="text-[18px] lg:leading-[22px] font-[200]">
            Step 1 - Provide the following information
          </p>
        </div>
        <div className="flex flex-col">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="w-full flex justify-between flex-wrap px-2.5">
              <div className=" w-full flex">
                <div className="mr-[23px] w-full">
                  <CustomInputField
                    type="text"
                    maxLength={128}
                    placeholder="Enter Name"
                    label="Customer Name"
                    errors={errors.customerName}
                    showRequiredIcon
                    {...register("customerName")}
                  />
                </div>
                <div className="w-full">
                  <CustomInputField
                    type="email"
                    maxLength={128}
                    placeholder="Customer Email"
                    label="Email"
                    errors={errors.customerEmail}
                    {...register("customerEmail")}
                  />
                </div>
              </div>
              <div className="w-full flex">
                <div className="mr-[23px] w-full">
                  <CustomInputField
                    type="number"
                    maxLength={11}
                    placeholder="08100000000"
                    label="Phone Number"
                    errors={errors.phoneNumber}
                    showRequiredIcon
                    {...register("phoneNumber")}
                  />
                </div>
                <div className="flex flex-col w-full">
                  <label className="text-aellaBlack lg:leading-[18px] text-[16px] font-[300] mb-2">
                    Date item was delivered to
                  </label>
                  <DatePicker
                    dateFormat="yyyy-MM-dd"
                    placeholderText={"Select date"}
                    selected={deliveryDate}
                    maxDate={new Date()}
                    onChange={(date: Date) => {
                      setValue("deliveryDate", date);
                    }}
                    className="h-[48px] w-full rounded pl-2 border placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] lg:leading-[24px] text-[16px] font-[200]"
                  />
                </div>
              </div>
              <div className=" w-full">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  label="Details of product/service provided"
                  placeholder="Enter details"
                  errors={errors.details}
                  showRequiredIcon
                  {...register("details")}
                />
              </div>
              <div className=" w-full">
                <CustomInputField
                  type="text"
                  maxLength={128}
                  label="Address item was delivered to"
                  placeholder="Enter address"
                  errors={errors.deliveryAddress}
                  {...register("deliveryAddress")}
                />
              </div>
              <p className="text-[18px] lg:leading-[22px] font-[200] w-full mt-6">
                Step 2 - By accepting this dispute, the refund amount would be
                deducted from wallet.
              </p>
              <div className="mt-3 w-full">
                <CustomSelectDropdown
                  label="Refund Option"
                  errors={errors.refundOption}
                  {...register("refundOption")}
                >
                  <option value="">select a refund option</option>
                  {refundOptions?.map((data: any) => {
                    const { id, name } = data;
                    return (
                      <option value={id} key={id}>
                        {name}
                      </option>
                    );
                  })}
                </CustomSelectDropdown>
              </div>
              <div className="w-full mb-[18px] lg:mb-[25px]">
                <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                  Enter Amount
                </p>

                <div className="flex">
                  <span className="inline-flex items-center px-3 text-sm bg-[#E5B7A0] rounded-l-md border border-r-0 border-[#D9D9D9]">
                    &#8358;
                  </span>
                  <input
                    type="number"
                    placeholder="0.00"
                    className=" placeholder:text-eallaGray rounded-none rounded-r bg-white border min-w-0 w-full text-[16px] lg:leading-[20px] font-[300] border-[#D9D9D9] p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-aellaGray dark:text-white dark:focus:ring-aellaBlue dark:focus:border-aellaBlue disabled:text-aellaGray"
                    disabled={
                      refundOptionSelected === "Full Payment" ||
                      !refundOptionSelected
                    }
                    defaultValue={amount}
                    {...register("amount")}
                  />
                </div>
              </div>
              {refundOptionSelected === "Partial Payment" && (
                <div className=" w-full">
                  <CustomTextArea
                    maxLength={50}
                    label="Reason for Partial Refund"
                    {...register("reason")}
                    placeholder="Enter reason here"
                  />
                </div>
              )}
              <label
                htmlFor="file"
                className="min-w-[50%] h-12 inline-flex items-center gap-4 rounded-[3px] border-aellaBlue border-dashed border-[1px] px-3 py-1 cursor-pointer"
              >
                <img
                  src={attachmentIcon}
                  alt="Attachment Icon"
                  width={20}
                  height={20}
                />
                <p className="text-[14px] lg:leading-[17px] text-aellaBlue font-[200]">
                  Upload Document (s)
                </p>
                <input
                  type="file"
                  name="file"
                  id="file"
                  accept="image/*"
                  className="hidden"
                  onChange={(e) =>
                    onFileChange({
                      e,
                      id: nanoid(),
                    })
                  }
                />
              </label>

              {attachment?.length > 0 && (
                <div className="w-full mt-4">
                  <p className="text-[14px] leading-[16px] font-[200]">
                    Attachments
                  </p>
                  <div className="w-full mt-2 flex flex-wrap  gap-x-4 items-center">
                    {attachment?.map((file: any) => (
                      <div
                        className="flex items-center gap-x-1 border p-4 rounded border-aellaBlue mt-2"
                        key={file.id}
                      >
                        <img
                          src={fileIcon}
                          alt="File Icon"
                          width={15}
                          height={18}
                        />
                        <p className="text-aellaBlue text-[14px] leading-[16px] font-[200] pr-1">
                          {truncateString(file.fileName, 10)}
                          {file.extension}
                        </p>
                        <img
                          src={deleteIcon}
                          alt=""
                          className=" cursor-pointer"
                          width={15}
                          height={18}
                          onClick={() => deleteFile(file.id)}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="flex justify-end px-2.5">
              <button
                type="submit"
                className="bg-aellaBlue rounded text-white py-[15px] px-[19.5px] items-center flex justify-center cursor-pointer mt-8"
              >
                Accept Dispute
                {loading && <Loader />}
              </button>
            </div>
          </form>
        </div>
      </>
    </Drawer>
  );
}