import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import {
  IProfileStepTwo,
  ProfileSetUpFormData,
} from "../../../interfaces/profileSetUp";
import { Loader } from "../../../component/Loader/Loader.component";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const schema = yup.object().shape({
  bvnEmail: yupValidators.bvnEmail,
  bvn: yupValidators.bvn,
  bvnFirstName: yupValidators.firstName,
  bvnLastName: yupValidators.lastName,
});

const ProfileStepTwo: React.FC<IProfileStepTwo> = ({
  submitHandler,
  state,
  merchantProfileData,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProfileSetUpFormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (merchantProfileData) {
      setValue("bvn", merchantProfileData?.bvn);
      setValue("bvnEmail", merchantProfileData?.bvn_email_or_phone_number);
      setValue("bvnFirstName", merchantProfileData?.bvn_first_name);
      setValue("bvnLastName", merchantProfileData?.bvn_last_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantProfileData]);

  const onSubmit = async ({
    bvn,
    bvnFirstName,
    bvnLastName,
    bvnEmail,
  }: {
    bvn: number;
    bvnLastName: string;
    bvnEmail: string;
    bvnFirstName: string;
  }) => {
    const reqBody = {
      merchant: {
        stage: 2,
        merchant_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID),
      },
      stage_two: {
        bvn: bvn,
        bvn_first_name: bvnFirstName,
        bvn_last_name: bvnLastName,
        bvn_email_or_phone_number: bvnEmail,
      },
    };
    await submitHandler(reqBody);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Business Owner
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
          This is the details of the owner of this business
        </p>

        <div className="flex flex-col lg:flex-row">
          <div className=" w-full lg:pr-6">
            <CustomInputField
              type="text"
              maxLength={128}
              label="First Name"
              errors={errors.bvnFirstName}
              {...register("bvnFirstName")}
              placeholder="First Name"
              readOnly={merchantProfileData?.bvn_first_name}
            />
          </div>
          <div className="w-full">
            <CustomInputField
              type="text"
              maxLength={128}
              label="Last Name"
              errors={errors.bvnLastName}
              {...register("bvnLastName")}
              placeholder="Last Name"
              readOnly={merchantProfileData?.bvn_last_name}
            />
          </div>
        </div>

        <CustomInputField
          type="email"
          maxLength={128}
          label="Email"
          errors={errors.bvnEmail}
          {...register("bvnEmail")}
          placeholder="Email"
          readOnly={merchantProfileData?.bvn_email_or_phone_number}
        />

        <CustomInputField
          type="number"
          maxLength={11}
          label="BVN"
          errors={errors.bvn}
          {...register("bvn")}
          placeholder="BVN"
          readOnly={merchantProfileData?.bvn}
        />
        <div className="flex justify-between">
          <div></div>
          <div></div>
          <div></div>
          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex xxl:mt-7 mb-28 lg:mb-0 justify-center cursor-pointer"
            disabled={state.loading}
          >
            Save and Continue
            {state.loading && <Loader />}
          </button>
        </div>
      </form>
    </>
  );
};
export default ProfileStepTwo;
