import { Link, useLocation, useSearchParams } from "react-router-dom";
import { ReactComponent as HomeIllustration } from "../../assets/images/svg/home-illustration.svg";
import { ReactComponent as EditIcon } from "../../assets/images/svg/edit-icon.svg";
import { ReactComponent as NextIcon } from "../../assets/images/svg/black-next-arrow-icon.svg";
import LinkIcon from "../../assets/images/svg/link-icon.svg";
import { toast } from "react-toastify";
import { useContext, useEffect, useState } from "react";
import { useWalletBalance } from "../../hooks/useWalletBalance";
import { useWalletTransactionSummary } from "../../hooks/useWalletTransactionSummary";
import { useWalletTransactions } from "../../hooks/useWalletTransactions";
import { ROUTES } from "../../helpers/routes";
import HomeData from "./HomeData";
import { SearchStateType, SearchType } from "../../interfaces/home";
import CardSkeletonLoader from "../../component/Skeleton/CardSkeletonLoader.component";
import GenerateWithdrawalAccountModals from "./Modal/GenerateWithdrawalAccountModals";
import WithdrawalAccountDrawer from "./Drawal/WithdrawalAccountDrawer";
import { errorHandler } from "../../helpers/errorHandler";
import CONFIG from "../../helpers/config";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import {
  getFromLocalStorage,
  postToLocalStorage,
} from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import DateRangeModal from "../../modal/DateRangeModal";
import { AuthContext } from "../../context/auth";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import moment from "moment";

export type DetailObjectType = {
  account_number: string;
  bank_name: string;
  account_name: string;
  id: string;
  bank_id: string;
};
const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
const Home = () => {
  const { merchantCntxtStatus, setMerchantCntxtStatus } =
    useContext(AuthContext);
  const merchantStatus = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTSTATUS);
  const profileSuccess = getFromLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP);
  const [currentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const { state } = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [detail, setDetail] = useState<DetailObjectType>({
    account_number: "",
    account_name: "",
    bank_name: "",
    id: "",
    bank_id: "",
  });
  const [loading, setLoading] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(0);
  const [withdrawalDescription, setWithdrawalDescription] = useState("");
  const [loadingTrnxPin, setLoadingTrnxPin] = useState(false);

  const { data: merchantDetails } = useMerchantDetails({ userId });

  const { profile } = Object(state) as {
    profile?: boolean | null;
  };
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  const [trnxMessage, setTrnxMessage] = useState("");
  const [totalTransactionSum, setTotalTransactionSum] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [showOutFlowModal, setShowOutFlowModal] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({});

  const [searchState, setSearchState] = useState<SearchStateType>({
    volOfTrnxServices: "",
    valOfTrnxServices: "",
    startDateForOutFlow: new Date(year, month, 1),
    endDateForOutFlow: new Date(),
    startDateForTrnx: new Date(year, month, 1),
    endDateForTrnx: new Date(),
  });
  const [modalType, setModalType] = useState("");

  const [inflowVolSearch, setInflowVolSearch] = useState<SearchType>({
    type: "cr",
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
    walletId: searchState.volOfTrnxServices,
    mode: "merchant",
  });
  const [outflowVolSearch, setOutflowVolSearch] = useState<SearchType>({
    type: "dr",
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
    walletId: searchState.volOfTrnxServices,
    mode: "merchant",
  });
  const [outflowValSearch, setOutflowValSearch] = useState<SearchType>({
    type: "dr",
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
    walletId: searchState.valOfTrnxServices,
    mode: "merchant",
  });
  const [inflowValSearch, setInflowValSearch] = useState<SearchType>({
    type: "cr",
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
    walletId: searchState.valOfTrnxServices,
    mode: "merchant",
  });
  const [outFlowSummarySearch, setOutFlowSummarySearch] = useState<SearchType>({
    type: "dr",
    startDate: searchState.startDateForOutFlow,
    endDate: searchState.endDateForOutFlow,
    mode: "merchant",
  });
  const [inFlowSearchForTdy] = useState<SearchType>({
    type: "cr",
    startDate: new Date(),
    endDate: new Date(),
    mode: "merchant",
  });

  const { data: totalWalletData } = useWalletBalance({ mode: "merchant" });

  const { data: walletInFlowSummaryForTdy } =
    useWalletTransactionSummary(inFlowSearchForTdy);

  const { data: walletOutFlowSummaryData } =
    useWalletTransactionSummary(outFlowSummarySearch);

  const { data: walletOutFlowSummaryForVolOfTrnx } =
    useWalletTransactionSummary(outflowVolSearch);

  const { data: walletInFlowSummaryForVolOfTrnx } =
    useWalletTransactionSummary(inflowVolSearch);

  const { data: walletInFlowSummaryForValOfTrnx } =
    useWalletTransactionSummary(inflowValSearch);

  const { data: walletInFlowTransactions, isLoading: walletInFlowTrnxLoading } =
    useWalletTransactions({
      type: "cr",
      mode: "merchant",
      page: currentPage,
      size: postsPerPage,
    });

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (
      currentParams?.startDateForTrnx ||
      currentParams?.endDateForTrnx ||
      currentParams?.startDateForOutFlow ||
      currentParams?.endDateForOutFlow ||
      currentParams?.volOfTrnxServices ||
      currentParams?.valOfTrnxServices
    ) {
      setSearchState({
        volOfTrnxServices: currentParams?.volOfTrnxServices,
        valOfTrnxServices: currentParams?.valOfTrnxServices,
        startDateForOutFlow: new Date(currentParams?.startDateForOutFlow),
        endDateForOutFlow: new Date(currentParams?.endDateForOutFlow),
        startDateForTrnx: new Date(currentParams?.startDateForTrnx),
        endDateForTrnx: new Date(currentParams?.endDateForTrnx),
      });
    }
  }, [searchParams]);
  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.volOfTrnxServices) {
      setInflowVolSearch({
        ...inflowVolSearch,
        walletId: currentParams?.volOfTrnxServices,
      });
      setOutflowVolSearch({
        ...outflowVolSearch,
        walletId: currentParams?.volOfTrnxServices,
      });
    }
  }, [searchParams, inflowVolSearch, outflowVolSearch]);

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.valOfTrnxServices) {
      setInflowValSearch({
        ...inflowValSearch,
        walletId: currentParams?.valOfTrnxServices,
      });
      setOutflowValSearch({
        ...outflowValSearch,
        walletId: currentParams?.valOfTrnxServices,
      });
    }
  }, [searchParams, inflowValSearch, outflowValSearch]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (
      !currentParams?.startDateForTrnx ||
      !currentParams?.endDateForTrnx ||
      !currentParams?.startDateForOutFlow ||
      !currentParams?.endDateForOutFlow
    ) {
      setSearchParams({
        startDateForOutFlow: moment(searchState?.startDateForOutFlow).format(
          "YYYY-MM-DD"
        ),
        endDateForOutFlow: moment(searchState?.endDateForOutFlow).format(
          "YYYY-MM-DD"
        ),
        startDateForTrnx: moment(searchState?.startDateForTrnx).format(
          "YYYY-MM-DD"
        ),
        endDateForTrnx: moment(searchState?.endDateForTrnx).format(
          "YYYY-MM-DD"
        ),
      });
    }

    // if (
    //   (!currentParams?.startDateForTrnx ||
    //     !currentParams?.endDateForTrnx ||
    //     !currentParams?.startDateForOutFlow ||
    //     !currentParams?.endDateForOutFlow) &&
    //   !searchState?.volOfTrnxServices &&
    //   !searchState?.valOfTrnxServices
    // ) {
    //   setSearchParams({
    //     startDateForOutFlow: moment(searchState?.startDateForOutFlow).format(
    //       "YYYY-MM-DD"
    //     ),
    //     endDateForOutFlow: moment(searchState?.endDateForOutFlow).format(
    //       "YYYY-MM-DD"
    //     ),
    //     startDateForTrnx: moment(searchState?.startDateForTrnx).format(
    //       "YYYY-MM-DD"
    //     ),
    //     endDateForTrnx: moment(searchState?.endDateForTrnx).format(
    //       "YYYY-MM-DD"
    //     ),
    //   });
    // }
    // if (
    //   (!currentParams?.startDateForTrnx ||
    //     !currentParams?.endDateForTrnx ||
    //     !currentParams?.startDateForOutFlow ||
    //     !currentParams?.endDateForOutFlow) &&
    //   searchState?.volOfTrnxServices &&
    //   searchState?.valOfTrnxServices
    // ) {
    //   setSearchParams({
    //     volOfTrnxServices: searchState?.volOfTrnxServices,
    //     valOfTrnxServices: searchState?.valOfTrnxServices,
    //     startDateForOutFlow: moment(searchState?.startDateForOutFlow).format(
    //       "YYYY-MM-DD"
    //     ),
    //     endDateForOutFlow: moment(searchState?.endDateForOutFlow).format(
    //       "YYYY-MM-DD"
    //     ),
    //     startDateForTrnx: moment(searchState?.startDateForTrnx).format(
    //       "YYYY-MM-DD"
    //     ),
    //     endDateForTrnx: moment(searchState?.endDateForTrnx).format(
    //       "YYYY-MM-DD"
    //     ),
    //   });
    // }
    // if (
    //   (!currentParams?.startDateForTrnx ||
    //     !currentParams?.endDateForTrnx ||
    //     !currentParams?.startDateForOutFlow ||
    //     !currentParams?.endDateForOutFlow) &&
    //   searchState?.volOfTrnxServices &&
    //   !searchState?.valOfTrnxServices
    // ) {
    //   setSearchParams({
    //     volOfTrnxServices: searchState?.volOfTrnxServices,
    //     startDateForOutFlow: moment(searchState?.startDateForOutFlow).format(
    //       "YYYY-MM-DD"
    //     ),
    //     endDateForOutFlow: moment(searchState?.endDateForOutFlow).format(
    //       "YYYY-MM-DD"
    //     ),
    //     startDateForTrnx: moment(searchState?.startDateForTrnx).format(
    //       "YYYY-MM-DD"
    //     ),
    //     endDateForTrnx: moment(searchState?.endDateForTrnx).format(
    //       "YYYY-MM-DD"
    //     ),
    //   });
    // }
    // if (
    //   (!currentParams?.startDateForTrnx ||
    //     !currentParams?.endDateForTrnx ||
    //     !currentParams?.startDateForOutFlow ||
    //     !currentParams?.endDateForOutFlow) &&
    //   !searchState?.volOfTrnxServices &&
    //   searchState?.valOfTrnxServices
    // ) {
    //   setSearchParams({
    //     valOfTrnxServices: searchState?.valOfTrnxServices,
    //     startDateForOutFlow: moment(searchState?.startDateForOutFlow).format(
    //       "YYYY-MM-DD"
    //     ),
    //     endDateForOutFlow: moment(searchState?.endDateForOutFlow).format(
    //       "YYYY-MM-DD"
    //     ),
    //     startDateForTrnx: moment(searchState?.startDateForTrnx).format(
    //       "YYYY-MM-DD"
    //     ),
    //     endDateForTrnx: moment(searchState?.endDateForTrnx).format(
    //       "YYYY-MM-DD"
    //     ),
    //   });
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchState.startDateForTrnx,
    searchState.endDateForTrnx,
    searchState?.startDateForOutFlow,
    searchState?.endDateForOutFlow,
    searchParams,
  ]);

  // Summate value of transactions for outflow and inflow
  useEffect(() => {
    setTotalTransactionSum(walletInFlowSummaryForValOfTrnx?.sum);
  }, [walletInFlowSummaryForValOfTrnx]);

  // Summate volume of transactions for outflow and inflow
  useEffect(() => {
    setTotalTransactions(
      walletInFlowSummaryForVolOfTrnx?.count +
        walletOutFlowSummaryForVolOfTrnx?.count
    );
  }, [walletInFlowSummaryForVolOfTrnx, walletOutFlowSummaryForVolOfTrnx]);

  useEffect(() => {
    if (profileSuccess === true) {
      toast.success(
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1
            style={{
              fontSize: "16px",
              color: "#232323",
              lineHeight: "19px",
              letterSpacing: "0.2px",
              fontWeight: "400",
            }}
          >
            Account Setup Completed
          </h1>
          <p
            style={{
              fontSize: "16px",
              color: "#232323",
              lineHeight: "17px",
              letterSpacing: "0.2px",
              fontWeight: "50",
            }}
          >
            Your account is now up to date
          </p>
        </div>,
        {
          position: "top-center",
          icon: ({ theme, type }) => <img src={LinkIcon} alt="" />,
          closeButton: false,
          pauseOnFocusLoss: false,
          hideProgressBar: true,
          style: {
            marginTop: "60px",
            backgroundColor: "#F0D5C7",
            marginLeft: "50px",
            borderRadius: "6px",
          },
        }
      );

      postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, false);
    }
  }, [profileSuccess]);

  //update volume of transaction query by service
  useEffect(() => {
    if (searchState.volOfTrnxServices) {
      setInflowVolSearch({
        ...inflowVolSearch,
        walletId: searchState.volOfTrnxServices,
      });
      setOutflowVolSearch({
        ...outflowVolSearch,
        walletId: searchState.volOfTrnxServices,
      });
    } else {
      setInflowVolSearch({
        ...inflowVolSearch,
        walletId: "",
      });
      setOutflowVolSearch({
        ...outflowVolSearch,
        walletId: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.volOfTrnxServices]);

  //update value of transaction query by service
  useEffect(() => {
    if (searchState.valOfTrnxServices) {
      setInflowValSearch({
        ...inflowValSearch,
        walletId: searchState.valOfTrnxServices,
      });
      setOutflowValSearch({
        ...outflowValSearch,
        walletId: searchState.valOfTrnxServices,
      });
    } else {
      setInflowValSearch({
        ...inflowValSearch,
        walletId: "",
      });
      setOutflowValSearch({
        ...outflowValSearch,
        walletId: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.valOfTrnxServices]);

  //update outflow query by date range
  useEffect(() => {
    setOutFlowSummarySearch({
      ...outFlowSummarySearch,
      startDate: searchState.startDateForOutFlow,
      endDate: searchState.endDateForOutFlow,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDateForOutFlow, searchState.endDateForOutFlow]);

  //update transaction query by date range
  useEffect(() => {
    setInflowVolSearch({
      ...inflowVolSearch,
      startDate: searchState.startDateForTrnx,
      endDate: searchState.endDateForTrnx,
    });
    setOutflowVolSearch({
      ...outflowVolSearch,
      startDate: searchState.startDateForTrnx,
      endDate: searchState.endDateForTrnx,
    });
    setOutflowValSearch({
      ...outflowValSearch,
      startDate: searchState.startDateForTrnx,
      endDate: searchState.endDateForTrnx,
    });
    setInflowValSearch({
      ...inflowValSearch,
      startDate: searchState.startDateForTrnx,
      endDate: searchState.endDateForTrnx,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDateForTrnx, searchState.endDateForTrnx]);

  useEffect(() => {
    if (merchantDetails?.status === 1) {
      postToLocalStorage(
        LOCAL_STORAGE_KEYS.MERCHANTSTATUS,
        merchantDetails.status
      );
      setMerchantCntxtStatus(merchantDetails.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantDetails]);

  const onChange = (e: any) => {
    const { name, value } = e.target;
    setSearchState({ ...searchState, [name]: value });
    const allParams: any = {
      ...searchState,
      startDateForOutFlow: moment(searchState?.startDateForOutFlow).format(
        "YYYY-MM-DD"
      ),
      endDateForOutFlow: moment(searchState?.endDateForOutFlow).format(
        "YYYY-MM-DD"
      ),
      startDateForTrnx: moment(searchState?.startDateForTrnx).format(
        "YYYY-MM-DD"
      ),
      endDateForTrnx: moment(searchState?.endDateForTrnx).format("YYYY-MM-DD"),
      [name]: value,
    };

    Object.keys(allParams).forEach(
      (key) => allParams[key] === undefined && delete allParams[key]
    );

    setSearchParams(allParams);
  };
  // function not needed
  const onWalletTransferSubmit = (pin: string) => {};

  const onSubmit = async (pin: string) => {
    setLoading(true);
    setLoadingTrnxPin(true);
    const reqBody = {
      amount: withdrawalAmount,
      narration: withdrawalDescription,
      account_number: detail?.account_number,
      bank_id: detail?.bank_id,
      account_name: detail?.account_name,
      code: pin,
    };
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.WITHDRAW_FROM_WALLET}`,
        reqBody
      );
      setTrnxMessage(res.message);
      setModalType("step4");
    } catch (error) {
      toast.error(errorHandler(error));
      setLoading(false);
    }
    setLoading(false);
    setLoadingTrnxPin(false);
  };

  if (walletInFlowTrnxLoading) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[120px]">
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="bg-white">
        {(merchantCntxtStatus === 1 || merchantStatus === "1") && (
          <HomeData
            totalWalletData={totalWalletData}
            walletInFlowSummaryForTdy={walletInFlowSummaryForTdy}
            walletOutFlowSummaryData={walletOutFlowSummaryData}
            walletInFlowTransactions={walletInFlowTransactions}
            totalTransactionSum={totalTransactionSum}
            totalTransactions={totalTransactions}
            searchState={searchState}
            onChange={onChange}
            setShowOutFlowModal={setShowOutFlowModal}
            setShowTransactionModal={setShowTransactionModal}
            setShowWithdrawalModal={setModalType}
          />
        )}
        {(merchantCntxtStatus === 2 || merchantStatus === "2") && (
          <div className="pl-10 pr-8 lg:px-10  bg-aellaDimGray">
            <div className="translate-y-44 flex flex-col items-center">
              <HomeIllustration />
              <h1 className="text-[26px] lg:text-[32px] lg:leading-[38px] tracking-[0.2px] font-[300] py-6">
                Dashboard loading...
              </h1>
              {profile ? (
                <>
                  <h1 className="text-[16px] lg:leading-[19px] tracking-[0.2px] text-aellaGray font-[100] items-center">
                    We are currently setting up your dashboard.
                  </h1>
                  <h1 className="text-[16px] lg:leading-[19px] tracking-[0.2px] text-aellaGray font-[100] items-center">
                    Take a break and check back later.
                  </h1>
                </>
              ) : (
                <Link to={ROUTES.PROFILE_SETUP}>
                  <div className="border flex flex-row border-[#6D91E9] rounded-[8px] bg-[#C5D4F6] p-4 items-start w-[300px] lg:w-[429px] flex-shrink-0 cursor-pointer">
                    <div className=" w-[16px] h-[16px] pr-4 lg:pr-2">
                      <EditIcon />
                    </div>
                    <div className="flex flex-col px-2">
                      <h1 className="text-[16px] leading-[16px] lg:leading-[14px] font-[300]">
                        Complete Your Account Setup
                      </h1>
                      <p className="text-[13px] lg:leading-[21px] font-[100] pt-2 ">
                        Your account is currently on test mode. Kindly complete
                        your account setup to go live..
                      </p>
                    </div>
                    <div className=" w-[16px] h-[32px]">
                      <NextIcon />
                    </div>
                  </div>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>

      {showOutFlowModal && (
        <DateRangeModal
          startDate={searchState.startDateForOutFlow}
          setSearchState={setSearchState}
          searchState={searchState}
          endDate={searchState.endDateForOutFlow}
          setCloseModal={setShowOutFlowModal}
          startDateName="startDateForOutFlow"
          endDateName="endDateForOutFlow"
          showModal={showOutFlowModal}
        />
      )}
      {showTransactionModal && (
        <DateRangeModal
          startDate={searchState.startDateForTrnx}
          setSearchState={setSearchState}
          endDate={searchState.endDateForTrnx}
          searchState={searchState}
          setCloseModal={setShowTransactionModal}
          startDateName="startDateForTrnx"
          endDateName="endDateForTrnx"
          showModal={showTransactionModal}
        />
      )}
      {modalType && (
        <GenerateWithdrawalAccountModals
          modalType={modalType}
          setModalType={setModalType}
          setIsOpen={setIsOpen}
          setDetail={setDetail}
          submitTransferPin={onWalletTransferSubmit}
          withdrawalAmount={withdrawalAmount}
          onSubmitTrnxPin={onSubmit}
          loadingTrnxPin={loadingTrnxPin}
          trnxMessage={trnxMessage}
          detail={detail}
        />
      )}
      {isOpen && (
        <WithdrawalAccountDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          detail={detail}
          setModalType={setModalType}
          setWithdrawalAmount={setWithdrawalAmount}
          withdrawalAmount={withdrawalAmount}
          setWithdrawalDescription={setWithdrawalDescription}
          loading={loading}
        />
      )}
    </>
  );
};
export default Home;
