import { ReactComponent as Copy } from "../../../assets/images/svg/copy-icon.svg";
// import { ReactComponent as Eye } from "../../../assets/images/svg/eye-icon.svg";
// import { ReactComponent as Link } from "../../../assets/images/svg/linking-icon.svg";
import GenerateAPIModal from "../Modal/GenerateAPIModal";
import { useState } from "react";
import { ROUTES } from "../../../helpers/routes";
import Tab from "../../../component/Tab/Tab.component";
import { useApiKey } from "../../../hooks/useKeyServices";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";

const WebHooks = () => {
  const [openModal, setOpenModal] = useState(false);
  const [copyTest, setCopyTest] = useState(false);
  const [copyLive, setCopyLive] = useState(false);

  const { data, isLoading: keyDataLoading } = useApiKey();

  if (keyDataLoading) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
        <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
          <div className="flex justify-between bg-white items-center pt-2 pb-[1px] flex-wrap border-t border-[#F1F1F1] w-[200%] xs:w-[165%] sm:w-full">
            <div className="flex ">
              <Tab text="Profile" path={ROUTES.SETTINGS_PROFILE} />
              <Tab text="Security" path={ROUTES.SETTINGS_SECURITY} />
              <Tab text="Notifications" path={ROUTES.SETTINGS_NOTIFICATIONS} />
              <Tab
                text="Withdrawal Bank Accounts"
                path={ROUTES.SETTINGS_WITHDRAWAL}
              />
              <Tab text="API Keys" path={ROUTES.SETTINGS_API} />
            </div>
          </div>
        </div>
        <div className="py-[32px] px-[10px] lg:p-10">
          <div className="bg-white border border-[#EDEFF5] p-[10px] lg:p-10 pt-[30px] min-h-[70vh]">
            <div className="">
              <div className="pb-[12px] border-b border-[#EDEFF5]">
                <p className="text-[24px] text-[#0B1E4B]">API Keys </p>
              </div>
            </div>

            <div className="mt-[40px] w-full md:w-[60%] flex flex-col gap-[24px]">
              <div>
                <label className="text-[14px] text-[#232323] font-thin">
                  Live Secret Key
                </label>
                <div className="relative">
                  <input
                    type="text"
                    readOnly
                    defaultValue={data?.live_secretkey}
                    className="h-[40px] w-full outline-none bg-[#EDEFF5] mt-[8px] text-[14px] text-[#0B1E4B] text-ellipsis font-thin pl-[16px] border border-[#E6E9F3] truncate rounded-[8px] pr-[4rem]"
                  />
                  {copyLive ? (
                    <p className="absolute cursor-pointer right-[16px] top-[50%] text-[12px]">
                      Copied
                    </p>
                  ) : (
                    <Copy
                      onClick={() => {
                        setCopyLive(true);
                        setCopyTest(false);
                        navigator.clipboard.writeText(data?.live_secretkey);
                      }}
                      className="absolute cursor-pointer right-[16px] top-[50%]"
                    />
                  )}
                </div>
                {/* <button
                onClick={() => setOpenModal(true)}
                className="text-[#2054D2] text-[14px]"
              >
                Regenerate Key
              </button> */}
              </div>

              <div>
                <label className="text-[14px] text-[#232323] font-thin">
                  Test Secret Key
                </label>
                <div className="relative">
                  <input
                    type="text"
                    readOnly
                    defaultValue={data?.test_secretkey}
                    className="h-[40px] w-full outline-none bg-[#EDEFF5] mt-[8px] text-[14px] text-[#0B1E4B] font-thin pl-[16px] border border-[#E6E9F3] truncate rounded-[8px] pr-[4rem]"
                  />

                  {copyTest ? (
                    <p className="absolute cursor-pointer right-[16px] top-[50%] text-[12px]">
                      Copied
                    </p>
                  ) : (
                    <Copy
                      onClick={() => {
                        setCopyTest(true);
                        setCopyLive(false);
                        navigator.clipboard.writeText(data?.test_secretkey);
                      }}
                      className="absolute cursor-pointer right-[16px] top-[50%]"
                    />
                  )}
                </div>
              </div>

              {/* <div>
              <label className="text-[14px] text-[#232323] font-thin">
                Test Callback URL
              </label>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Enter Link"
                  className="h-[48px] w-full outline-none  mt-[8px] text-[14px] text-[#0B1E4B] font-thin pl-[40px] border border-[#2323232E] rounded-[8px]"
                />
                <Link className="absolute left-[15px] top-[50%]" />
              </div>
            </div> */}

              {/* <div>
              <label className="text-[14px] text-[#232323] font-thin">
                Test Webhook URL
              </label>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Enter URL"
                  className="h-[48px] w-full outline-none  mt-[8px] text-[14px] text-[#0B1E4B] font-thin pl-[40px] border border-[#2323232E] rounded-[8px]"
                />
                <Link className="absolute left-[15px] top-[50%]" />
              </div>
            </div> */}

              {/* <button className="px-[16px] py-[16px] w-[170px] rounded-[4px] text-white font-thin bg-[#2054D2] ">
              Save Generate
            </button> */}
            </div>
          </div>
        </div>
      </div>

      {openModal && (
        <GenerateAPIModal showModal={openModal} setCloseModal={setOpenModal} />
      )}
    </>
  );
};

export default WebHooks;
