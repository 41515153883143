import React, { FC } from "react";

type IModalContainerProps = {
  children: React.ReactNode | undefined;
  showModal: boolean;
  closeModal?: () => void;
  className?: string;
};

export const ModalContainer: FC<IModalContainerProps> = ({
  children,
  showModal,
  closeModal,
  className,
}) => {
  return (
    <div
      className={`fixed top-0 left-0 z-10 w-full h-screen flex justify-center items-center animation ${className} ${
        showModal ? "opacity-100 visible" : "opacity-0 invisible"
      }`}
    >
      {children}
      <div
        className="absolute top-0 left-0 w-full h-full bg-[#0B1E4B]/40 z-20"
        onClick={closeModal}
      />
    </div>
  );
};
