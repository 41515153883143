import React from "react";
import Logo from "../../assets/images/svg/aella-logo.svg";
import MobileLogo from "../../assets/images/svg/aella-mobile-logo.svg";
import LandingSvg from "../../assets/images/svg/landing-illustrator.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";

interface ILandingLayout {
  children: React.ReactNode;
  firstText: string;
  secondText: string;
}

const LandingLayout: React.FC<ILandingLayout> = ({
  children,
  firstText,
  secondText,
}) => {
  return (
    <main
      role="main"
      className=" bg-white flex h-auto overflow-hidden relative w-full"
    >
      <div className="bg-[#F6F8FA] hidden lg:flex flex-col w-[35%] py-[42px] fixed h-full">
        <div className="">
          <Link to={ROUTES.LOGIN}>
            <img className="pl-[64px]" src={Logo} alt="Aella Logo" />
          </Link>
          <p className="text-[32px] font-light text-aellaBlack leading-[44px] pl-[64px] pt-[87px]">
            {firstText}
            <br />
            {secondText}
          </p>
          <img className="pt-[18vh]" src={LandingSvg} alt="Illustration" />
        </div>
      </div>
      <div className="flex flex-col pt-[18px] xxl:pt-[70px]  top-0 right-0 lg:ml-[35%] relative overflow-hidden w-full">
        <div className=" px-[24px] lg:pl-[120px] xxl:pl-[160px] md:pl-[64px] ">
          <Link to={ROUTES.LOGIN}>
            <img
              className="mb-[41px] lg:hidden"
              src={MobileLogo}
              alt="Login Illustration"
            />
          </Link>
        </div>

        <div className=" px-[24px] lg:pl-[146px] xxl:pl-[160px] md:pl-[64px] overflow-y-auto">
          {children}
        </div>
      </div>
    </main>
  );
};
export default LandingLayout;
