import { ReactComponent as CalendarIcon } from "../../../assets/images/svg/calendar-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/black-arrow-down-icon.svg";
import { ReactComponent as WalletIcon } from "../../../assets/images/svg/wallet3-icon.svg";
import { useWalletBalance } from "../../../hooks/useWalletBalance";
import { currencyFormat } from "../../../helpers/formatter";
import { useEffect, useState } from "react";
import { SearchStateType, SearchType } from "../../../interfaces/home";
import { useWalletTransactionSummary } from "../../../hooks/useWalletTransactionSummary";
import moment from "moment";
import DateRangeModal from "../../../modal/DateRangeModal";
import { useMerchantServices } from "../../../hooks/useMerchantServices";
import { useAllMerchantServices } from "../../../hooks/useAllMerchantServices";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { useSearchParams } from "react-router-dom";

export default function BalanceOverview() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [totalServicesWalletSum, setTotalServicesWalletSum] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams({});

  const [searchState, setSearchState] = useState<SearchStateType>({
    startDateForTrnx: new Date(year, month, 1),
    endDateForTrnx: new Date(),
  });
  const {
    data: allMerchantWalletServices,
    isLoading: allMerchantWalletServicesLoading,
  } = useAllMerchantServices();

  useEffect(() => {
    let initialValue = 0;
    if (allMerchantWalletServices) {
      allMerchantWalletServices?.map(
        (eachData: any) =>
          (initialValue = eachData?.available_balance + initialValue)
      );
      setTotalServicesWalletSum(initialValue);
    }
  }, [allMerchantWalletServices]);

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.startDateForTrnx || currentParams?.endDateForTrnx) {
      setSearchState({
        startDateForTrnx: new Date(currentParams?.startDateForTrnx),
        endDateForTrnx: new Date(currentParams?.endDateForTrnx),
      });
    }
  }, [searchParams]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (!currentParams?.startDateForTrnx || !currentParams?.endDateForTrnx) {
      setSearchParams({
        startDateForTrnx: moment(searchState.startDateForTrnx).format(
          "YYYY-MM-DD"
        ),
        endDateForTrnx: moment(searchState.endDateForTrnx).format("YYYY-MM-DD"),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDateForTrnx, searchState.endDateForTrnx, searchParams]);

  const { data: totalWalletData, isLoading: totalWalletDataLoading } =
    useWalletBalance({ mode: "merchant" });

  const [inflowVolSearch, setInflowVolSearch] = useState<SearchType>({
    type: "cr",
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
    mode: "merchant",
  });

  const [outflowVolSearch, setOutflowVolSearch] = useState<SearchType>({
    type: "dr",
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
    mode: "merchant",
  });

  const {
    data: walletInFlowSummaryForVolOfTrnx,
    isLoading: walletInFlowSummaryForVolOfTrnxLoading,
  } = useWalletTransactionSummary(inflowVolSearch);

  const { data: walletOutFlowSummaryForVolOfTrnx } =
    useWalletTransactionSummary(outflowVolSearch);

  const { data: merchantServicesData, isLoading: merchantServicesDataLoading } =
    useMerchantServices();

  // Summate volume of transactions for outflow and inflow
  useEffect(() => {
    setTotalTransactions(
      walletInFlowSummaryForVolOfTrnx?.count +
        walletOutFlowSummaryForVolOfTrnx?.count
    );
  }, [walletInFlowSummaryForVolOfTrnx, walletOutFlowSummaryForVolOfTrnx]);

  //update transaction query by date range
  useEffect(() => {
    setInflowVolSearch({
      ...inflowVolSearch,
      startDate: searchState.startDateForTrnx,
      endDate: searchState.endDateForTrnx,
    });
    setOutflowVolSearch({
      ...outflowVolSearch,
      startDate: searchState.startDateForTrnx,
      endDate: searchState.endDateForTrnx,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDateForTrnx, searchState.endDateForTrnx]);
  if (
    allMerchantWalletServicesLoading ||
    totalWalletDataLoading ||
    walletInFlowSummaryForVolOfTrnxLoading ||
    merchantServicesDataLoading
  ) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[120px]">
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
      </div>
    );
  }
  return (
    <>
      <div className="bg-aellaDimGray w-full">
        <div className="px-10 py-[32px] mb-10 ">
          <div className="flex flex-col lg:flex-row justify-between">
            <div className="border border-aellaBorderGray lg:w-[30vw] w-full bg-white mb-5">
              <div className="px-6 pt-[32px] pb-[22px]">
                <WalletIcon />
              </div>
              <div className="px-6">
                <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                  GENERAL WALLET BALANCE
                </h1>
                <h1 className="text-[32px] lg:leading-[30px] font-[300] tracking-[-0.2px] pt-[18px]">
                  {totalWalletData?.available_balance
                    ? currencyFormat(totalWalletData?.available_balance)
                    : currencyFormat(0)}
                </h1>
                <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2">
                  This is the total amount available for withdrawal
                </p>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row border border-aellaBorderGray lg:w-[47vw] w-full px-6 bg-white mb-5">
              <div className="w-full lg:w-[361px] lg:border-r border-[#EDF2FF]">
                <h1 className="text-[13px] lg:leading-[16px] font-[300] pt-[69px]  tracking-[0.03em]">
                  TOTAL WALLET BALANCE FOR ALL SERVICES
                </h1>
                <h1 className="text-[32px] lg:leading-[30px] font-[300] tracking-[-0.2px] pt-[18px]">
                  {currencyFormat(totalServicesWalletSum)}
                </h1>
                <p className="text-[13px] lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                  This is the total amount on all your services.
                </p>
              </div>

              <div className="w-full lg:w-[398px]">
                <div
                  className="text-[13px] font-[300] pt-4 lg:pl-6 flex pb-10 items-center cursor-pointer"
                  onClick={() => setShowTransactionModal(true)}
                >
                  <span className="py-2 pr-4 lg:leading-[18px] text-aellaGray">
                    Showing:
                  </span>
                  <span className="border rounded py-2 flex flex-wrap px-2.5">
                    <CalendarIcon />
                    <span className="pl-1 lg:leading-[20px] tracking-[0.2px] pr-1">
                      {moment(searchState.startDateForTrnx).format(
                        "DD MMM YYYY"
                      )}{" "}
                      -{" "}
                      {moment(searchState.endDateForTrnx).format("DD MMM YYYY")}
                    </span>
                    <div>
                      <ArrowIcon />
                    </div>
                  </span>
                </div>

                <div className="lg:pl-6">
                  <div className="flex flex-col">
                    <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                      TOTAL VOLUME OF TRANSACTIONS
                    </h1>

                    <h1 className="text-[32px] lg:leading-[30px] font-[300] tracking-[-0.2px] pt-[18px]">
                      {totalTransactions || 0}
                    </h1>
                    <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                      This is the total number of withdrawals <br></br>that have
                      been recorded on your account.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white w-full px-6 py-6 lg:py-10 mb-10">
            <div className=" mb-4 lg:mb-[31px]">
              <h1 className="text-[24px] lg:leading-[29px] font-[300] tracking-[-0.2px]">
                More overview to manage your services
              </h1>
            </div>
            <div
              className="grid grid-cols-1 lg:grid-cols-3 gap-6 w-full
            "
            >
              {merchantServicesData?.map((merchantService: any) => (
                <div className="border border-[#EDF2FF] p-4 lg:p-6 max-width-[319px] mt-2 first:mb-0 lg:mt-0">
                  <h1 className="text-sm leading-4 text-aellaGray tracking-[0.03em]">
                    TOTAL WALLET BALANCE -{" "}
                    <span className="text-aellaBlue">
                      {merchantService?.name}
                    </span>
                  </h1>
                  <h1 className="text-[32px] leading-[30px] text-[#011C34] font-[300] tracking-[-0.2px] mt-[18px]">
                    {currencyFormat(merchantService?.available_balance)}
                  </h1>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {showTransactionModal && (
        <DateRangeModal
          startDate={searchState.startDateForTrnx}
          setSearchState={setSearchState}
          endDate={searchState.endDateForTrnx}
          searchState={searchState}
          setCloseModal={setShowTransactionModal}
          startDateName="startDateForTrnx"
          endDateName="endDateForTrnx"
          showModal={showTransactionModal}
        />
      )}
    </>
  );
}
