import { useState } from "react";

import PinInput from "react-pin-input";
import CONFIG from "../../../helpers/config";
import { postData } from "../../../apis/apiMethods";
import { toast } from "react-toastify";
import { errorHandler } from "../../../helpers/errorHandler";
import { Loader } from "../../../component/Loader/Loader.component";

// const schemaPassword = yup.object().shape({
//     password: yupValidators.password,
//     confirmPassword: yup
//       .string()
//       .oneOf([yup.ref("password"), null], "Passwords must match"),
//   });

interface ITransaction {
  setStep: (newState: number) => void;
  setState: any;
  state: {
    currentPin: string;
    newPin: string;
    confirmPin: string;
  };
}

const NewTransaction: React.FC<ITransaction> = ({
  setStep,
  setState,
  state,
}) => {
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [loading, setLoading] = useState(false);

  const submitForm = async () => {
    setLoading(true);

    const reqBody = {
      current_pin: state.currentPin,
      new_pin: pin,
      confirm_new_pin: confirmPin,
    };

    try {
      const res: any = await postData(
        `${CONFIG.BASE_URL2}/kabani/user/pin/change`,
        reqBody
      );
      toast.success(res.message);
      setStep(3);
      setState({
        ...state,

        newPassword: pin,
        confirmNewPassword: confirmPin,
      });
    } catch (error) {
      toast.error(errorHandler(error));
    }

    setLoading(false);
  };

  return (
    <>
      <div>
        <div className="p-[15px] md:p-[40px]">
          <p className="text-[24px] mb-[30px]">
            Enter and confirm your new transaction pin
          </p>

          <div className="mx-auto">
            <div className="mt-[28px]">
              <div>
                <label className="text-[#011C34] inline-block mb-[15px]">
                  Enter New Pin
                </label>
                <PinInput
                  length={4}
                  placeholder="0"
                  initialValue=""
                  secret={true}
                  onChange={(value, index) => setPin(value)}
                  type="numeric"
                  inputMode="number"
                  style={{ display: "flex", justifyContent: "space-between" }}
                  inputStyle={{
                    border: "1px solid #7B7B7B80",
                    width: "23%",
                    height: "54px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    fontWeight: 100,
                    borderRadius: "3px",
                  }}
                  inputFocusStyle={{ borderColor: "#2054D2" }}
                  onComplete={(value, index) => {}}
                  autoSelect={true}
                />
              </div>
            </div>
            <div className="mx-auto">
              <div className="mt-[32px]">
                <label className="text-[#011C34] inline-block mb-[15px]">
                  Confirm New Pin
                </label>
                <PinInput
                  length={4}
                  placeholder="0"
                  initialValue=""
                  secret={true}
                  onChange={(value, index) => setConfirmPin(value)}
                  type="numeric"
                  inputMode="number"
                  style={{ display: "flex", justifyContent: "space-between" }}
                  inputStyle={{
                    border: "1px solid #7B7B7B80",
                    width: "23%",
                    height: "54px",
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    fontWeight: 100,
                    borderRadius: "3px",
                  }}
                  inputFocusStyle={{ borderColor: "#2054D2" }}
                  onComplete={(value, index) => {}}
                  autoSelect={true}
                />
              </div>
            </div>
          </div>
          <button
            onClick={submitForm}
            type="submit"
            className="bg-[#2054D2] mt-[45px] w-full flex gap-[10px] justify-center text-white py-[15px] rounded-[7px]"
          >
            <p>Confirm</p>
            {loading && <Loader />}
          </button>
        </div>
      </div>
    </>
  );
};

export default NewTransaction;
