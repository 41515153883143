import HeaderBackNavigation from "../../component/Header/HeaderBackNavigation.component";
import { ReactComponent as OffDoubleCheckIcon } from "../../assets/images/svg/gray-double-check-icon.svg";
import { ReactComponent as OnDoubleCheckIcon } from "../../assets/images/svg/double-check-icon.svg";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { getFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { useEffect, useState } from "react";
import ProfileStepOne from "./ProfileSteps/ProfileStepOne";
import { postData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { toast } from "react-toastify";
import { errorHandler } from "../../helpers/errorHandler";
import { useMerchantProfile } from "../../hooks/useMerchantProfile";
import { useQueryClient } from "react-query";
import ProfileStepTwo from "./ProfileSteps/ProfileStepTwo";
import ProfileStepThree from "./ProfileSteps/ProfileStepThree";
import ProfileStepFive from "./ProfileSteps/ProfileStepFive";
import { useNavigate } from "react-router-dom";

type stateType = {
  stage: number;
  step: number;
  loading: boolean;
};
const ProfileSetUp = () => {
  const [state, setState] = useState<stateType>({
    stage: 1,
    step: 1,
    loading: false,
  });

  const businessName = getFromLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME);

  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);
  const { data: merchantProfileData } = useMerchantProfile({ merchantId });

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  useEffect(() => {
    if (merchantProfileData) {
      if (merchantProfileData.stage <= 4) {
        setState({
          ...state,
          step: merchantProfileData.stage,
          stage: merchantProfileData.stage,
        });
      } else {
        return navigate("/home", { state: { profile: true } });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantProfileData, merchantId]);

  const submitHandler = async (reqBody: any) => {
    setState({ ...state, loading: true });

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_BUSINESS_PROFILE}`,
        reqBody
      );
      setState({ ...state, step: state.step + 1, stage: state.stage + 1 });
      queryClient.invalidateQueries("getMerchantProfile");
    } catch (error) {
      toast.error(errorHandler(error));
      setState({ ...state, loading: false });
    }
  };

  return (
    <>
      <div className="bg-white border-t border-[#F1F1F1]">
        <HeaderBackNavigation />
        <div className="px-10 bg-aellaDimGray h-screen overflow-y-auto pt-[32px]">
          <div className="block lg:flex flex-row  justify-between">
            <div className="hidden lg:flex flex-col">
              <div className="flex flex-col border border-[#EDF2FF] bg-white p-6 rounded-2xl w-[25vw] items-center">
                <div className="inline-flex border rounded-full bg-[#967164] w-[80px] h-[80px] items-center justify-center relative">
                  {businessName && (
                    <div className="text-aellaBlue z-10 absolute text-4xl">
                      {getFirstLetter(businessName)}
                    </div>
                  )}
                </div>
                <h1 className="pt-[23px] text-[24px] lg:leading-[29px] font-[300] capitalize">
                  {businessName}
                </h1>
                <div
                  className={`py-[13px] px-2.5 flex rounded border ${
                    state.step === 1 ? "border-aellaBlue" : "border-[#E0E0E0]"
                  } items-center justify-between w-full cursor-pointer mt-[23px]`}
                  onClick={() => setState({ ...state, step: 1 })}
                >
                  <div
                    className={`flex items-center text-[16px] lg:leading-[19px] ${
                      state.stage === 1
                        ? "text-aellaGray  font-[100]"
                        : "text-[#232323]  font-[200]"
                    }`}
                  >
                    <span className="pr-1.5">1.</span>
                    <span className="">Business Address</span>
                  </div>

                  {state.stage === 1 ? (
                    <OffDoubleCheckIcon />
                  ) : (
                    <OnDoubleCheckIcon />
                  )}
                </div>
                <div
                  className={`py-[13px] px-2.5 flex rounded border ${
                    state.step === 2 ? "border-aellaBlue" : "border-[#E0E0E0]"
                  } items-center justify-between w-full cursor-pointer mt-2`}
                  onClick={() => {
                    state.stage > 1 && setState({ ...state, step: 2 });
                  }}
                >
                  <div
                    className={`flex items-center text-[16px] lg:leading-[19px] ${
                      state.stage < 2
                        ? "text-aellaLightGray  font-[100]"
                        : state.stage === 2
                        ? "text-aellaGray  font-[50]"
                        : "text-[#232323]  font-[200]"
                    }`}
                  >
                    <span className="pr-1.5">2.</span>
                    <span className="">Business Owner</span>
                  </div>

                  {state.stage <= 2 ? (
                    <OffDoubleCheckIcon />
                  ) : (
                    <OnDoubleCheckIcon />
                  )}
                </div>
                <div
                  className={`py-[13px] px-2.5 flex rounded border ${
                    state.step === 3 ? "border-aellaBlue" : "border-[#E0E0E0]"
                  } items-center justify-between w-full cursor-pointer mt-2`}
                  onClick={() => {
                    state.stage > 2 && setState({ ...state, step: 3 });
                  }}
                >
                  <div
                    className={`flex items-center text-[16px] lg:leading-[19px] ${
                      state.stage < 3
                        ? "text-aellaLightGray  font-[100]"
                        : state.stage === 3
                        ? "text-aellaGray  font-[50]"
                        : "text-[#232323]  font-[200]"
                    }`}
                  >
                    <span className="pr-1.5">3.</span>
                    <span className="">Business Type</span>
                  </div>

                  {state.stage <= 3 ? (
                    <OffDoubleCheckIcon />
                  ) : (
                    <OnDoubleCheckIcon />
                  )}
                </div>
                <div
                  className={`py-[13px] px-2.5 flex rounded border ${
                    state.step === 4 ? "border-aellaBlue" : "border-[#E0E0E0]"
                  } items-center justify-between w-full cursor-pointer mt-2`}
                  onClick={() => {
                    state.stage > 3 && setState({ ...state, step: 4 });
                  }}
                >
                  <div
                    className={`flex items-center text-[16px] lg:leading-[19px] ${
                      state.stage < 4
                        ? "text-aellaLightGray  font-[100]"
                        : state.stage === 4
                        ? "text-aellaGray  font-[50]"
                        : "text-[#232323]  font-[200]"
                    }`}
                  >
                    <span className="pr-1.5">4.</span>
                    <span className="">Transaction Pin</span>
                  </div>

                  {state.stage <= 5 ? (
                    <OffDoubleCheckIcon />
                  ) : (
                    <OnDoubleCheckIcon />
                  )}
                </div>
              </div>
              <h1 className="flex items-center text-[16px] text-[#232323] lg-[19px] font-[200] pt-6">
                Complete this to get your profile fully running 🎉
              </h1>
            </div>
            <div className="lg:w-[50vw] bg-white p-6 rounded-2xl w-full">
              {(() => {
                switch (state.step) {
                  case 1:
                    return (
                      <ProfileStepOne
                        submitHandler={submitHandler}
                        state={state}
                        merchantProfileData={merchantProfileData}
                      />
                    );
                  case 2:
                    return (
                      <ProfileStepTwo
                        submitHandler={submitHandler}
                        state={state}
                        merchantProfileData={merchantProfileData}
                      />
                    );
                  case 3:
                    return (
                      <ProfileStepThree
                        submitHandler={submitHandler}
                        state={state}
                        merchantProfileData={merchantProfileData}
                      />
                    );
                  case 4:
                    return <ProfileStepFive />;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfileSetUp;
