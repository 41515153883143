import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getPosDisputeActivity = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { id } = queryKey[1];

  const { data }: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.POS_DISPUTES}/${id}/activities`
  );
  return data;
};

export const usePosDisputeActivity = ({ id }: { id: string }) => {
  return useQuery(["getPosDisputeActivity", { id }], getPosDisputeActivity, {
    retry: 1,
    enabled: id ? true : false,
    refetchOnWindowFocus: false,
  });
};