import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getEnvironmentData = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.ENVIRONMENT}`
  );
  return data;
};

export const useEnvironmentData = () => {
  return useQuery("environmentData", getEnvironmentData, {
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};
