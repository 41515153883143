import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getMerchantDefaultAccount = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.MERCHANT_ACCOUNT}`
  );
  return data;
};

export const useMerchantDefaultAccount = () => {
  return useQuery(
    ["getMerchantDefaultAccount"],

    getMerchantDefaultAccount,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
