import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getPosRequests = async ({ queryKey }: { queryKey: any }) => {
  const { page, size } = queryKey[1];

  let queryParams = `?page=${page}&size=${size}`;

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.POS_REQUESTS}${queryParams}`
  );
  return data;
};

export const usePosRequests = ({
  page,
  size,
}: {
  page: number;
  size: number;
}) => {
  return useQuery(["posRequests", { page, size }], getPosRequests, {
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};
