import React, { useState } from "react";
import { useMerchantProfile } from "../../../hooks/useMerchantProfile";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { toast } from "react-toastify";

import { errorHandler } from "../../../helpers/errorHandler";

const SMSNotification = () => {
  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);
  const { data: merchantProfileData, refetch } = useMerchantProfile({
    merchantId,
  });

  const pushData = merchantProfileData?.notifications?.push;
  const smsData = merchantProfileData?.notifications?.sms;
  const emailData = merchantProfileData?.notifications?.email;

  const [toggle, setToggle] = useState(smsData);

  const togglePush = async () => {
    setToggle(!toggle);

    const reqBody = {
      push: pushData,
      sms: !toggle,
      email: emailData,
    };

    // ${CONFIG.BASE_URL2}${apiEndpoints.UPLOAD_MERCHANT_PROFILE}
    try {
      const res = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.UPDATE_NOTIFICATION}`,
        reqBody
      );
      refetch();
      toast.success(res.message);
    } catch (error) {
      toast.error("Error Updating SMS Notification, Try again");
    }
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="w-[80%]">
          <p className="text-[#0B1E4B] mt-[16px]">SMS Notifications</p>
          <p className="font-thin text-[#7B7B7B] text-[14px]">
            Recieve SMS notifications for your activities on your workspace
          </p>
        </div>

        <div
          onClick={togglePush}
          className={`${
            smsData === true ? "bg-[#2054D2]" : "bg-[#F3F5FC]"
          } cursor-pointer w-[45px] rounded-[1rem] h-[24px] border border-[#C5D4F6] relative`}
        >
          <div
            className={`w-[19.6px] absolute top-[50%] transition translate-y-[-50%] ${
              smsData === true ? "left-[50%]" : "left-[2%]"
            }  h-[19.6px] bg-white rounded-[50%] shadow-md`}
          ></div>
        </div>
      </div>
    </>
  );
};

export default SMSNotification;
