import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAgentList = async () => {
  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_AGENTS_LIST}`
  );
  return res;
};

export const useAgentList = () => {
  return useQuery("getAgentList", getAgentList, {
    refetchOnWindowFocus: false,
  });
};
