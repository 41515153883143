import { useState } from "react";
import BusinessForm from "../FormComponents/BusinessForm";
import PersonalForm from "../FormComponents/PersonalForm";
import { ROUTES } from "../../../helpers/routes";
import Tab from "../../../component/Tab/Tab.component";

const Profile = () => {
  const [stage, setStage] = useState("business");

  const RenderContent = () => {
    switch (stage) {
      case "business":
        return <BusinessForm />;
      case "personal":
        return <PersonalForm />;
      default:
        return;
    }
  };

  return (
    <>
      <div className="border-y border-[#e1dddd] bg-aellaDimGray overflow-x-auto h-screen">
        <div className="bg-white pt-[7px] px-[10px] lg:px-10 overflow-x-auto">
          <div className="flex justify-between bg-white items-center pt-2 pb-[1px] flex-wrap border-t border-[#F1F1F1] w-[200%] xs:w-[165%] sm:w-full">
            <div className="flex ">
              <Tab text="Profile" path={ROUTES.SETTINGS_PROFILE} />
              <Tab text="Security" path={ROUTES.SETTINGS_SECURITY} />
              <Tab text="Notifications" path={ROUTES.SETTINGS_NOTIFICATIONS} />
              <Tab
                text="Withdrawal Bank Accounts"
                path={ROUTES.SETTINGS_WITHDRAWAL}
              />
              <Tab text="API Keys" path={ROUTES.SETTINGS_API} />
            </div>
          </div>
        </div>
        <div className="py-[32px] px-[10px] lg:p-10">
          <div className="bg-white border border-[#EDEFF5] p-[10px] lg:p-10 pt-[30px]">
            <div className="">
              <div className="pb-[12px] border-b border-[#EDEFF5]">
                <div className="mb-[32px] bg-[#F7F8F8] border border-[#EDF2FF] py-[4px] px-[8px] gap-[16px] rounded-[5rem] inline-flex">
                  <div
                    onClick={() => setStage("business")}
                    className={`text-[#232323] font-thin cursor-pointer  ${
                      stage === "business"
                        ? "border-[#ECEDF0] border bg-white shadow-md"
                        : ""
                    }  px-[16px] text-[12px] xs:text-[14px] sm:text-[16px] py-[8px] rounded-[4rem]`}
                  >
                    Business Profile
                  </div>

                  <div
                    onClick={() => setStage("personal")}
                    className={`text-[#232323] font-thin cursor-pointer ${
                      stage === "personal"
                        ? "border-[#ECEDF0] border bg-white shadow-md"
                        : ""
                    }  px-[16px] text-[12px] xs:text-[14px] sm:text-[16px] py-[8px] rounded-[4rem] `}
                  >
                    Personal Profile
                  </div>
                </div>

                <p className="text-[24px] text-[#0B1E4B]">
                  {stage === "business"
                    ? "Business Information"
                    : "Personal Information"}
                </p>
              </div>
            </div>
            <div className="py-[32px]">{RenderContent()}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
