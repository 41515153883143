import React, { forwardRef } from "react";

const SocialLinkInput = (
  {
    label,
    icon,
    name,
    defaultValue,
    ...otherProps
  }: {
    label: string;
    name: string;
    defaultValue: string;
    icon: any;
  },
  ref: any
) => {
  return (
    <>
      <div className="w-full">
        <label className="font-thin text-[#0B1E4B]">{label}</label>
        <div className="flex border border-[#DADFED] rounded-[4px] overflow-hidden">
          <div className="px-[14px] py-[15px] border-r border-[#DADFED]">
            {icon}
          </div>
          <input
            name={name}
            className="flex-1 font-thin text-[#7B7B7B] text-[12px] pl-[11px] outline-none"
            type="text"
            defaultValue={defaultValue}
            placeholder="paste your profile url here"
            {...otherProps}
            ref={ref}
          />
        </div>
      </div>
    </>
  );
};

export default forwardRef(SocialLinkInput);
