import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllSubAccountDetails = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { id } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_SUBACCOUNTS_DETAILS}/${id}`
  );
  return data;
};

export const useAllSubAccountDetails = ({ id }: { id: string }) => {
  return useQuery(
    ["getAllSubAccountDetails", { id }],

    getAllSubAccountDetails,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: id ? true : false,
      retry: 1,
    }
  );
};
