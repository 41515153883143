import React from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
// import editIcon from "../../../assets/images/svg/edit2-icon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/images/svg/info-icon.svg";
import { ReactComponent as PreviewIcon } from "../../../assets/images/svg/preview-icon.svg";
import { RequestsType } from "../Pos";

interface TPosModal {
  modalType: string;
  setModalType: (newState: string) => void;
  setIsOpen: (newState: boolean) => void;
  onSubmitRequest: () => void;
  loading: boolean;
  requests: Array<RequestsType>;
  setRequests: (newState: Array<RequestsType>) => void;
}

const PosModal: React.FC<TPosModal> = ({
  modalType,
  setModalType,
  setIsOpen,
  loading,
  requests,
  onSubmitRequest,
  setRequests,
}) => {
  // const disabledValue = (id?: number) => {
  //   const exactField = requests.find((req) => req.id === id);
  //   return !exactField?.edit;
  // };
  //   const setEditTrue = (id?: number) => {
  //     const exactField = requests.find((req) => req.id === id);
  //     return !exactField?.edit;
  //   };

  // const onEdit = (id?: number) => {
  //   const exactField = requests.find((req) => req.id === id);
  //   // eslint-disable-next-line array-callback-return
  //   requests?.map((obj: RequestsType) => {
  //     if (exactField) {
  //       return { ...obj, edit: false };
  //     }
  //   });
  // };

  // const onChange = (e: any, id?: number) => {
  //   e.preventDefault();
  //   const requestToUpdate = requests.find((request) => request.id === id);
  //   const otherRequests = requests.filter(
  //     (request: RequestsType) => request.id !== id
  //   );
  //   const { name, value } = e.target;
  //   setRequests([...otherRequests, { ...requestToUpdate, [name]: value }]);
  // };
  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mt-2 mb-40 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setModalType("");
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg p-10 flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none ">
            {(() => {
              switch (modalType) {
                case "step1":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col items-center">
                        <PreviewIcon />
                        <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px] ">
                          Preview
                        </h1>
                        <p className="text-[16px] leading-[19px] font-[200] text-[#232323] pt-[5px]">
                          Kindly confirm your request.
                        </p>
                        <div className="bg-[#F6F8FA] p-[16px] mt-[23px]">
                          {requests?.map((request: RequestsType) => (
                            <div
                              className="rounded flex items-center mt-2.5 first:mt-0"
                              key={request.id}
                            >
                              <div className="flex flex-col">
                                <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2 ">
                                  Delivery Address
                                </label>
                                <textarea
                                  className="bg-white appearance-none border px-4 py-2 h-[53px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray"
                                  name="delivery_address"
                                  defaultValue={request.delivery_address}
                                  disabled
                                  // disabled={disabledValue(request.id)}
                                  // onChange={(e: any) => {
                                  //   onChange(e, request.id);
                                  // }}
                                />
                              </div>
                              <div className="flex flex-col pr-2.5 pl-2.5">
                                <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2  ">
                                  Quantity
                                </label>
                                <input
                                  type="number"
                                  min={1}
                                  className="bg-white appearance-none border px-4 py-2 h-[53px] w-[93px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray"
                                  placeholder="e.g 5"
                                  name="quantity"
                                  defaultValue={request.quantity}
                                  disabled
                                  // disabled={disabledValue(request.id)}
                                  // onChange={(e: any) => {
                                  //   onChange(e, request.id);
                                  // }}
                                />
                              </div>
                              {/* 
                              <img
                                src={editIcon}
                                alt=""
                                className="mt-6 cursor-pointer"
                                onClick={() => onEdit(request.id)}
                              /> */}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="bg-aellaBlue rounded text-white py-4 px-10 items-center flex justify-center cursor-pointer mt-8"
                          onClick={() => onSubmitRequest()}
                        >
                          Submit Request
                        </button>
                      </div>
                    </>
                  );
                case "step2":
                  return (
                    <>
                      <div className="flex flex-col items-center ">
                        <PreviewIcon />
                        <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px] ">
                          Preview
                        </h1>
                        <p className="text-[16px] leading-[19px] font-[200] text-[#232323] pt-[5px]">
                          Your POS request has been received.
                        </p>
                        <div className="bg-[#F6F8FA] p-[16px] mt-[23px] overflow-y-auto  max-h-[40vh]">
                          {requests?.map((request: RequestsType) => (
                            <div
                              className="rounded flex items-center mt-2.5 first:mt-0"
                              key={request.id}
                            >
                              <div className="flex flex-col">
                                <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2 ">
                                  Delivery Address
                                </label>
                                <textarea
                                  className="bg-white appearance-none border px-4 py-2 h-[53px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray"
                                  name="delivery_address"
                                  defaultValue={request.delivery_address}
                                  disabled
                                />
                              </div>
                              <div className="flex flex-col pr-2.5 pl-2.5">
                                <label className=" text-[#0B1E4B] lg:leading-[18px] text-[16px] font-[300] mb-2  ">
                                  Quantity
                                </label>
                                <input
                                  type="number"
                                  min={1}
                                  className="bg-white appearance-none border px-4 py-2 h-[53px] w-[93px] font-[100] rounded placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] text-aellaGray"
                                  placeholder="e.g 5"
                                  name="quantity"
                                  defaultValue={request.quantity}
                                  disabled
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="flex mt-[17px] items-start">
                        <div className="shrink-0">
                          <InfoIcon />
                        </div>
                        <h1 className="ml-2.5 text-[12px] lg:leading-[13px] font-[100]">
                          Your Aella POS Terminal will be delivered in a few
                          days. If it takes any longer, please send us a message
                          at
                          <span className="text-aellaBlue">
                            support@aellacredit.com.
                          </span>
                        </h1>
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          onClick={() => {
                            setModalType("");
                          }}
                          className="bg-aellaBlue rounded text-white py-4 px-10 items-center flex justify-center cursor-pointer mt-8"
                        >
                          Done
                        </button>
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setModalType("");
          setRequests([
            {
              id: "",
              delivery_address: "",
              quantity: null,
            },
          ]);
        }}
      ></div>
    </>
  );
};
export default PosModal;
