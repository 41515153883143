import { FC, useEffect, useState } from "react";
import { Player } from "@lottiefiles/react-lottie-player";
import { useQueryClient } from "react-query";

import SuccessAnimation from "../../assets/files/Stars.json";
import { ReactComponent as FailedTransferIcon } from "../../assets/images/svg/failed-transfer.svg";
import { ReactComponent as PendingTransferIcon } from "../../assets/images/svg/pending-transfer-icon.svg";
import { ReactComponent as CloseModalIcon } from "../../assets/images/svg/close-icon.svg";
import { ModalContainer } from "../../modal/ModalContainer";
import BlueButton from "../../component/Button/BlueButton";
import { formatAmount } from "../../helpers/formatMoney";

type stateType = {
  type: string;
  showModal: boolean;
  setShowModal: (newState: boolean) => void;
  setShowDetails: (newState: boolean) => void;
  transactionData: any;
  receiver: string;
  receiverEmail: string;
  receiverDetails: any;
  setSelectedTransfer: (newState: Object) => void;
  onSubmit: any;
  loading?: boolean;
  resetMyForm: any;
  bank: any;
  refetch: any;
  formInfo: any;
  amountToSend: string;
  setSelectedAccount: (newState: string)=> void

};

const TransferStatus: FC<stateType> = ({
  type,
  showModal,
  setShowModal,
  setShowDetails,
  transactionData,
  receiver,
  receiverEmail,
  receiverDetails,
  setSelectedTransfer,
  onSubmit,
  loading,
  resetMyForm,
  bank,
  refetch,
  formInfo,
  amountToSend,
  setSelectedAccount
}) => {
  const [transferStatus, setTransferStatus] = useState("");
  const queryClient = useQueryClient();

  const handleClose = () => {
    setShowModal(false);
    if (
      transactionData?.message === "Transfer successful" ||
      "Transfer pending"
    ) {
      resetMyForm();
    }
    setSelectedAccount('')
    queryClient.invalidateQueries("getWalletBalance");
  };

  useEffect(() => {
    if (
      (transactionData && transactionData?.message === "Transfer successful") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes === "success") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes === "successful")
    ) {
      setTransferStatus("success");
    }
    if (
      (transactionData &&
        transactionData?.message === "Transfer is being processed") ||
      (transactionData && transactionData?.message === "Transfer pending") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes === "processed") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes === "processing") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes === "pending")
    ) {
      setTransferStatus("pending");
    } else setTransferStatus("error");
  }, [transactionData]);

  const PendingTransaction = () => {
    return (
      <div className="w-full lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 flex flex-col items-center text-center p-10 rounded-[8px] bg-white">
        <PendingTransferIcon />
        <div
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={handleClose}
        >
          <CloseModalIcon />
        </div>
        <p className="text-[#0B1E4B] text-2xl mt-8">Transaction Pending</p>
        <p className="text-[#5B5B5B] text-base mt-4 font-light">
          Your transaction is still being processed, <br /> you will be notified
          when it is successful!
        </p>
        <BlueButton
          title="Back Home"
          onClick={handleClose}
          className="px-12 text-[#F3F5FC] mt-10"
        />
      </div>
    );
  };

  const OtherStatus = () => {
    return (
      <div className="w-full lg:w-[35vw] relative right-0 h-auto max-h-[90vh] z-50 text-[#232323] flex flex-col items-center  rounded-[8px] bg-white py-10 px-10">
        <div
          className="absolute -top-8 -right-0 cursor-pointer"
          onClick={handleClose}
        >
          <CloseModalIcon />
        </div>
        {(transactionData &&
          transactionData?.message === "Transfer successful") ||
        (transactionData &&
          transactionData?.message?.toLowerCase()?.includes === "success") ||
        (transactionData &&
          transactionData?.message?.toLowerCase()?.includes ===
            "successful") ? (
          <div className="flex items-center justify-center h-auto relative">
            <Player
              autoplay
              controls
              loop
              src={SuccessAnimation}
              style={{
                width: "200px",
                height: "120px",
              }}
            />
            <p className="absolute top-[30%] right-[25%] text-2xl text-aellaBlue">
              Successful
            </p>
          </div>
        ) : (
          <FailedTransferIcon />
        )}

        <p className="text-2xl text-[#0B1E4B] mt-10 capitalize">
          {transactionData?.message}
        </p>
        <div className="text-center text-[#232323] font-light mt-4 text-base tracking-wide">
          {!(
            (transactionData &&
              transactionData?.message === "Transfer successful") ||
            (transactionData &&
              transactionData?.message?.toLowerCase()?.includes ===
                "success") ||
            (transactionData &&
              transactionData?.message?.toLowerCase()?.includes ===
                "successful") ||
            (transactionData &&
              transactionData?.message === "Transfer is being processed") ||
            (transactionData &&
              transactionData?.message === "Transfer pending") ||
            (transactionData &&
              transactionData?.message?.toLowerCase()?.includes ===
                "pending") ||
            (transactionData &&
              transactionData?.message?.toLowerCase()?.includes ===
                "processed") ||
            (transactionData &&
              transactionData?.message?.toLowerCase()?.includes ===
                "processing")
          ) && "Oops..."}{" "}
          Your transfer of{" "}
          <span className="font-medium">
            ₦
            {transactionData?.data?.amount ||
              formatAmount(parseFloat(amountToSend?.replace(/,/g, ""))) ||
              formInfo?.amount}
          </span>
        </div>
        <div className="font-light text-[#232323] text-center text-sm mt-1 tracking-wide">
          to{" "}
          <span className="font-medium">
            {type === "Bank" ? bank?.account_name : receiverDetails?.name}
          </span>{" "}
          {receiver && receiver} (
          {type === "Bank"
            ? transactionData?.data?.bank_name || bank?.bank_name
            : receiverDetails?.email}
          ) was{" "}
          {(transactionData &&
            transactionData?.message === "Transfer successful") ||
          (transactionData &&
            transactionData?.message?.toLowerCase()?.includes === "success") ||
          (transactionData &&
            transactionData?.message?.toLowerCase()?.includes === "successful")
            ? "successful"
            : "unsuccessful"}
        </div>
        {(transactionData &&
          transactionData?.message === "Transfer successful") ||
        (transactionData &&
          transactionData?.message?.toLowerCase()?.includes === "success") ||
        (transactionData &&
          transactionData?.message?.toLowerCase()?.includes ===
            "successful") ? (
          <div className="mt-10 flex flex-col items-center">
            <BlueButton
              onClick={handleClose}
              className="px-12"
              title="Got It!"
            />
            <p
              onClick={() => {
                setSelectedTransfer(transactionData);
                setShowModal(false);
                setShowDetails(true);
                setSelectedAccount('')
              }}
              className="text-sm text-aellaBlue cursor-pointer mt-5"
            >
              View Receipt
            </p>
          </div>
        ) : (
          <BlueButton
            onClick={onSubmit}
            className="px-7 mt-10"
            title={loading ? "Sending.." : "Try Again"}
          />
        )}
      </div>
    );
  };
  return (
    <ModalContainer
      showModal={showModal}
      closeModal={() => {
        setShowModal(false);
        resetMyForm();
        setSelectedAccount('')
      }}
    >
      {(transactionData &&
        transactionData?.message === "Transfer is being processed") ||
      (transactionData && transactionData?.message === "Transfer pending") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes === "pending") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes === "processed") ||
      (transactionData &&
        transactionData?.message?.toLowerCase()?.includes === "processing") ? (
        <PendingTransaction />
      ) : (
        <OtherStatus />
      )}
    </ModalContainer>
  );
};

export default TransferStatus;
