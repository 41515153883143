export enum LOCAL_STORAGE_KEYS {
  TOKEN = "token",
  MERCHANTID = "Id",
  FIRSTNAME = "firstName",
  LASTNAME = "lastName",
  BUSINESSNAME = "businessName",
  PHONENUMBER = "phoneNumber",
  USERID = "userId",
  EMAIL = "email",
  PROFILE_SETUP = "setup",
  TEST = "test",
  LIVE = "live",
  WALLETID = "wallet",
  MERCHANTSTATUS = "status",
  MERCHANTCREATED = "created",
  MERCHANTTERMS = "terms",
}
