import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getWalletTransactions = async ({
  queryKey,
}: {
  queryKey: any;
}) => {
  const { type, walletID, mode, page, size } = queryKey[1];
  let queryParams = `?mode=${mode}&page=${page}&per_page=${size}`;
  if (type) {
    queryParams = `${queryParams}&type=${type}`;
  }
  if (walletID) {
    queryParams = `${queryParams}&wallet_id=${walletID}`;
  }

  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_WALLET_TRANSACTIONS}${queryParams}`
  );
  return data;
};

export const useWalletTransactions = (props: {
  type?: string | undefined;
  mode: string;
  walletID?: string | undefined;
  page: number;
  size: number;
}) => {
  const { walletID, type, mode, page, size } = props;
  return useQuery(
    ["getWalletTransactions", { type, walletID, mode, page, size }],

    getWalletTransactions,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: props.hasOwnProperty("walletID")
        ? walletID
          ? true
          : false
        : mode
        ? true
        : false,
      retry: false,
    }
  );
};
