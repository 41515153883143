import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getMerchantProfile = async () => {
  // const { merchantId } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_PROFILE}`
  );
  return data;
};

export const useMerchantProfile = ({ merchantId }: { merchantId: string }) => {
  return useQuery(
    ["getMerchantProfile", { merchantId }],

    getMerchantProfile,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
