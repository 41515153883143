import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import { ROUTES } from "../../helpers/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../helpers/yupValidators";
import * as yup from "yup";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { RegistrationFormData } from "../../interfaces/login";
import { toast } from "react-toastify";
import { Loader } from "../../component/Loader/Loader.component";
import { axiosInstance, postData } from "../../apis/apiMethods";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import {
  getFromLocalStorage,
  postToLocalStorage,
} from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";

const schema = yup.object().shape({
  email: yupValidators.email,
  firstName: yupValidators.firstName,
  lastName: yupValidators.lastName,
  phoneNumber: yupValidators.phoneNumber,
  businessName: yupValidators.businessName,
});

interface ISignUpStepOne {
  setStep: (newState: number) => void;
}

const SignUpStepOne: React.FC<ISignUpStepOne> = ({ setStep }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const phoneNumber = getFromLocalStorage(LOCAL_STORAGE_KEYS.PHONENUMBER);
    const convertedNumber = phoneNumber.replace("234", "0");
    setValue("firstName", getFromLocalStorage(LOCAL_STORAGE_KEYS.FIRSTNAME));
    setValue("lastName", getFromLocalStorage(LOCAL_STORAGE_KEYS.LASTNAME));
    setValue("phoneNumber", convertedNumber);
    setValue("email", getFromLocalStorage(LOCAL_STORAGE_KEYS.EMAIL));

    // eslint-disable-next-line
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    email,
    businessName,
    firstName,
    lastName,
    phoneNumber,
  }: {
    email: string;
    firstName: string;
    lastName: string;
    phoneNumber: number;
    businessName: string;
  }) => {
    setLoading(true);

    const reqBody = {
      is_kabani: true,
      name: businessName,
      user: {
        email,
        first_name: firstName,
        last_name: lastName,
        password: "1234567890",
        phone_number: phoneNumber,
      },
    };
    try {
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_MERCHANT}`,
        reqBody
      );
      const data = res.data;
      setLoading(false);
      setStep(3);
      postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID, data.id);
      postToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, data.email);
      postToLocalStorage(LOCAL_STORAGE_KEYS.FIRSTNAME, data.first_name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME, data.name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.PHONENUMBER, data.phone_number);
      postToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, data.token);
      postToLocalStorage(LOCAL_STORAGE_KEYS.USERID, data.user_id);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      axiosInstance.defaults.headers.common.userid = `${data.id}`;
      axiosInstance.defaults.headers.common.secret = `${data.token}`;
      axiosInstance.defaults.headers.common.type = "Token";
    } catch (error) {
      setLoading(false);
      toast.error(errorHandler(error));
    }
  };

  return (
    <>
      <div className="lg:pr-[185px] md:pr-[64px]  mb-20">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-7 lg:pt-[96px]">
            <h3 className="text-[32px] lg:leading-[38px] font-[300] pb-2">
              Confirm Profile Details
            </h3>
            <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
              Please provide more information about your business
            </p>
          </div>

          <CustomInputField
            type="text"
            maxLength={128}
            placeholder="Enter business name here"
            label="Business Name"
            errors={errors.businessName}
            showRequiredIcon
            {...register("businessName")}
          />
          <div className="flex flex-col lg:flex-row">
            <div className=" w-full lg:pr-6">
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder="Enter first name"
                label="First Name"
                errors={errors.firstName}
                showRequiredIcon
                {...register("firstName")}
                readOnly
              />
            </div>
            <div className="w-full">
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder="Enter last name"
                label="Last Name"
                errors={errors.lastName}
                showRequiredIcon
                {...register("lastName")}
                readOnly
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className=" w-full lg:pr-6">
              <CustomInputField
                type="email"
                maxLength={128}
                placeholder="User@gmail.com"
                label="Work Email"
                errors={errors.email}
                showRequiredIcon
                {...register("email")}
                readOnly
              />
            </div>
            <div className="w-full">
              <CustomInputField
                type="number"
                maxLength={11}
                placeholder="08100000000"
                label="Phone Number"
                hasIcon="+234"
                errors={errors.phoneNumber}
                showRequiredIcon
                {...register("phoneNumber")}
              />
            </div>
          </div>

          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center w-full flex xxl:mt-7 justify-center cursor-pointer"
            disabled={loading}
          >
            Continue
            <span className="pl-2">
              <NextIcon />{" "}
            </span>
            {loading && <Loader />}
          </button>

          <p className="flex items-center justify-center my-8 text-[16px] lg:leading-[20px] font-[300]">
            <span className="text-aellaGray pr-2">
              Already have an account?{" "}
            </span>
            <Link className="text-aellaBlue" to={ROUTES.LOGIN}>
              Sign In
            </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default SignUpStepOne;
