// import searchIcon from "../../../assets/images/svg/search-icon.svg";
import { currencyFormat } from "../../helpers/formatter";
import moment from "moment";
import {
  attachClassNameToStatus,
  getStatusNameByNumber,
} from "../../helpers/attachClassNameToTag";
import { useEffect, useState } from "react";
import { useWalletTransactions } from "../../hooks/useWalletTransactions";
import InflowTransactionDrawer from "../InflowTransactions/Drawer/InflowTransactionDrawer";
import Pagination from "../../component/Pagination/Pagination.component";
import { SecondaryLoader } from "../../component/Loader/SecondaryLoader";
import { useSearchParams } from "react-router-dom";

const WalletTransaction = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [transaction, setTransaction] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [inflowResponse, setInflowResponse] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams({});

  const { data: walletInFlowTransactions, isLoading: walletInFlowTrnxLoading } =
    useWalletTransactions({
      mode: "merchant",
      page: currentPage,
      size: postsPerPage,
    });

  useEffect(() => {
    const newData = [];
    if (walletInFlowTransactions?.data.length > 0) {
      for (let data of walletInFlowTransactions?.data) {
        if (
          data?.transaction_type === null ||
          data?.transaction_type === undefined
        ) {
          if (data?.description) {
            let transaction_type = {} as any;
            let desObj = JSON.parse(data?.description);
            transaction_type.name = desObj?.mode;
            data.transaction_type = transaction_type;
          } else {
            data.transaction_type = "N/A";
          }
        }
        newData.push(data);
      }
      walletInFlowTransactions.data = newData;
      setInflowResponse(walletInFlowTransactions);
    }
  }, [walletInFlowTransactions?.data, walletInFlowTransactions]);

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.page || currentParams?.size) {
      setCurrentPage(JSON.parse(currentParams?.page));
      setPostsPerPage(JSON.parse(currentParams?.size));
    }
  }, [searchParams, currentPage]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (!currentParams?.page || !currentParams?.size) {
      setSearchParams({
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, postsPerPage]);
  return (
    <>
      <div className="border-t bg-aellaDimGray px-10 w-full mb-24">
        <div className="py-6 border border-aellaBorderGray bg-white mt-[40px]">
          <div className="flex justify-between flex-wrap items-center pb-[18px] px-6 lg:px-10 ">
            <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350]">
              Transactions
            </h1>
            {/* <div className="relative flex w-full lg:w-[190px] h-10 ">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none mt-1 md:mt-0">
                <img className="w-4" src={searchIcon} alt="" />
              </div>
              <input
                autoComplete="false"
                type="text"
                placeholder="Search"
                className="placeholder:font-thin border border-[#EDEFF5] text-[#5B5B5B] text-sm rounded pl-10 py-3 focus:outline-none"
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div> */}
          </div>

          <div className="pt-10 border-t border-[rgb(241,241,241)] px-6 lg:px-10 overflow-x-auto w-full">
            <div>
              <div className="flex items-center bg-[#F7F9FC] w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b">
                <p className="w-[22%]">Transaction</p>
                <p className="w-[18%]">Transaction Type</p>
                <p className="w-[25%]"> Amount</p>
                <p className="w-[18%]"> Time Stamp</p>
                <p className="w-[20%] text-right"> Status</p>
              </div>
              {walletInFlowTrnxLoading ? (
                <SecondaryLoader count={10} />
              ) : (
                inflowResponse?.data?.map((trnxn: any) => {
                  return (
                    <div
                      key={trnxn?.id}
                      className="flex items-center bg-white w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
                      onClick={() => {
                        setIsOpen(true);
                        setTransaction(trnxn);
                      }}
                    >
                      <p className="w-[22%]">
                        {" "}
                        {trnxn?.transaction_type?.name}
                      </p>
                      <p
                        className={`w-[18%] capitalize  text-[13px] lg:text-[16px] lg:leading-[19px] tracking-[0.2px] font-[300] py-[15px] ${
                          trnxn?.type === "cr"
                            ? "text-[#219653]"
                            : trnxn?.type === "dr"
                            ? "text-[#EB5757]"
                            : "text-aellaGray"
                        }`}
                      >
                        {trnxn?.type === "dr"
                          ? "Debit"
                          : trnxn?.type === "cr"
                          ? "Credit"
                          : ""}
                      </p>
                      <p className="w-[25%]">{currencyFormat(trnxn?.amount)}</p>
                      <p className="w-[18%]">
                        {trnxn?.date
                          ? moment(trnxn?.date).format("DD MMM YYYY | LT")
                          : "-"}
                      </p>

                      <p className={`w-[20%] text-right`}>
                        <span
                          className={`w-[13%] text-center ${attachClassNameToStatus(
                            trnxn?.status
                          )} `}
                        >
                          {getStatusNameByNumber(trnxn?.status)}
                        </span>
                      </p>
                    </div>
                  );
                })
              )}
            </div>
          </div>
          {walletInFlowTransactions?.data?.length > 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={inflowResponse?.meta?.total}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={inflowResponse?.meta?.total}
            />
          )}
        </div>
      </div>
      {/* <FilterModal /> */}
      <InflowTransactionDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        transaction={transaction}
      />
    </>
  );
};
export default WalletTransaction;
