import { useForm } from "react-hook-form";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye-icon.svg";
import { errorHandler } from "../../../helpers/errorHandler";
import * as yup from "yup";
import { postData } from "../../../apis/apiMethods";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { RegistrationFormData } from "../../../interfaces/login";
import { yupValidators } from "../../../helpers/yupValidators";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { Loader } from "../../../component/Loader/Loader.component";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import CONFIG from "../../../helpers/config";

const schemaPassword = yup.object().shape({
  password: yupValidators.password,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});

interface IPassword {
  setStep: (newState: number) => void;
  setState: any;
  state: {
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  };
}

const NewPassword: React.FC<IPassword> = ({ setStep, setState, state }) => {
  const [isNewVisible, setNewVisible] = useState(false);
  const [isConfirmVisible, setConfirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggleNewPassword = () => {
    setNewVisible(!isNewVisible);
  };

  const toggleConfirmPassword = () => {
    setConfirmVisible(!isConfirmVisible);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(schemaPassword),
  });

  const onSubmit = async ({
    password,
    confirmPassword,
  }: {
    password: string;
    confirmPassword: string;
  }) => {
    setLoading(true);
    const reqBody = {
      current_password: state.currentPassword,
      new_password: password,
      confirm_new_password: confirmPassword,
    };

    try {
      const res: any = await postData(
        `
        ${CONFIG.BASE_URL1}${apiEndpoints.CHANGE_PASSWORD}`,
        reqBody
      );
      toast.success(res.message);
      setStep(3);
      setState({
        ...state,
        newPassword: password,
        confirmNewPassword: confirmPassword,
      });
    } catch (error) {
      toast.error(errorHandler(error));
    }

    setLoading(false);
  };

  return (
    <div className="p-[15px] md:p-[40px]">
      <p className="text-[24px] mb-[30px]">
        Enter and confirm your new password
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-[32px]">
          <div className="relative">
            <CustomInputField
              type={!isNewVisible ? "password" : "text"}
              maxLength={128}
              placeholder="********"
              label="New password"
              errors={errors.password}
              hasActionButton={true}
              {...register("password")}
            />

            <Eye
              onClick={toggleNewPassword}
              className="absolute right-[12px] top-[70%] translate-y-[-50%] cursor-pointer"
            />
          </div>

          {errors.password && (
            <div className="flex gap-[9px] items-center">
              <div className="w-[5px] h-[5px] bg-[#2054D2] rounded-[50%]"></div>

              <p className="text-[12px] font-thin text-[#6A7581]">
                {errors.password?.message}
              </p>
            </div>
          )}
        </div>

        <div>
          <div className="relative">
            <CustomInputField
              type={!isConfirmVisible ? "password" : "text"}
              maxLength={128}
              placeholder="********"
              label=" Confirm New password"
              errors={errors.confirmPassword}
              hasActionButton={true}
              {...register("confirmPassword")}
            />

            <Eye
              onClick={toggleConfirmPassword}
              className="absolute right-[12px] top-[70%] translate-y-[-50%] cursor-pointer"
            />
          </div>

          {errors.confirmPassword && (
            <div className="flex gap-[9px] items-center">
              <div className="w-[5px] h-[5px] bg-[#2054D2] rounded-[50%]"></div>

              <p className="text-[12px] font-thin text-[#6A7581]">
                {errors.confirmPassword?.message}
              </p>
            </div>
          )}
        </div>

        <div className="mt-[45px] flex flex-row-reverse">
          <button
            type="submit"
            className="px-[50px] flex gap-[10px] py-[18px] text-white bg-[#2054D2] rounded-[4px]"
          >
            Continue
            {loading && <Loader />}
          </button>
        </div>
      </form>
    </div>
  );
};

export default NewPassword;
