import moment from "moment";
import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getPosTransaction = async ({ queryKey }: { queryKey: any }) => {
  const { page, size, trnxType, startDate, endDate } = queryKey[1];

  let queryParams = `?page=${page}&size=${size}`;

  if (startDate) {
    queryParams = `${queryParams}&from=${moment(startDate).format(
      "YYYY-MM-DD"
    )}`;
  }
  if (endDate) {
    queryParams = `${queryParams}&to=${moment(endDate).format("YYYY-MM-DD")}`;
  }
  if (trnxType) {
    queryParams = `${queryParams}&transaction_type=${trnxType}`;
  }
  const data = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.POS_TRANSACTIONS}${queryParams}`
  );
  return data;
};

export const usePosTransaction = ({
  page,
  size,
  trnxType,
  startDate,
  endDate,
}: {
  page: number;
  size: number;
  trnxType?: string;
  startDate?: Date | null;
  endDate?: Date | null;
}) => {
  return useQuery(
    ["PosTransaction", { page, size, trnxType, startDate, endDate }],
    getPosTransaction,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
