import closeIcon from "../assets/images/svg/close-icon.svg";
import { SearchStateType } from "../interfaces/home";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment from "moment";

export default function DateRangeModal({
  startDate,
  setSearchState,
  endDate,
  searchState,
  setCloseModal,
  startDateName,
  endDateName,
  showModal,
}: {
  startDate?: Date;
  setSearchState: (newState: SearchStateType) => void;
  endDate?: Date;
  searchState: SearchStateType;
  setCloseModal: (newState: boolean) => void;
  startDateName: string;
  endDateName: string;
  showModal: boolean;
}) {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams({});
  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none  overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between max-w-[501px]">
            <div></div>
            <div
              onClick={() => {
                setCloseModal(!showModal);
              }}
            >
              <img src={closeIcon} alt="" className="cursor-pointer" />
            </div>
          </div>
          {/*content*/}
          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none max-h-[320px]">
            <div className="bg-white p-6 flex flex-col justify-center">
              <p className="pb-4 border-b">Filter by:</p>

              <div className="mt-4 w-full">
                <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                  Filter Date:
                </p>
                <div className="flex items-center">
                  <div className="">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={start}
                      onChange={(date: Date) => {
                        setStart(date);
                      }}
                      className="h-10 pl-3 border mr-[31px] border-aellaLightGray rounded-lg text-aellaBlack lg:leading-[16px] text-[16px] font-[300]"
                    />
                  </div>

                  <div className="">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={end}
                      minDate={start}
                      onChange={(date: Date) => {
                        setEnd(date);
                      }}
                      className="h-10 pl-2 border border-aellaLightGray rounded-lg text-aellaBlack lg:leading-[16px] text-[16px] font-[300]"
                    />
                  </div>
                </div>

                <div className="flex justify-between bg-white pt-10">
                  <div></div>
                  <button
                    className="border-aellaBlue border rounded py-2 cursor-pointer text-center text-aellaBlue  w-[121px] "
                    onClick={() => {
                      setSearchState({
                        ...searchState,
                        [startDateName]: new Date(year, month, 1),
                        [endDateName]: new Date(),
                      });
                      setSearchParams({
                        [startDateName]: moment(
                          new Date(year, month, 1)
                        ).format("YYYY-MM-DD"),
                        [endDateName]: moment(new Date()).format("YYYY-MM-DD"),
                      });
                      setStart(new Date(year, month, 1));
                      setEnd(new Date());
                    }}
                  >
                    Reset
                  </button>
                  <button
                    className="border border-aellaBlue rounded bg-aellaBlue text-white cursor-pointer w-[121px]"
                    onClick={() => {
                      setSearchState({
                        ...searchState,
                        [startDateName]: start,
                        [endDateName]: end,
                      });
                      setSearchParams({
                        [startDateName]: moment(start).format("YYYY-MM-DD"),
                        [endDateName]: moment(end).format("YYYY-MM-DD"),
                      });
                      setCloseModal(!showModal);
                    }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setCloseModal(!showModal);
        }}
      ></div>
    </>
  );
}
