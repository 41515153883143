import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import { useState } from "react";
import Lottie from "react-lottie-player";
import Sparkles from "../../../assets/lottie/sparkles.json";
import OldPassword from "../Components/OldPassword";
import NewPassword from "../Components/NewPassword";

export default function ChangePasswordModal({
  setCloseModal,
  showModal,
}: {
  setCloseModal: (newState: boolean) => void;
  showModal: boolean;
}) {
  const [step, setStep] = useState<number>(1);
  const [state, setState] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const style = {
    height: 200,
    width: 200,
  };

  const renderContent = () => {
    switch (step) {
      case 1:
        return (
          <OldPassword setStep={setStep} state={state} setState={setState} />
        );

      case 2:
        return (
          <NewPassword setStep={setStep} state={state} setState={setState} />
        );
      case 3:
        return (
          <div className="p-[15px] md:p-[40px] pt-[88px] relative">
            <div className="absolute left-[50%] translate-x-[-50%] top-[40px]">
              <Lottie animationData={Sparkles} style={style} play speed={1} />
            </div>

            <p className="text-[26px] text-[#2054D2] text-center mb-[80px]">
              Successful
            </p>

            <p className="text-[24px] text-[#0B1E4B] text-center">
              Password Updated
            </p>

            <p className="mt-[16px] text-[#5B5B5B] font-thin text-center mb-[40px]">
              You can now login with your new password.
            </p>

            <div className="flex justify-center">
              <button
                onClick={() => {
                  setCloseModal(!showModal);
                }}
                className="px-[42px] py-[16px] rounded-[4px] text-white font-thin bg-[#2054D2] inline-block "
              >
                Got It!
              </button>
            </div>
          </div>
        );
      default:
        return;
    }
  };
  return (
    <>
      <div className="absolute justify-center items-center flex inset-0 z-50 outline-none focus:outline-none  overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between w-[97%] md:w-[501px]">
            <div></div>
            <div
              className=""
              onClick={() => {
                setCloseModal(!showModal);
              }}
            >
              <img
                src={CloseIcon}
                alt="close modal"
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none">
            <div className="bg-white flex flex-col justify-center rounded-[8px] ">
              <div className="">
                <div className="">{renderContent()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"></div>
    </>
  );
}
