import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllStates = async ({ queryKey }: { queryKey: any }) => {
  const { id } = queryKey[1];
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_STATES}/getbycountryid/${id}`
  );
  return data;
};

export const useAllStates = ({ id }: { id: string }) => {
  return useQuery(
    ["getAllStates", { id }],

    getAllStates,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
      enabled: id ? true : false,
    }
  );
};
