import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { toast } from "react-toastify";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { postData } from "../../apis/apiMethods";
import Tab from "../../component/Tab/Tab.component";
import CONFIG from "../../helpers/config";
import { errorHandler } from "../../helpers/errorHandler";
import { ROUTES } from "../../helpers/routes";
import { useMerchantServices } from "../../hooks/useMerchantServices";
import PosRequestDrawer from "./Drawer/PosRequestDrawer";
import PosModal from "./Modal/PosModal";

export type RequestsType = {
  id?: any;
  delivery_address?: string;
  quantity?: any;
  edit?: boolean;
};

type ContextType = { walletId: string };
const Pos = () => {
  const [walletId, setWalletId] = useState("");
  const { data: merchantServices } = useMerchantServices();
  const [isOpen, setIsOpen] = useState(false);
  const [singleAddress, setSingleAddress] = useState(true);
  const [modalType, setModalType] = useState("");
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState<Array<RequestsType>>([
    {
      id: 1,
      delivery_address: "",
      quantity: 0,
      edit: true,
    },
  ]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ROUTES.POS) {
      navigate(ROUTES.POS_OVERVIEW); // Redirect to the default tab when the component mounts
    }
  }, [navigate, location]);

  useEffect(() => {
    if (merchantServices) {
      const merchantService = merchantServices.find(
        (obj: any) => obj?.name?.toLowerCase() === "pos"
      );
      setWalletId(merchantService.wallet_id);
    }
  }, [merchantServices]);

  const onAddMore = (e: any) => {
    e.preventDefault();

    const updatedRequests: Array<any> = requests?.map((obj: RequestsType) => {
      return { ...obj, edit: false };
    });

    setRequests([
      ...updatedRequests,
      {
        id: requests.length + 1,
        delivery_address: "",
        quantity: 0,
        edit: true,
      },
    ]);
  };

  const deleteDetail = (id?: number) => {
    const remainingRequests = requests.filter(
      (request: RequestsType) => request.id !== id
    );
    if (remainingRequests?.length > 0) {
      setRequests([...remainingRequests]);
    } else {
      setRequests([
        {
          id: 1,
          delivery_address: "",
          quantity: 0,
          edit: true,
        },
      ]);
    }
  };
  const onSubmitRequest = async () => {
    const reqBody = {
      full_name: fullName,
      phone_number: phoneNumber,
      request: requests,
    };

    setLoading(true);
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.POS_REQUESTS}`,
        reqBody
      );
      setModalType("step2");
      setIsOpen(false);
      queryClient.invalidateQueries("posRequests");
      queryClient.invalidateQueries("posAnalytics");
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
    setFullName("");
    setRequests([
      {
        id: 1,
        delivery_address: "",
        quantity: 0,
        edit: true,
      },
    ]);
    setSingleAddress(true);
    setPhoneNumber("");
  };

  return (
    <>
      <div className=" bg-aellaDimGray overflow-auto h-screen">
        <div className=" bg-white px-10 ">
          <div className="border-t border-[#F1F1F1] flex justify-between items-center pt-2 pb-[1px] w-full flex-wrap">
            <div className="flex flex-wrap">
              <Tab text="Overview" path={ROUTES.POS_OVERVIEW} />
              <Tab text="Requests" path={ROUTES.POS_REQUESTS} />
              <Tab text="Transactions" path={ROUTES.POS_TRANSACTIONS} />
              <Tab text="Disputes" path={ROUTES.POS_DISPUTES} />
              <Tab text="Wallet" path={ROUTES.POS_WALLETS} />
            </div>

            <button
              className=" px-[36px] py-[14px] mt-1 lg:mt-0 flex rounded-[6px] bg-aellaBlue text-white  items-center transition ease-in-out duration-300  font-normal text-[14px]"
              onClick={() => setIsOpen(true)}
            >
              Request POS
            </button>
          </div>
        </div>
        <div className="">
          <Outlet
            context={{
              walletId,
            }}
          />
        </div>
      </div>

      <PosRequestDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onAddMore={onAddMore}
        requests={requests}
        setSingleAddress={setSingleAddress}
        singleAddress={singleAddress}
        setRequests={setRequests}
        deleteDetail={deleteDetail}
        setModalType={setModalType}
        setFullName={setFullName}
        setPhoneNumber={setPhoneNumber}
        loading={loading}
        fullName={fullName}
        phoneNumber={phoneNumber}
      />
      {modalType && (
        <PosModal
          modalType={modalType}
          setModalType={setModalType}
          setIsOpen={setIsOpen}
          requests={requests}
          loading={loading}
          setRequests={setRequests}
          onSubmitRequest={onSubmitRequest}
        />
      )}
    </>
  );
};

export default Pos;

export function usePosContext() {
  return useOutletContext<ContextType>();
}
