import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllMerchantServices = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_MERCHANT_SERVICES}`
  );
  return data;
};

export const useAllMerchantServices = () => {
  return useQuery(
    ["getAllMerchantServices"],

    getAllMerchantServices,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
