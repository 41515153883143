import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import { useState } from "react";
import Lottie from "react-lottie-player";
import Sparkles from "../../../assets/lottie/sparkles.json";
import { ReactComponent as Locker } from "../../../assets/images/svg/locker-icon.svg";
import { ReactComponent as Eye } from "../../../assets/images/svg/eye-icon.svg";
import { ReactComponent as Copy } from "../../../assets/images/svg/copy-icon.svg";

const GenerateAPIModal = ({
  setCloseModal,
  showModal,
}: {
  setCloseModal: (newState: boolean) => void;
  showModal: boolean;
}) => {
  const [state, setState] = useState("generate");
  const style = {
    height: 200,
    width: 200,
  };

  const renderContent = () => {
    switch (state) {
      case "generate":
        return (
          <div className="p-[40px] relative">
            <p className="text-[24px] text-[#0B1E4B] ">Please Note</p>

            <p className="mt-[16px] text-[#5B5B5B] font-thin mb-[40px]">
              Kindly note that by regenerating your API key, the old key will be
              revoked and no longer be function. To continue, input the password
              tied to this account or cancel to go back.
            </p>

            <div>
              <label className="text-[14px] text-[#232323] font-thin">
                Enter password tied to this account
              </label>
              <div className="relative">
                <input
                  type="text"
                  placeholder="Enter Link"
                  className="h-[48px] w-full outline-none  mt-[8px] text-[14px] text-[#0B1E4B] font-thin pl-[40px] border border-[#2323232E] rounded-[8px]"
                />
                <Locker className="absolute left-[15px] top-[55%] translate-y-[-50%]" />
                <Eye className="absolute right-[16px] top-[50%]" />
              </div>
            </div>

            <div className="flex justify-center mt-[40px]">
              <button
                onClick={() => {
                  setState("success");
                  //   setCloseModal(!showModal);
                }}
                className="px-[42px] py-[16px] rounded-[4px] text-white font-thin bg-[#2054D2] inline-block "
              >
                Re-generate API Key
              </button>
            </div>
          </div>
        );
      case "success":
        return (
          <div className="p-[40px] pt-[88px] relative">
            <div className="absolute left-[50%] translate-x-[-50%] top-[40px]">
              <Lottie animationData={Sparkles} style={style} play speed={1} />
            </div>

            <p className="text-[26px] text-[#2054D2] text-center mb-[80px]">
              Successful
            </p>

            <p className="text-[24px] text-[#0B1E4B] text-center">
              New API Key Generated
            </p>

            <p className="mt-[16px] text-[#5B5B5B] font-thin text-center mb-[40px]">
              Your new API key can be found below.
            </p>

            <div className="mb-[40px]">
              <div className="relative">
                <input
                  type="text"
                  readOnly
                  defaultValue="gshjRTyu56789HSAghjfcvbn34567GHBnm"
                  className="h-[40px] w-full outline-none bg-[#EDEFF5] mt-[8px] text-[14px] text-[#0B1E4B] font-thin pl-[16px] border border-[#E6E9F3] rounded-[8px]"
                />
                <Copy className="absolute right-[16px] top-[50%]" />
              </div>
            </div>

            <div className="flex justify-center">
              <button
                onClick={() => {
                  setCloseModal(!showModal);
                }}
                className="px-[42px] py-[16px] rounded-[4px] text-white font-thin bg-[#2054D2] inline-block "
              >
                Go Back
              </button>
            </div>
          </div>
        );

      default:
        return;
    }
  };
  return (
    <>
      <div className="absolute justify-center items-center flex inset-0 z-50 outline-none focus:outline-none  overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between w-[501px]">
            <div></div>
            <div
              onClick={() => {
                setCloseModal(!showModal);
              }}
            >
              <img
                src={CloseIcon}
                alt="close modal"
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none">
            <div className="bg-white flex flex-col justify-center rounded-[8px]">
              <div className="">
                <div className="">{renderContent()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"></div>
    </>
  );
};

export default GenerateAPIModal;
