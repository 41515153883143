import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const CardSkeletonLoader = ({
  width,
  height,
}: {
  width?: number;
  height?: number;
}) => {
  return (
    <>
      <SkeletonTheme
        baseColor="#E8EEF4"
        highlightColor="#F9FCFF"
        borderRadius="0.5rem"
        duration={4}
      >
        <div>
          <Skeleton height={height || 270} width={width || 223} />
        </div>
      </SkeletonTheme>
    </>
  );
};
export default CardSkeletonLoader;
