import moment from "moment";
import { truncateString } from "../../../helpers/formatter";
import { useWithdrawalData } from "../Wallet";

export default function WalletAccounts() {
  const { data } = useWithdrawalData();
  return (
    <>
      <div className="bg-aellaDimGray w-full">
        <div className="px-10 py-[32px] mb-10  w-full">
          <div className="p-[48px] bg-white">
            <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350] pb-6">
              Active Accounts ({data?.length})
            </h1>
            <div className="w-full overflow-x-auto ">
              {data?.map((eachData: any, index: number) => {
                return (
                  <div
                    className={`flex p-6 justify-between border-b border-[#EDEFF5]${
                      data?.length > 1 && "last:border-none "
                    } items-start`}
                    key={index}
                  >
                    <div className="flex items-center">
                      <div className="mr-3.5 rounded-[4px] h-12 w-12 items-center">
                        <img
                          src={eachData?.bank_logo}
                          className="h-10 w-10"
                          alt=""
                        />
                      </div>
                      <div className="flex flex-col items-center min-w-[200px]">
                        <th
                          scope="row"
                          className="text-[#7B7B7B] font-[300] text-[14px] leading-[17px] tracking-[0.2px]"
                        >
                          Bank Name
                        </th>
                        <td className="text-[#0B1E4B] font-[200] text-[16px] leading-[22px] tracking-[0.2px] capitalize">
                          {eachData?.bank_name}
                        </td>
                      </div>
                    </div>
                    <div className="flex flex-col items-center min-w-[200px]">
                      <th className="text-[#7B7B7B] font-[300] text-[14px] leading-[17px] tracking-[0.2px]">
                        Account Name
                      </th>
                      <td className="text-[#0B1E4B] font-[200] text-[16px] leading-[22px] tracking-[0.2px]">
                        {truncateString(eachData?.account_name, 13)}
                      </td>
                    </div>
                    <div className="flex flex-col items-center min-w-[200px]">
                      <th className="text-[#7B7B7B] font-[300] text-[14px] leading-[17px] tracking-[0.2px]">
                        Account Number
                      </th>
                      <td className="text-[#0B1E4B] font-[200] text-[16px] leading-[22px] tracking-[0.2px]">
                        {eachData?.account_number}
                      </td>
                    </div>
                    <div className="flex flex-col items-center min-w-[200px]">
                      <th className="text-[#7B7B7B] font-[300] text-[14px] leading-[17px] tracking-[0.2px]">
                        Date Created
                      </th>
                      <td className="text-[#0B1E4B] font-[200] text-[16px] leading-[22px] tracking-[0.2px]">
                        {moment(eachData?.created_at).format("MMM. Do, YYYY")}
                      </td>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
