import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import {
  IProfileStepThree,
  ProfileSetUpFormData,
} from "../../../interfaces/profileSetUp";
import { Loader } from "../../../component/Loader/Loader.component";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CustomTextArea from "../../../component/CustomHTMLElements/CustomTextArea";
import RegisteredBusinessForm from "./BusinessTypes/RegisteredBusinessForm";
import IndividualBusinessForm from "./BusinessTypes/IndividualBusinessForm";
import { convertToBase64 } from "../../../helpers/convertToBase64";
import { toast } from "react-toastify";

const schema = yup.object().shape({
  description: yupValidators.genericRequired({
    min: 1,
    max: 150,
  }),
  legalName: yupValidators.businessName,
  tradeName: yupValidators.businessName,
});

const ProfileStepThree: React.FC<IProfileStepThree> = ({
  submitHandler,
  state,
  merchantProfileData,
}) => {
  const [businessType, setBusinessType] = useState("");

  const [filesToBeUploaded, setFilesToBeUploaded] = useState<any>([]);
  const [identification, setIdentification] = useState("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ProfileSetUpFormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (merchantProfileData) {
      setValue("tradeName", merchantProfileData?.trading_name);
      setValue("description", merchantProfileData?.description);
      setValue("legalName", merchantProfileData?.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantProfileData]);


  
  const onFileChange = async ({
    e,
    fileType,
    id,
  }: {
    e: any;
    fileType: string;
    id: string;
  }) => {
    const fileName = e.target.files[0].name;
    const fileAcceptType = e.target.files[0].type;
    const isValidFile =
      fileAcceptType.startsWith("image/png") ||
      fileAcceptType.startsWith("image/jpg") ||
      fileAcceptType.startsWith("image/jpeg") ||
      fileAcceptType.startsWith("application/pdf");
    if (!isValidFile) {
      toast.error(
        "Invalid file extension. Kindly upload a pdf,word,png,jpeg or jpg file"
      );
      return (e.target.value = null);
    }
    if (e.target.files[0].size > 10000000) {
      toast.error(
        "File size is too large. Max file size is 10mb. Kindly reupload another Document"
      );
      return (e.target.value = null);
    }
    const fileBase64 = await convertToBase64(e.target.files[0]);
    const extension = fileName.split(".").pop();

    let newFile = {} as {
      extension: string;
      photo: string;
      type: string;
      id: string;
    };
    newFile = {
      extension,
      photo: fileBase64?.split("base64,")[1],
      type: fileType,
      id,
    };

    const existingfileItem = filesToBeUploaded.find(
      (file: any) => file.id === newFile.id
    );

    if (filesToBeUploaded?.length > 0) {
      if (existingfileItem) {
        const newFiles = filesToBeUploaded.map((file: any) => {
          return file.id === newFile.id ? { ...newFile } : file;
        });

        setFilesToBeUploaded(newFiles);
      } else {
        let newFiles = [...filesToBeUploaded, newFile];
        setFilesToBeUploaded(newFiles);
      }
    } else {
      let newFiles = [newFile];
      setFilesToBeUploaded(newFiles);
    }
  };

  const onSubmit = async ({
    tradeName,
    legalName,
    description,
  }: {
    tradeName: string;
    legalName: string;
    description: string;
  }) => {
    const reqBody = {
      merchant: {
        stage: 3,
        merchant_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID),
      },

      stage_three: {
        business_type: businessType,
        document: filesToBeUploaded,
        name: legalName,
        trading_name: tradeName,
        description: description,
      },
    };
    await submitHandler(reqBody);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Business Type
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
          What type of business do you own?
        </p>

        <CustomInputField
          type="text"
          maxLength={128}
          label="Trading Name"
          extraLabel="This is the name by which your company is commonly known"
          errors={errors.tradeName}
          {...register("tradeName")}
          placeholder="Trading Name"
          readOnly={merchantProfileData?.trading_name}
        />
        <CustomInputField
          type="text"
          maxLength={128}
          label="Legal Name"
          errors={errors.legalName}
          {...register("legalName")}
          placeholder="Legal Name"
        />
        <CustomTextArea
          maxLength={50}
          label="Business Description"
          errors={errors.description}
          {...register("description")}
          placeholder="Tell us something about your business"
          readOnly={merchantProfileData?.description}
          bottomLabel=" A maximum of 150 characters"
        />

        <div className="flex items-start mt-[34px]">
          <input
            type="radio"
            className="mt-1 shrink-0"
            checked={businessType === "Individual"}
            onChange={() => {
              setBusinessType("Individual");
              setFilesToBeUploaded([]);
            }}
          />
          <div className="flex flex-col">
            <label className="ml-2.5 text-[16px] lg:leading-[16px] font-[300] tracking-[0.03px]">
              Individual Business
            </label>
            <p className="ml-2.5 text-[16px] lg:leading-[24px] font-[300] tracking-[0.03px] text-[#5B5B5B] pt-2.5">
              Your business does not have to be registered with Coporate Affairs
              Commission.
            </p>
          </div>
        </div>
        {businessType.toLowerCase() === "individual" && (
          <IndividualBusinessForm
            onFileChange={onFileChange}
            setIdentification={setIdentification}
            identification={identification}
          />
        )}

        <div className="flex items-start pt-[34px]">
          <input
            type="radio"
            className="mt-1 shrink-0"
            checked={businessType === "Registered"}
            onChange={() => {
              setBusinessType("Registered");
              setFilesToBeUploaded([]);
            }}
          />

          <div className="flex flex-col">
            <label className="ml-2.5 text-[16px] lg:leading-[16px] font-[300] tracking-[0.03px]">
              Registered Business
            </label>
            <p className="ml-2.5 text-[16px] lg:leading-[24px] font-[300] tracking-[0.03px] text-[#5B5B5B] pt-2.5">
              Your business must be registered with the Coporate Affairs
              Commission.
            </p>
          </div>
        </div>
        {businessType.toLowerCase() === "registered" && (
          <RegisteredBusinessForm
            onFileChange={onFileChange}
            setIdentification={setIdentification}
            identification={identification}
          />
        )}

        <div className="flex justify-between xxl:mt-7 mt-10 mb-32">
          <div></div>
          <div></div>
          <div></div>

          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex justify-center cursor-pointer"
            disabled={
              state.loading ||
              (businessType.toLowerCase() === "individual"
                ? filesToBeUploaded?.length < 2
                : filesToBeUploaded?.length < 6)
            }
          >
            Save and Continue
            {state.loading && <Loader />}
          </button>
        </div>
      </form>
    </>
  );
};
export default ProfileStepThree;
