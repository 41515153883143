import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getMerchantTransferFee = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_TRANSFER_FEE}`
  );
  return data;
};

export const useMerchantTransferFee = ({ loadFee }: { loadFee: boolean }) => {
  return useQuery("getMerchantTransferFee", getMerchantTransferFee, {
    enabled: loadFee ? true : false,
    refetchOnWindowFocus: false, // Refetch on window focus
  });
};
