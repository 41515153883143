export const attachClassNameToTag = (element: string) => {
  const item = element ? element?.toLowerCase() : "";
  if (item === "pending" || item === "processing") {
    return "text-[#F2994A] bg-[#F2994A] bg-opacity-25 h-[27px] py-[4px] px-[8px] rounded";
  }
  if (item === "awaiting feedback") {
    return "text-[#5E5ADB] bg-[#5E5ADB] bg-opacity-20 h-[27px] py-[4px] px-[8px] rounded";
  }
  if (
    item === "success" ||
    item === "1" ||
    item === "delivered" ||
    item === "approved" ||
    item === "accept dispute" ||
    item === "resolved"
  ) {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }
  if (
    item === "failed" ||
    item === "0" ||
    item === "declined" ||
    item === "fail" ||
    item === "decline"
  ) {
    return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
  }
};
export const getStatusName = (element: string) => {
  const item = element ? element?.toLowerCase() : "";
  if (item === "pending") {
    return "Pending";
  }
  if (item === "success" || item === "resolved") {
    return "Success";
  }
  if (item === "reopened") {
    return "Reopened";
  }
  if (item === "accept dispute") {
    return "Accepted";
  }
  if (item === "awaiting feedback") {
    return "Awaiting Feedback";
  }
  if (item === "in-progress") {
    return "In-Progress";
  }
  if (item === "delivered") {
  }
  if (item === "success" || item === "resolved") {
    return "Success";
  }
  if (item === "processing") {
    return "Processing";
  }
  if (item === "delivered") {
    return "Delivered";
  }
  if (item === "approved") {
    return "Approved";
  }
  if (item === "failed" || item === "fail") {
    return "Failed";
  }
  if (item === "declined" || item === "decline") {
    return "Declined";
  }
  if (item === "1") {
    return "Active";
  }
  if (item === "0") {
    return "Not Active";
  }
};

export const attachClassNameToStatus = (element: number) => {
  const item = (element && element === 1) || element === 0 ? element : "";
  if (item === 0) {
    return "text-[#eb5757] bg-[#FBDDDD] h-[27px] py-[4px] px-[8px] rounded";
  }
  if (item === 1) {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }
};
export const getStatusNameByNumber = (element: Number) => {
  const item = (element && element === 1) || element === 0 ? element : "";
  if (item === 1) {
    return "Successful";
  }
  if (item === 0) {
    return "Failed";
  }
};
export const attachClassNameAccount = (element: number) => {
  const item =
    (element && element === 1) || (element && element === 2) || element === 0
      ? element
      : "";
  if (item === 0) {
    return "text-[#EB5757] bg-[#fbdddd] h-[27px] py-[4px] px-[8px] rounded";
  }
  if (item === 1) {
    return "text-[#219653] bg-[#E9F4EE] h-[27px] py-[4px] px-[8px] rounded";
  }

  if (item === 2) {
    return "text-[#F2994A] bg-[#F2994A] bg-opacity-25 h-[27px] py-[4px] px-[8px] rounded";
  }
};
export const getStatusNameAccount = (element: Number) => {
  const item =
    (element && element === 1) || (element && element === 2) || element === 0
      ? element
      : "";
  if (item === 0) {
    return "Deactivated";
  }
  if (item === 1) {
    return "Active";
  }

  if (item === 2) {
    return "Pending";
  }
};
