import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getTransfersList = async ({ queryKey }: { queryKey: any }) => {
  const { page, size, from, to } = queryKey[1];

  let queryParams = `?page=${page}&size=${size}&from=${from}&to=${to}`;

  if (!from) {
    queryParams = `?page=${page}&size=${size}&to=${to}`;
  }

  if (!to) {
    queryParams = `?page=${page}&size=${size}&from=${from}`;
  }

  if (!from && !to) {
    queryParams = `?page=${page}&size=${size}`;
  }
  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_TRANSFER_LIST}${queryParams}`
  );
  return res;
};

export const useTransfersList = ({
  page,
  size,
  to,
  from,
}: {
  page?: string | number | undefined;
  size?: string | number | undefined;
  to?: string | number | undefined;
  from?: string | number | undefined;
}) => {
  return useQuery(
    ["getTransferList", { page, size, from, to }],
    getTransfersList,
    {
      //   enabled: ? true : false,
      refetchOnWindowFocus: false,
    }
  );
};

export const getBulkList = async ({ queryKey }: { queryKey: any }) => {
  const { page, limit } = queryKey[1];

  let queryParams = `?limit=${limit}&page=${page}`;
  // ?limit=1&page=1

  // if () {
  //   queryParams = `?page=${page}&size=${size}&to=${to}`;
  // }

  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_BULK_TRANSFER_LIST}${queryParams}`
  );
  return res;
};

export const useBulkList = ({
  page,
  limit,
}: {
  page?: string | number | undefined;
  limit?: string | number | undefined;
}) => {
  return useQuery(["getBulkTransferList", { page, limit }], getBulkList, {
    //   enabled: ? true : false,
    refetchOnWindowFocus: false,
  });
};

export const getGroupedList = async ({ queryKey }: { queryKey: any }) => {
  const { page, limit, selected } = queryKey[1];

  let queryParams = `/group/${selected}?limit=${limit}&page=${page}`;

  const res: any = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_BULK_TRANSFER_LIST}${queryParams}`
  );
  return res;
};

export const useGroupedList = ({
  page,
  limit,
  selected,
  mId,
}: {
  page?: string | number | undefined;
  limit?: string | number | undefined;
  selected?: string | number | undefined;
  mId?: string | number | undefined;
}) => {
  return useQuery(
    ["getGroupedTransferList", { page, limit, selected, mId }],
    getGroupedList,
    {
      // enabled: selected ? true : false,
      refetchOnWindowFocus: false,
    }
  );
};
