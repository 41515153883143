export const ROUTES = {
  LOGIN: "/",
  HOME: "/home",
  RESET_PASSWORD: "/reset-password",
  SIGNUP: "/sign-up",
  TERMS: "/terms",
  WALLET: "/wallet",
  TRANSFERS: "/transfers",
  EVENT: "/event",
  POS: "/pos",
  POS_DISPUTE_DETAILS: "/pos/dispute_details",
  BANK_ACCOUNT: "bank-account",
  PROFILE_SETUP: "/profile-setup",
  INFLOW_TRANSACTIONS: "inflow-transaction",
  // PROFILE_SETTING: "/profile-settings",
  PROFILE_SETTING: "/profile-settings/settings-profile",
  overview: "/pos",
  TERMS_OF_USE: "/terms-of-use",
  CONNECT_SERVICES: "/connect-services",
  BANK_LIST: "/bank-list",
  TRANSACTIONS: "/transactions",
  SUB_ACCOUNTS: "/sub-accounts",
  SUB_ACCOUNTS_DETAILS: "/sub-accounts/details",
  WALLET_BALANCE: "balance",
  WALLET_WITHDRAWAL_ACCOUNT: "withdrawal",
  POS_OVERVIEW: "overview",
  POS_REQUESTS: "requests",
  POS_TRANSACTIONS: "transactions",
  POS_DISPUTES: "disputes",
  POS_WALLETS: "wallets",
  SETTINGS_PROFILE: "/profile-settings/settings-profile",
  SETTINGS_SECURITY: "/profile-settings/settings-security",
  SETTINGS_NOTIFICATIONS: "/profile-settings/settings-notifications",
  SETTINGS_WITHDRAWAL: "/profile-settings/settings-widthdrawal",
  SETTINGS_API: "/profile-settings/settings-api",
  SINGLE_TRANSFER: "single-transfer",
  BULK_TRANSFER: "bulk-transfer",
};
