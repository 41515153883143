import { identificationTypes } from "../../../../helpers/json";
import { ReactComponent as AttachIcon } from "../../../../assets/images/svg/attach-icon.svg";
import { IBusinessForm } from "../../../../interfaces/profileSetUp";

const IndividualBusinessForm: React.FC<IBusinessForm> = ({
  setIdentification,
  onFileChange,
  identification,
}) => {
  return (
    <ul className="list-square list-inside ml-5 pt-[26px]">
      <div className="lg:ml-[11px]">
        <li className="text-[16px] lg:leading-[19px]  font-[300] tracking-[0.03px]">
          Valid means of Identification
        </li>
        <div className="pt-6 flex flex-col lg:flex-row items-center">
          <div className="w-full">
            <label className="text-aellaGray text-[14px] lg:leading-[16px] tracking-[0.03px] font-[300] mb-2">
              Select means of identification{" "}
            </label>
            <select
              placeholder="Select"
              className={`bg-white appearance-none border px-4 placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] rounded w-full h-[48px] py-2  leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white `}
              onChange={(event: any) => setIdentification(event.target.value)}
            >
              <option value="">Select Identification</option>
              {identificationTypes?.map((data: any) => {
                const { id, name } = data;
                return (
                  <option value={id} key={id}>
                    {name}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="flex flex-col lg:ml-[17px] w-full">
            <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mt-1 mb-1">
              Upload document
            </label>
            <div className="relative">
              <input
                type="file"
                accept="application/pdf, image/*"
                onChange={(e) =>
                  onFileChange({
                    e,
                    fileType: identification,
                    id: "1",
                  })
                }
                disabled={!identification}
                className={`bg-white appearance-none border px-4 placeholder:text-[#DADADA] placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] rounded w-full h-[48px] py-2  leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white file:border-none file:bg-white file:text-aellaGray ${
                  identification
                    ? "file:cursor-pointer cursor-pointer"
                    : "file:cursor-not-allowed cursor-not-allowed"
                }`}
              />

              <div className="absolute inset-y-0 right-0 bottom-1 lg:pr-4 pr-2 flex items-center leading-5">
                <span className="text-capitalize">
                  <AttachIcon />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" mt-[33px] lg:ml-[11px]">
        <li className="text-[16px] lg:leading-[19px] font-[300] tracking-[0.03px]">
          Proof of Address (Utility bill)
        </li>

        <div className="flex flex-col w-full pt-6">
          <label className="text-[14px] lg:leading-[16px] font-[300] tracking-[0.03px] text-aellaGray mb-2.5">
            Upload document
          </label>

          <div className="relative w-full">
            <input
              type="file"
              accept="application/pdf , image/*"
              onChange={(e) =>
                onFileChange({
                  e,
                  fileType: "Utility Bill",
                  id: "2",
                })
              }
              placeholder="Attach File"
              className={`bg-white appearance-none border px-4 placeholder:text-[#DADADA]  placeholder:text-[16px] placeholder:lg:leading-[24px] placeholder:font-[100] rounded w-full h-[48px] py-2  leading-6 text-[16px] font-[300] focus:outline-none focus:bg-white mb-5 file:border-none file:bg-white file:text-aellaGray file:cursor-pointer cursor-pointer`}
            />
            <div className="absolute inset-y-0 right-0 bottom-5 pr-2 lg:pr-4 flex items-center leading-5">
              <span className="text-capitalize">
                <AttachIcon />
              </span>
            </div>
          </div>
        </div>
      </div>
    </ul>
  );
};
export default IndividualBusinessForm;
