import { useState } from "react";
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { postData } from "../../../apis/apiMethods";
import { Loader } from "../../../component/Loader/Loader.component";
import CONFIG from "../../../helpers/config";
import { errorHandler } from "../../../helpers/errorHandler";
import {
  // getFromLocalStorage,
  postToLocalStorage,
} from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { ROUTES } from "../../../helpers/routes";
const ProfileStepFive = () => {
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPin, setConfirmPin] = useState("");

  const navigate = useNavigate();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    if (pin.length !== 4 || confirmPin.length !== 4) {
      setLoading(false);
      return toast.error("Invalid Pin");
    }
    if (pin !== confirmPin) {
      setLoading(false);
      return toast.error("Pin must Match");
    }
    const reqBody = {
      pin,
    };

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL1}${apiEndpoints.CREATE_TRANSACTION_PIN}`,
        reqBody
      );
      postToLocalStorage(LOCAL_STORAGE_KEYS.PROFILE_SETUP, true);
      return navigate(ROUTES.HOME, {
        state: { profile: true, profileSuccess: true },
      });
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  return (
    <>
      <form>
        <div className="flex items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Transaction Pin *
          </h3>

          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[26px]">
          Set up your transaction pin to enable you make unlimited transactions
          across your merchant dashboard.
        </p>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-2.5 pb-[24px]">
          Please Note: Ensure you do not forget your pin.
        </p>
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pb-[12px]">
          Enter your transaction Pin
        </p>
        <PinInput
          length={4}
          placeholder="0"
          initialValue=""
          secret={true}
          onChange={(value, index) => setPin(value)}
          type="numeric"
          inputMode="number"
          inputStyle={{
            border: "1px solid #2054D2",
            marginRight: "5px",
            paddingRight: "0px",
            paddingLeft: "0px",
            fontWeight: 100,
          }}
          inputFocusStyle={{ borderColor: "#2054D2" }}
          onComplete={(value, index) => {}}
          autoSelect={true}
        />
        <p className="text-[14px] lg:leading-[19px] font-[100] tracking-[0.03px] text-aellaGray pt-[19px] pb-[12px]">
          Enter your transaction Pin
        </p>
        <PinInput
          length={4}
          placeholder="0"
          initialValue=""
          secret
          onChange={(value, index) => setConfirmPin(value)}
          type="numeric"
          inputMode="number"
          inputStyle={{
            border: "1px solid #2054D2",
            marginRight: "5px",
            paddingRight: "0px",
            paddingLeft: "0px",
            fontWeight: 100,
          }}
          inputFocusStyle={{ borderColor: "#2054D2" }}
          onComplete={(value, index) => {}}
          autoSelect={true}
        />{" "}
        <div className=" xxl:mt-7 mt-7 mb-28 w-[290px]  lg:mb-0 ">
          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded w-[225px] text-white p-[15.5px] items-center flex justify-center cursor-pointer"
            disabled={loading}
            onClick={(e) => onSubmit(e)}
          >
            Set Pin
            {loading && <Loader />}
          </button>
        </div>
      </form>
    </>
  );
};

export default ProfileStepFive;
