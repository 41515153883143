import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import { ROUTES } from "../../helpers/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../helpers/yupValidators";
import * as yup from "yup";
import { errorHandler } from "../../helpers/errorHandler";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { ReactComponent as ShowPasswordIcon } from "../../assets/images/svg/eye-open-icon.svg";
import { ReactComponent as HidePasswordIcon } from "../../assets/images/svg/eye-close-icon.svg";
import { RegistrationFormData } from "../../interfaces/login";
import { Loader } from "../../component/Loader/Loader.component";
import { toast } from "react-toastify";
import { apiEndpoints } from "../../apis/apiEndpoints";
import CONFIG from "../../helpers/config";
import { axiosInstance, postData } from "../../apis/apiMethods";
import { postToLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";

const schema = yup.object().shape({
  email: yupValidators.email,
  password: yupValidators.password,
  firstName: yupValidators.firstName,
  lastName: yupValidators.lastName,
  phoneNumber: yupValidators.phoneNumber,
  businessName: yupValidators.businessName,
  terms: yup
    .boolean()
    .oneOf([true], "Please read and accept the terms and conditions"),
});

interface ISignUpInitialStep {
  setStep: (newState: number) => void;
}

const SignUpInitialStep: React.FC<ISignUpInitialStep> = ({ setStep }) => {
  const [passwordType, setPasswordType] = useState("password");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const onSubmit = async ({
    email,
    password,
    businessName,
    firstName,
    lastName,
    phoneNumber,
  }: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    phoneNumber: number;
    businessName: string;
  }) => {
    setLoading(true);

    const reqBody = {
      is_kabani: false,
      name: businessName,
      user: {
        email,
        first_name: firstName,
        last_name: lastName,
        password,
        phone_number: phoneNumber,
      },
    };

    try {
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.CREATE_MERCHANT}`,
        reqBody
      );
      const data = res.data;
      setLoading(false);
      setStep(2);
      postToLocalStorage(LOCAL_STORAGE_KEYS.EMAIL, data.email);
      postToLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID, data.id);
      postToLocalStorage(LOCAL_STORAGE_KEYS.FIRSTNAME, data.first_name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME, data.name);
      postToLocalStorage(LOCAL_STORAGE_KEYS.PHONENUMBER, data.phone_number);
      postToLocalStorage(LOCAL_STORAGE_KEYS.TOKEN, data.token);
      postToLocalStorage(LOCAL_STORAGE_KEYS.USERID, data.user_id);
      axiosInstance.defaults.headers.common.Authorization = `Bearer ${data.token}`;
      axiosInstance.defaults.headers.common.userid = `${data.id}`;
      axiosInstance.defaults.headers.common.secret = `${data.token}`;
      axiosInstance.defaults.headers.common.type = "Token";
    } catch (error) {
      setLoading(false);
      if (
        errorHandler(error) ===
        "You have an existing Aella account. Kindly proceed to login"
      ) {
        navigate(ROUTES.LOGIN, { state: { reRoute: true } });
      }
      toast.error(errorHandler(error));
    }
  };

  const togglePasswordField = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };

  return (
    <>
      <div className="lg:pr-[185px] md:pr-[64px]  mb-20">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-7 lg:pt-[50px]">
            <h3 className="text-[32px] lg:leading-[38px] font-[300] pb-2">
              Input Profile Details
            </h3>
            <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
              Please provide more information about your business
            </p>
          </div>

          <CustomInputField
            type="text"
            maxLength={128}
            placeholder="Enter business name here"
            label="Business Name"
            errors={errors.businessName}
            showRequiredIcon
            {...register("businessName")}
          />
          <div className="flex flex-col lg:flex-row">
            <div className=" w-full lg:pr-6">
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder="Enter first name"
                label="First Name"
                errors={errors.firstName}
                showRequiredIcon
                {...register("firstName")}
              />
            </div>
            <div className="w-full">
              <CustomInputField
                type="text"
                maxLength={128}
                placeholder="Enter last name"
                label="Last Name"
                errors={errors.lastName}
                showRequiredIcon
                {...register("lastName")}
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className=" w-full lg:pr-6">
              <CustomInputField
                type="email"
                maxLength={128}
                placeholder="User@gmail.com"
                label="Work Email"
                errors={errors.email}
                showRequiredIcon
                {...register("email")}
              />
            </div>
            <div className="w-full">
              <CustomInputField
                type="number"
                maxLength={11}
                placeholder="08100000000"
                label="Phone Number"
                hasIcon="+234"
                errors={errors.phoneNumber}
                showRequiredIcon
                {...register("phoneNumber")}
              />
            </div>
          </div>
          <CustomInputField
            type={passwordType}
            maxLength={128}
            placeholder="********"
            label="Password"
            showRequiredIcon
            errors={errors.password}
            hasActionButton={true}
            autoComplete="new-password"
            actionButtonText={
              passwordType === "password" ? (
                <ShowPasswordIcon />
              ) : (
                <HidePasswordIcon />
              )
            }
            passwordError
            onClickActionButton={togglePasswordField}
            {...register("password")}
          />

          <div className="form-check flex flex-col pb-[29px] pt-[27px]">
            <div className="flex ">
              <input
                className="border-aellaGray focus:ring-0 form-check-input h-6 w-6  md:h-4 md:w-4 border rounded-sm bg-white checked:bg-aellaBlue checked:border-aellaBlue focus:outline-none transition duration-200 md:mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                type="checkbox"
                value=""
                id="terms"
                {...register("terms")}
              />
              <label
                className="font-[200] form-check-label inline-block text-aellaGray lg:leading-[24px] text-[16px]"
                htmlFor="flexCheckDefault"
              >
                By proceeding, you agree to our
                <span className="text-aellaBlue">
                  {" "}
                  <Link to={ROUTES.TERMS_OF_USE} target="_blank">
                    Terms of Use
                  </Link>
                </span>{" "}
                and acknowledge that you have read the{" "}
                <span className="text-aellaBlue">
                  <a
                    href="https://aellaapp.com/privacy-policy"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {" "}
                    Privacy Policy.
                  </a>
                </span>
              </label>
            </div>
            {errors?.terms && (
              <p className="text-left text-[#EB5757] lg:leading-[16px] text-[13px] font-[300]">
                {errors?.terms.message}
              </p>
            )}
          </div>
          <button
            type="submit"
            className="bg-aellaBlue rounded text-white py-4 px-6 items-center w-full flex justify-center cursor-pointer"
            disabled={loading}
          >
            Continue
            <span className="pl-2">
              <NextIcon />{" "}
            </span>
            {loading && <Loader />}
          </button>

          <p className="flex items-center justify-center my-8 text-[16px] lg:leading-[20px] font-[300]">
            <span className="text-aellaGray pr-2">
              Already have an account?{" "}
            </span>
            <Link className="text-aellaBlue" to={ROUTES.LOGIN}>
              Sign In
            </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default SignUpInitialStep;
