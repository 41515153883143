import { useState } from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import ConfirmDelete from "../Components/ConfirmDelete";
import DeleteAccount from "../Components/DeleteAccount";
import UnableToDeleteAccount from "../Components/UnableToDeleteAccount";

const DeleteAccountModal = ({
  setCloseModal,
  showModal,
}: {
  setCloseModal: (newState: boolean) => void;
  showModal: boolean;
}) => {
  const [state, setState] = useState("delete");

  const renderContent = () => {
    switch (state) {
      case "unable":
        return <UnableToDeleteAccount />;
      case "delete":
        return <DeleteAccount navigate={() => setState("confirm")} />;

      case "confirm":
        return <ConfirmDelete />;
      default:
        return;
    }
  };

  return (
    <>
      <div className="absolute justify-center items-center flex inset-0 z-50 outline-none focus:outline-none  overflow-y-scroll">
        <div className="translate-50 mx-auto max-w-[501px]">
          <div className="flex mb-2 justify-between w-[501px]">
            <div></div>
            <div
              onClick={() => {
                setCloseModal(!showModal);
              }}
            >
              <img
                src={CloseIcon}
                alt="close modal"
                className="cursor-pointer"
              />
            </div>
          </div>

          <div className="border-0 rounded-lg flex flex-col w-screen md:w-full bg-colors-white outline-none focus:outline-none">
            <div className="bg-white flex flex-col justify-center rounded-[8px]">
              <div className="">{renderContent()}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"></div>
    </>
  );
};

export default DeleteAccountModal;
