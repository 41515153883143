import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import CONFIG from "../helpers/config";
import { getData } from "../apis/apiMethods";

export const getSortCodes = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_BANK_SORT_CODES}`
  );
  return data;
};

export const useSortCodes = () => {
  return useQuery("getSortCode", getSortCodes);
};
