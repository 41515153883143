import React from "react";

const PasswordInput = ({
  label,
  value,
  actionText,
  action,
}: {
  label: string;
  value: string;
  actionText: string;
  action: any;
}) => {
  return (
    <>
      <div>
        <label className="text-[#0B1E4B] font-thin">{label}</label>
        <input
          className="w-full rounded-[4px] mt-[8px] pl-[12px] outline-none h-[40px] border border-[#0000001A] bg-[#F5F5F5]"
          type="password"
          readOnly
          value={value}
        />
        <div className="text-[12px] mt-[8px] font-thin text-[#6A7581]">
          Click here to{" "}
          <span
            onClick={action}
            className="font-[400] text-[#2054D2] cursor-pointer"
          >
            {actionText}
          </span>
        </div>
      </div>
    </>
  );
};

export default PasswordInput;
