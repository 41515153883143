import { useState } from "react";
import declineIcon from "../../../assets/images/svg/decline-icon.svg";
import locationIcon from "../../../assets/images/svg/location-icon.svg";
import Tab from "../../../component/Tab/Tab.component";
import { useLocation } from "react-router-dom";
import {
  attachClassNameAccount,
  getStatusNameAccount,
} from "../../../helpers/attachClassNameToTag";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/images/svg/back-icon.svg";
import SubAccountTransactions from "./SubAccountTransactions";
import SubAccountsModal from "../Modal/SubAccountsModal";
import { useAllSubAccountDetails } from "../../../hooks/useAllSubAccountDetails";
import { useSubAccountAccountDetails } from "../../../hooks/useSubAccountAccountDetails";
import { getFirstLetter } from "../../../helpers/capitalizeFirstLetter";
import { currencyFormat } from "../../../helpers/formatter";
import { SpinLoader } from "../../../component/Loader/Loader.component";

type OptionsType = { id: string; name: string; icon: any };
// const ReActivateOptions: Array<OptionsType> = [
//   { id: "reactivate", name: "Reactivate Sub-account", icon: acceptIcon },
// ];

// const deActivateOptions: Array<OptionsType> = [
//   { id: "deactivate", name: "Deactivate Sub-account", icon: declineIcon },
// ];

export type AcceptDisputeType = {
  customerName?: string;
  customerEmail?: string;
  phoneNumber?: string;
  deliveryDate?: Date;
  deliveryAddress?: string;
  details?: string;
  refundOption?: string;
  amount?: number;
  reason?: string;
};

const SubAccountDetails = () => {
  const [actionModal, setActionModal] = useState("");
  const [activeTab, setActiveTab] = useState("transactions");
  const { state } = useLocation();

  const { data } = Object(state) as {
    data: any;
  };
  // const handleAction = (id: string) => {
  //   setActionModal(id);
  // };
  const { data: AllSubAccountDetails, isLoading: AllSubAccountDetailsLoading } =
    useAllSubAccountDetails({ id: data?.id });
  const {
    data: subAccountAccountDetails,
    isLoading: subAccountAccountDetailsLoading,
  } = useSubAccountAccountDetails({ id: data?.wallet_id });

  const Name = data?.name?.split(" ");

  const navigate = useNavigate();

  return (
    <>
      <div className="border-t bg-aellaDimGray">
        <div className="flex items-center py-[12px] lg:py-[14px]  justify-between px-10 bg-white border-b border-[#EDEFF5]">
          <div
            className="flex items-center cursor-pointer"
            onClick={() => navigate(-1)}
          >
            <BackIcon />
            <span className="pl-[10px] text-[18px] font-[100] lg:leading-[30px] tracking-[0.3px]">
              Back
            </span>
          </div>
          {/* <Menu as="div" className="relative inline-block">
            <div>
              <Menu.Button
                className="inline-flex w-full items-center rounded bg-aellaBlue px-3 py-3 text-sm font-[300] text-white tracking-[0.02] hover:bg-opacity-80 focus:outline-none disabled:bg-opacity-30"
                disabled={
                  AllSubAccountDetails?.status === 0 ||
                  AllSubAccountDetails?.status === "0"
                }
              >
                Manage Sub Account
                <div className="w-[20px] h-[16px] ml-2">
                  <DropDownIcon />
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right p-2 rounded-md border border-[#EDEFF5] bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {deActivateOptions.map((option: any) => (
                  <Menu.Item key={option.id} as={Fragment}>
                    {({ active }) => (
                      <p
                        onClick={() => handleAction(option.id)}
                        className={`${
                          active
                            ? "bg-aellaBlue text-white rounded"
                            : "text-aellaGray"
                        } px-[11px] py-2 text-sm flex items-center cursor-pointer `}
                      >
                        <div className="pr-2.5">
                          <img src={option.icon} alt="" className="" />
                        </div>
                        {option.name}
                      </p>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu> */}
        </div>

        <div className="flex flex-col lg:flex-row items-start justify-between mt-10 w-full">
          <div className="w-full lg:w-[35%] ">
            <div className="py-4 px-10 w-full bg-white rounded-lg border border-[#EDEFF5] mr-6 flex items-center">
              <div className="rounded-full inline-flex bg-[#E5B7A0] w-[56px] h-[56px] items-center justify-center top-6  cursor-pointer mr-1 text-aellaBlue">
                {getFirstLetter(Name[0])}
                {getFirstLetter(Name[1])}
              </div>
              <p className="text-[16px] leading[19px] pl-2 font-[300] tracking-[0.2px]">
                {data?.name}
              </p>
            </div>
            <div className="bg-white py-4 pr-6 pl-10 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-[14px] leading-[16px] font-[400] text-aellaGray">
                Sub Account Balance
              </h1>

              <div className="text-[32px] leading[30px] pt-6 font-[200] -tracking-[0.2px]">
                {subAccountAccountDetailsLoading ? (
                  <SpinLoader />
                ) : subAccountAccountDetails ? (
                  currencyFormat(subAccountAccountDetails?.balance)
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className="bg-white py-6 pr-6 pl-10 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
                USER ADDRESS
              </h1>
              <div className="pt-6">
                <div className="flex items-center">
                  <img src={locationIcon} alt="" />{" "}
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray pl-2">
                    {data?.address || "N/A"}
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-white py-6 pr-6 pl-10 mt-4 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
                SUB-ACCOUNT INFO
              </h1>
              <div className="pt-6">
                <div className="flex items-center justify-between">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Name:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] flex-wrap text-end">
                    {data?.name}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    ID:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                    {data?.id}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Email Address:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                    {data?.email}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Phone Number:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                    {data?.phone_number}
                  </p>
                </div>

                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Status:{" "}
                  </p>
                  {AllSubAccountDetailsLoading ? (
                    <SpinLoader />
                  ) : (
                    <p
                      className={`text-[14px] leading[17px] font-[350] tracking-[0.4px] ${attachClassNameAccount(
                        AllSubAccountDetails?.status
                      )}`}
                    >
                      {getStatusNameAccount(AllSubAccountDetails?.status)}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white py-6 pr-6 pl-10 my-4 rounded-lg border-[#EDEFF5] border w-full ">
              <h1 className="text-[16px] leading[20px] font-[400] py-4 border-b border-[#EDEFF5]">
                BANK ACCOUNT
              </h1>
              <div className="pt-6 ">
                <div className="flex items-center justify-between">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Bank Name:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] flex-wrap text-end">
                    Flourish MFB
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Account Name:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                    {subAccountAccountDetails?.first_name}
                  </p>
                </div>
                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Account Number:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px]">
                    {subAccountAccountDetails?.account_number}
                  </p>
                </div>

                <div className="flex items-center justify-between mt-[17px]">
                  <p className="text-[14px] leading[17px] font-[200] tracking-[0.2px] text-aellaGray">
                    Date Created:
                  </p>
                  <p className="text-[14px] leading[17px] font-[400] tracking-[0.4px] text-end">
                    {moment(data?.created_at).format("DD MMM YYYY, LT")}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[60%] w-full mt-3 lg:mt-0 justify-self-end mr-10">
            <div className="w-full bg-white rounded-t-lg px-6 pt-2">
              <div className="border-b border-[#C5D4F6] pb-[1px] w-full">
                <div className="flex flex-wrap">
                  <Tab
                    text="Transactions"
                    id="transactions"
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </div>
            </div>
            <div className="w-full py-[17.5px] px-6 bg-[#EDF2FF]">
              <p className="text-[16px] leading[16px] font-[400] capitalize">
                {activeTab}
              </p>
            </div>
            <div className="bg-white px-6 py-[34px]">
              {(() => {
                switch (activeTab) {
                  case "transactions":
                    return <SubAccountTransactions id={data?.wallet_id} />;

                  default:
                    return;
                }
              })()}
            </div>
          </div>
        </div>
      </div>

      {actionModal && subAccountAccountDetails?.account_number && (
        <SubAccountsModal
          modalType={actionModal}
          setModalType={setActionModal}
          accountNum={subAccountAccountDetails?.account_number}
        />
      )}
    </>
  );
};
export default SubAccountDetails;
