import React, { useState } from "react";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useMerchantProfile } from "../../../hooks/useMerchantProfile";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { toast } from "react-toastify";
import { errorHandler } from "../../../helpers/errorHandler";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { postData } from "../../../apis/apiMethods";
import { Loader } from "../../../component/Loader/Loader.component";
import CONFIG from "../../../helpers/config";

interface IUpdatePersonalProfile {
  firstName: string;
  lastName: string;
  phoneNumber: number;
  gender: string;
}

const PersonalForm = () => {
  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);
  const { data: merchantProfileData, isLoading: ProfileDetailsLoading } =
    useMerchantProfile({ merchantId });

  const phoneRegExp =
    /^(\+234|234|0)(701|702|703|704|705|706|707|708|709|802|803|804|805|806|807|808|809|810|811|812|813|814|815|816|817|818|819|909|908|901|902|903|904|905|906|907|911)([0-9]{7})$/;

  const schema = yup.object().shape({
    firstName: yup.string().matches(/^[a-zA-Z0-9]*$/, "Spaces are not allowed"),
    lastName: yup.string().matches(/^[a-zA-Z0-9]*$/, "Spaces are not allowed"),
    phoneNumber: yup
      .string()
      .matches(phoneRegExp, "Enter a valid Nigerian Phone Number"),
    gender: yup.string(),
  });

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdatePersonalProfile>({
    resolver: yupResolver(schema),
  });

  const submitForm = async (data: IUpdatePersonalProfile) => {
    setLoading(true);

    const reqBody = {
      gender: data?.gender,
      first_name: data?.firstName,
      last_name: data?.lastName,
      phone_number: data?.phoneNumber,
    };

    try {
      const res = await postData(
        `${CONFIG.BASE_URL2}/merchant/profile`,
        reqBody
      );
      toast.success(res.message);
    } catch (error) {
      toast.error(errorHandler(error));
    }

    setLoading(false);
  };

  if (ProfileDetailsLoading) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="xl:w-[65%]">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="mt-[3rem] flex flex-col gap-[16px]">
            <div className="lg:flex justify-between ">
              <div className="lg:w-[48%] lg:mb-0 xxs:mb-[30px] ">
                <CustomInputField
                  {...register("firstName")}
                  type="text"
                  label="First Name"
                  placeholder="First Name"
                  defaultValue={merchantProfileData?.first_name}
                />
                <p className="text-[12px] mt-[-1rem] font-thin text-[#6A7581]">
                  {errors.firstName?.message}
                </p>
              </div>
              <div className="lg:w-[48%] lg:mb-0 xxs:mb-[30px]">
                <CustomInputField
                  {...register("lastName")}
                  type="text"
                  label="last Name"
                  placeholder="Last Name"
                  defaultValue={merchantProfileData?.last_name}
                />

                <p className="text-[12px] mt-[-1rem] font-thin text-[#6A7581]">
                  {errors.lastName?.message}
                </p>
              </div>
            </div>
            <CustomInputField
              {...register("phoneNumber")}
              type="number"
              label="Phone Number"
              placeholder="Enter Number"
              defaultValue={merchantProfileData?.phone_number}
            />
            <p className="text-[12px] mt-[-1rem] font-thin text-[#6A7581]">
              {errors.phoneNumber?.message}
            </p>

            <CustomSelectDropdown
              label="Gender"
              {...register("gender")}
              defaultValue={merchantProfileData?.gender}
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </CustomSelectDropdown>
          </div>
          <div className="mt-[32px]">
            <button className="py-[14px] flex gap-[10px] px-[60px] text-white bg-[#2054D2] rounded-[4px]">
              Update
              {loading && <Loader />}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default PersonalForm;
