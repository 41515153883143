import { currencyFormat, truncateString } from "../../../helpers/formatter";
import {
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";

import moment from "moment";
import { useEffect, useState } from "react";
import NoDataState from "../../../component/NoDataState/NoDataState";
import transactionIcon from "../../../assets/images/svg/transaction-icon.svg";
import filterIcon from "../../../assets/images/svg/filter-icon.svg";
import { DateType } from "../../../interfaces/home";
import Pagination from "../../../component/Pagination/Pagination.component";
import { usePosTransaction } from "../../../hooks/usePosTransaction";
import DateFilterModal from "../../../modal/DateFilterModal";
import PosTransactionDetailsModal from "../Modal/PosTransactionDetailsModal";
import { SecondaryLoader } from "../../../component/Loader/SecondaryLoader";
import { useSearchParams } from "react-router-dom";

export default function Transactions() {
  const [modalType, setModalType] = useState("");
  const [transaction, setTransaction] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams({});

  const [searchState, setSearchState] = useState<DateType>({
    startDate: null,
    endDate: null,
  });

  const {
    data: allPosTransactionsForTransfer,
    isLoading: allPosTransactionsForTransferLoading,
  } = usePosTransaction({
    page: currentPage,
    size: postsPerPage,
    startDate: searchState.startDate,
    endDate: searchState.endDate,
  });

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.startDate || currentParams?.endDate) {
      setSearchState({
        startDate: new Date(currentParams?.startDate),
        endDate: new Date(currentParams?.endDate),
      });
    }
    if (currentParams?.page || currentParams?.size) {
      setCurrentPage(JSON.parse(currentParams?.page));
      setPostsPerPage(JSON.parse(currentParams?.size));
    }
  }, [searchParams, currentPage]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (
      !currentParams?.startDate ||
      !currentParams?.endDate ||
      !currentParams?.page ||
      !currentParams?.size
    ) {
      if (searchState.startDate || searchState.endDate) {
        setSearchParams({
          page: JSON.stringify(currentPage),
          size: JSON.stringify(postsPerPage),
          startDate: moment(searchState.startDate).format("YYYY-MM-DD"),
          endDate: moment(searchState.endDate).format("YYYY-MM-DD"),
        });
      } else {
        setSearchParams({
          page: JSON.stringify(currentPage),
          size: JSON.stringify(postsPerPage),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDate, searchState.endDate, searchParams]);
  return (
    <>
      <div className="bg-aellaDimGray w-full px-10 py-[32px] mb-10">
        <div className="py-6 border border-aellaBorderGray bg-white">
          <div className="flex justify-between flex-wrap items-center pb-[18px] px-6 lg:px-10 ">
            <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350]">
              Transactions History
            </h1>
            <div className=" flex">
              <label
                className="min-w-[114px] h-10 border bg-[#F5F5F5] px-2 lg:px-5 py-2 cursor-pointer rounded xxs:justify-center md:justify-around active:shadow-md transition duration-150 ease-in-out"
                htmlFor="filterModal"
                onClick={() => setShowDateRangeModal(true)}
              >
                <div className="xxs:px-1 md:px-2 py-1 h-[24px] flex items-center">
                  <img
                    className="object-contain w-[18px] h-[18px]"
                    src={filterIcon}
                    alt=""
                  />
                  <span className="ml-[12px] font-thin  text-gray-400  text-sm focus:outline-none focus:ring-0">
                    Filter By
                  </span>
                </div>
              </label>
            </div>
          </div>
          <div className="pt-10 border-t border-[#F1F1F1] px-6 lg:px-10 overflow-x-auto w-full">
            {allPosTransactionsForTransfer?.data?.length === 0 ? (
              <NoDataState
                icon={transactionIcon}
                textHeader="No transactions"
                firstParaText="There is no transaction history."
              />
            ) : (
              <div className="">
                <div className="flex items-center bg-[#F7F9FC] w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b">
                  <p className="w-[20%]">Terminal Id</p>
                  <p className="w-[20%]">Reference</p>
                  <p className="w-[30%]">Customer Name</p>
                  <p className="w-[25%]">Amount</p>
                  <p className="w-[18%] "> Fee</p>
                  <p className="w-[18%]"> Net Amount</p>
                  <p className="w-[20%] text-center"> Status</p>
                  <p className="w-[20%] text-right">Date</p>
                </div>
                {allPosTransactionsForTransferLoading ? (
                  <SecondaryLoader count={10} />
                ) : (
                  allPosTransactionsForTransfer?.data?.map((trxn: any) => {
                    return (
                      <div
                        key={trxn?.id}
                        onClick={() => {
                          setModalType("details");
                          setTransaction(trxn);
                        }}
                        className="flex items-center bg-white w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
                      >
                        <p className="w-[20%]">
                          {" "}
                          {truncateString(trxn?.terminal_id, 7)}
                        </p>
                        <p className="w-[20%]">
                          {" "}
                          {truncateString(trxn?.transaction_reference, 7)}
                        </p>
                        <p className="w-[30%] capitalize">
                          {truncateString(trxn?.card_holder, 8)}
                        </p>
                        <p className="w-[25%]">
                          {trxn?.amount ? currencyFormat(trxn?.amount) : "-"}
                        </p>
                        <p className="w-[18%]">
                          {trxn?.fee ? currencyFormat(trxn?.fee) : "-"}
                        </p>
                        <p className="w-[18%]">
                          {trxn?.amount_funded
                            ? currencyFormat(trxn?.amount_funded)
                            : "-"}
                        </p>

                        <p className={`w-[20%] text-center`}>
                          <span
                            className={`w-full text-center ${attachClassNameToTag(
                              trxn?.status
                            )} `}
                          >
                            {getStatusName(trxn?.status)}
                          </span>
                        </p>

                        <p className="w-[20%] text-right">
                          {trxn?.paid_at
                            ? moment(trxn?.paid_at).format("MMM. DD, YYYY")
                            : "-"}
                        </p>
                      </div>
                    );
                  })
                )}
              </div>
            )}

            {allPosTransactionsForTransfer?.data?.length > 0 && (
              <Pagination
                postsPerPage={postsPerPage}
                totalPosts={allPosTransactionsForTransfer?.totalItems}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                totalPages={allPosTransactionsForTransfer?.totalPages}
              />
            )}
          </div>
        </div>
      </div>

      {showDateRangeModal && (
        <DateFilterModal
          startDate={searchState.startDate}
          setSearchState={setSearchState}
          endDate={searchState.endDate}
          searchState={searchState}
          setCloseModal={setShowDateRangeModal}
          startDateName="startDate"
          endDateName="endDate"
          showModal={showDateRangeModal}
        />
      )}
      {modalType && (
        <PosTransactionDetailsModal
          modalType={modalType}
          setModalType={setModalType}
          transaction={transaction}
        />
      )}
    </>
  );
}
