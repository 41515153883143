import { ReactComponent as SadFace } from "../../../assets/images/svg/sad-face-icon.svg";

const UnableToDeleteAccount = () => {
  return (
    <>
      <div className="p-[45px] text-center">
        <SadFace className="mx-auto" />
        <p className="text-[24px] mb-[16px] mt-[40px]">Oops... not allowed</p>
        <p className="text-[#5B5B5B] font-thin">
          Sorry, you’re not permitted to delete bank accounts. This action can
          only be performed by the Super Admin.
        </p>
      </div>
    </>
  );
};

export default UnableToDeleteAccount;
