import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getMerchantWithdrawalAccountDetails = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_MERCHANT_WITHDRAWAL_ACCOUNTS}`
  );
  return data;
};

export const useMerchantWithdrawalAccountDetails = () => {
  return useQuery(
    "merchantWithdrawalAccountDetails",
    getMerchantWithdrawalAccountDetails,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
