import { useState } from "react";
import { useForm } from "react-hook-form";
import CustomInputField from "../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../helpers/yupValidators";
import * as yup from "yup";
import { ReactComponent as NextIcon } from "../../assets/images/svg/right-arrow-icon.svg";
import { RegistrationFormData } from "../../interfaces/login";
import { Loader } from "../../component/Loader/Loader.component";
import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";

import { ReactComponent as ShowPasswordIcon } from "../../assets/images/svg/eye-open-icon.svg";
import { ReactComponent as HidePasswordIcon } from "../../assets/images/svg/eye-close-icon.svg";

const schema = yup.object().shape({
  password: yupValidators.password,
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
interface IResetStepTwo {
  setStep: (newState: number) => void;
  setState: any;
  state: {
    email: string;
    newPassword: string;
    confirmNewPassword: string;
  };
}

const ResetStepTwo: React.FC<IResetStepTwo> = ({
  setStep,
  setState,
  state,
}) => {
  const [loading, setLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [newPasswordType, setNewPasswordType] = useState("password");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormData>({
    criteriaMode: "all",
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit = async ({
    password,
    confirmPassword,
  }: {
    password: string;
    confirmPassword: string;
  }) => {
    setLoading(true);

    setStep(3);
    setState({
      ...state,
      newPassword: password,
      confirmNewPassword: confirmPassword,
    });

    setLoading(false);
  };

  const togglePasswordField = () => {
    if (passwordType === "password") {
      setPasswordType("text");
    } else {
      setPasswordType("password");
    }
  };
  const toggleNewPasswordField = () => {
    if (newPasswordType === "password") {
      setNewPasswordType("text");
    } else {
      setNewPasswordType("password");
    }
  };

  return (
    <>
      <div className="md:pt-10 lg:pt-[150px] md:pr-10 pl-6 lg:pr-[196px] ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-7">
            <h3 className="text-[26px] md:text-[32px] lg:leading-[38px] font-[300] pb-2">
              Change Password
            </h3>
            <p className="text-aellaGray text-[16px] font-[200] lg:leading-[19px]">
              Fill the form below to change your password
            </p>
          </div>
          <CustomInputField
            type={passwordType}
            maxLength={128}
            placeholder="********"
            label="New Password"
            showRequiredIcon
            errors={errors.password}
            hasActionButton={true}
            actionButtonText={
              passwordType === "password" ? (
                <ShowPasswordIcon />
              ) : (
                <HidePasswordIcon />
              )
            }
            passwordError
            onClickActionButton={togglePasswordField}
            {...register("password")}
          />
          <CustomInputField
            type={newPasswordType}
            maxLength={128}
            placeholder="********"
            label="Confirm New Password"
            showRequiredIcon
            errors={errors.confirmPassword}
            hasActionButton={true}
            autoComplete="new-password"
            actionButtonText={
              newPasswordType === "password" ? (
                <ShowPasswordIcon />
              ) : (
                <HidePasswordIcon />
              )
            }
            onClickActionButton={toggleNewPasswordField}
            {...register("confirmPassword")}
          />

          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center w-full flex justify-center mt-[30px] cursor-pointer"
            disabled={loading}
          >
            Reset Password
            <span className="pl-2">
              <NextIcon />{" "}
            </span>
            {loading && <Loader />}
          </button>
          <p className="flex items-center justify-center my-8 text-[16px] lg:leading-[20px] font-[300]">
            <span className="text-aellaGray pr-2">Remember your password?</span>
            <Link className="text-aellaBlue" to={ROUTES.LOGIN}>
              Sign In
            </Link>
          </p>
        </form>
      </div>
    </>
  );
};

export default ResetStepTwo;
