import { ReactComponent as Refresh } from "../../../assets/images/svg/refresh-icon.svg";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";

const DeleteAccount = ({ navigate }: { navigate: any }) => {
  return (
    <>
      <div className="p-[45px] text-center">
        <p className="text-[24px] mb-[16px] ">Delete Bank Acount</p>
        <p className="text-[#5B5B5B] font-thin mb-[20px] w-[80%] mx-auto">
          For security reasons, a confirmation code has been sent to your
          registered email. Enter the code below to delete this account.
        </p>
        <div className="w-[256px] mx-auto">
          <CustomInputField type="text" label="Enter Code" />
        </div>
        <button className="flex justify-center gap-[10px] mx-auto items-center cursor-pointer">
          <Refresh />
          <p className="text-[14px] text-[#2054D2] font-thin">Resend Code</p>
        </button>
        <button
          onClick={navigate}
          className="px-[50px] py-[16px] mt-[50px] rounded-[4px] font-thin text-white bg-[#EB5757]"
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default DeleteAccount;
