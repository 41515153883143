import { createContext, useState, useEffect } from "react";
import { getFromLocalStorage } from "../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../helpers/localStorageKeys";

export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [isAuthed, setIsAuthed] = useState(false);
  const [merchantCntxtProfile, setMerchantCntxtProfile] = useState("");
  const [merchantCntxtStatus, setMerchantCntxtStatus] = useState("");
  const [merchantCntxtTerms, setMerchantCntxtTerms] = useState("");

  useEffect(() => {
    if (getFromLocalStorage(LOCAL_STORAGE_KEYS.TOKEN)) {
      setIsAuthed(true);
    } else {
      setIsAuthed(false);
    }
  }, [isAuthed]);

  return (
    <AuthContext.Provider
      value={{
        isAuthed,
        setIsAuthed,
        merchantCntxtProfile,
        setMerchantCntxtProfile,
        merchantCntxtStatus,
        setMerchantCntxtStatus,
        merchantCntxtTerms,
        setMerchantCntxtTerms,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
