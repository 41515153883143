import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ReactComponent as DropDownIcon } from "../../assets/images/svg/black-arrow-down-icon.svg";
import { ReactComponent as ConnectIcon } from "../../assets/images/svg/connect-icon.svg";
import { ReactComponent as LogoutIcon } from "../../assets/images/svg/logout.svg";
import {
  deleteFromLocalStorage,
  getFromLocalStorage,
} from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { NavLink, useNavigate } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";
import { getFirstLetter } from "../../helpers/capitalizeFirstLetter";
import { truncateString } from "../../helpers/formatter";

export default function ProfileSubMenu() {
  const businessName = getFromLocalStorage(LOCAL_STORAGE_KEYS.BUSINESSNAME);
  const navigate = useNavigate();
  const handleLogout = () => {
    deleteFromLocalStorage();
    navigate(ROUTES.LOGIN);
  };
  return (
    <>
      <Popover className="flex flex-col z-50 ">
        <Popover.Button className="rounded-l-full rounded-r-[3500px] p-[8px] border-[#ECEFF8] bg-[#ECEFF8] flex justify-between items-center cursor-pointer outline-none focus:outline-none">
          <div className="rounded-full inline-flex bg-aellaBlue w-[30.4px] h-[29.6px] items-center justify-center top-6  cursor-pointer mr-2 text-white">
            {getFirstLetter(businessName)}
          </div>
          <div className="flex flex-col">
            <h1 className="text-[14px] tracking-[0.2px] font-[400] capitalize self-start">
              {truncateString(businessName, 10)}
            </h1>
            <h3 className="text-[12px] tracking-[0.2px] font-[250] text-[#011C34]">
              Merchant ID:{" "}
              {truncateString(
                getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID),
                10
              )}
            </h3>
          </div>
          <DropDownIcon />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute bg-white top-[120%] right-[40px]">
            <div className="overflow-hidden rounded-lg border-[#f1f1f1] border shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="grid gap-2">
                <div className="min-w-[290px] px-4 py-6 border-[#EDEFF5] border-b-[1px] flex items-center gap-4">
                  <div className="rounded-full inline-flex bg-aellaBlue w-[30.4px] h-[29.6px] items-center justify-center top-6  cursor-pointer mr-1 text-white">
                    {getFirstLetter(businessName)}
                  </div>
                  <div className="flex-1">
                    <p className="text-[16px] leading-[16px] font-[400] tracking-[0.03em]">
                      {businessName}
                    </p>
                    <p className="text-[14px] leading-[16px] tracking-[0.03em] text-aellaGray pt-1.5 font-[300]">
                      Super Admin
                    </p>
                  </div>
                </div>
                <div className=" py-[4px] border-[#EDEFF5] border-b-[1px] gap-4">
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? `py-2 px-4 rounded-lg transition duration-150 ease-in-out  bg-aellaBlue text-white flex items-center cursor-pointer`
                        : `py-2 px-4 rounded-lg transition duration-150 ease-in-out text-aellaGray  hover:bg-aellaBlue hover:text-white flex items-center cursor-pointer`
                    }
                    to={ROUTES.CONNECT_SERVICES}
                  >
                    <ConnectIcon />
                    <p className="text-[16px] pl-2.5 leading-[18px] tracking-[0.03em] font-[300]">
                      Connect more services
                    </p>
                  </NavLink>
                </div>
                <div
                  className=" cursor-pointer rounded-lg py-2 px-4 transition duration-150 ease-in-out hover:bg-aellaBlue hover:text-white focus:outline-none"
                  onClick={() => handleLogout()}
                >
                  <div className="w-full flex items-center ">
                    <div className="pr-2.5">
                      <LogoutIcon />
                    </div>
                    <p className="text-[14px] leading-[17px] tracking-[0.2px] font-[200] hover:text-white uppercase">
                      Logout
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}
