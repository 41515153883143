import React from "react";
import Logo from "../../assets/images/svg/aella-logo.svg";
import { Link } from "react-router-dom";
import { ROUTES } from "../../helpers/routes";

const TermsOfUse = () => {
  return (
    <main
      role="main"
      className=" bg-white flex h-full overflow-hidden relative w-full flex-col"
    >
      <div className="bg-[#F6F8FA] flex-col w-full py-6 px-4 lg:px-[96px]">
        <Link to={ROUTES.LOGIN}>
          <img src={Logo} alt="Aella Logo" className="w-[90px] h-[32px]" />
        </Link>
      </div>
      <div className="py-4 lg:py-[85px] px-7 lg:px-[96px] bg-aellaBlue text-white text-center w-full">
        <h1 className="text-[24px] lg:text-[56px] lg:leading-[67px] font-[200]">
          Terms & Conditions of Acceptable Use
        </h1>
        <p className="text-[16px] lg:leading-[24px] pt-4 font-[200]">
          Last updated: 28th July, 2022
        </p>
      </div>
      <div className="px-7 lg:px-[152px] py-[72px] text-justify">
        <p className="text-[16px] lg:leading-[24px] font-[100] pb-3">
          Aella, Aella Financial Solutions, Aella Credit Limited are registered
          business entities and affiliated trademark in United States and
          Nigeria. Aella (“we”, “us” or “our”) has developed an online payment
          gateway that makes it easy for merchants to accept payments online
          from users or customers. Aella is the owner of the digital Platform
          and has agreed to grant you a non-exclusive right to use subject to
          the continued compliance to extant laws and the term herein contained.
        </p>
        <p className="text-[16px] lg:leading-[24px] font-[100] pb-3">
          Aella is for all intent and purposes an independent contractor and not
          a party to the transactions carried out on its Platform and hence, do
          not guarantee the legality, accuracy, originality, efficiency or
          completeness for the products or services paid for on our Platform.We
          do not guarantee any user’s identity and cannot ensure that a buyer or
          seller will complete a transaction.
        </p>
        <p className="text-[16px] lg:leading-[24px] font-[100] pb-3">
          This Terms of Use CONSTITUTES A LEGAL AGREEMENT BETWEEN YOU (THE
          MERCHANT) AND AELLA. It highlights certain risks on using the services
          and you must consider such risks carefully as you will be bound by the
          provision of this Agreement through your use of this website or any of
          our services. This Terms of Use is an agreement between you and Aella.
          <br></br>
          <span className="font-[400]">Aella</span> and
          <span className="font-[400]"> Merchant </span>
          are herein referred to collectively as{" "}
          <span className="font-[400]">“Parties”</span>
          and individually as <span className="font-[400]">“Parties”</span>.
          <br></br>
        </p>
        <p className="text-[16px] lg:leading-[24px] font-[100] pb-3">
          PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE SIGNING UP ASA
          MERCHANT OR USING ANY OF OUR SERVICES. If you do not agree to any or
          all of these Terms of Service, DO NOT USE THIS SITE! By registering,
          integrating and clicking our accept icon you accept our Privacy
          Policy,Acceptable Use Policy and Merchant Terms of Service (where
          applicable) which are incorporated by reference into these Terms of
          Use and make the following guarantees:
          <ul className="list-square list-outside mx-6">
            <li className="text-[16px] lg:leading-[24px] font-[100]">
              You represent and warrant to Aella that you have full power and
              authority to enter into, execute, deliver and perform this
              Agreement;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[100]">
              That you will not use the Platform in connection with any product,
              service, transaction or activity that flouts these terms and
              conditions;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[100]">
              That your business/ enterprise is duly registered and had the
              requisite authorization, permit, license (where applicable) to
              engage in your business and shall always have such valid
              authorization whilst on board this Platform;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[100]">
              That you will not use the Platform in connection with any product,
              service, transaction or activity that violates any law or
              government regulation, or promotes or facilitates such by third
              parties including but not limited to activities that:
            </li>
            <ul className="list-disc list-outside ml-6">
              <li className="text-[16px] lg:leading-[24px] font-[100]">
                are fraudulent, deceptive, unfair or predatory;
              </li>
              <li className="text-[16px] lg:leading-[24px] font-[100]">
                Cause or threaten reputational damage to us or any Card Network;
              </li>
              <li className="text-[16px] lg:leading-[24px] font-[100]">
                are fraudulent, deceptive, unfair or predatory;Involve any of
                the business categories listed above; or
              </li>
              <li className="text-[16px] lg:leading-[24px] font-[100]">
                Result in or create a significant risk of chargebacks,
                penalties, damages or other harm or liability.
              </li>
              <li className="text-[16px] lg:leading-[24px] font-[100]">
                Violate any rule or regulation of card issuers, other
                collaborating service providers, regulatory provisions or any
                other electronic funds transfer network by any means.
              </li>
            </ul>
          </ul>
        </p>
        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Prohibited Businesses:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            Aella prohibits the use of its platform/digital infrastructure in
            connection with any product,service, transaction or activity that
            falls within certain business categories. These includedealings in
            humans (trafficking), body part or buying and selling of organs,
            drugs,ammunitions including:
          </p>

          <ul className="list-square list-outside mx-6">
            <li className="text-[16px] lg:leading-[24px] font-[300] ">
              banned narcotics, steroids, certain controlled substances or other
              products that present a risk a consumer's safety;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              blood, bodily fluids or body parts;- burglary tools;- counterfeit
              items;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              illegal drugs and drug paraphernalia;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              fireworks, destructive devices and explosives;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              identity documents, government documents, personal financial
              records or personal information (in any form, including mailing
              lists);
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              lottery tickets, sweepstakes entries or slot machines without the
              required license;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              offensive material or hate speech or items that promote hate,
              violence, racial intolerance, or the financial exploitation of a
              crime;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              chemicals;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              recalled items;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              prohibited services;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              unlicensed financial services, stocks or other securities;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              stolen property;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              items that infringe or violate any copyright, trademark, right of
              publicity or privacy or any other proprietary right under the laws
              of any jurisdiction;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              sales of currency without BDC license, cryptocurrency operators;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              obscene material or pornography;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              certain sexually oriented materials or services;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              certain firearms, firearm parts or accessories, ammunition,
              weapons or knives;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              any product or service that is illegal or marketed or sold in such
              a way as to create liability to Aella; or
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              production of military and paramilitary wears and accoutrement,
              including those of the Police and the Customs, Immigration and
              Prison Services.
            </li>
          </ul>
          <p className="text-[16px] lg:leading-[24px] font-[100] py-2">
            Transactions/Services that are also prohibited include:
          </p>

          <ul className="list-square list-outside mx-6">
            <li className="text-[16px] lg:leading-[24px] font-[300] ">
              show the personal information of third parties in violation of
              applicable law;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              support pyramid or ponzi schemes, matrix programs, other "get rich
              quick" schemes or certain multi-level marketing programs or any
              other form of public invitation to investment without the
              requisite license;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              are associated with purchases of annuities or lottery contracts,
              lay-away systems, off-shore banking or transactions to finance or
              refinance debts funded by a credit card;
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              pertain to ammunitions and arms; and-
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              involve gambling, gaming and/or any other activity with an entry
              fee and a prize, including, but not limited to casino games,
              sports betting, horse or greyhound racing, lottery tickets, other
              ventures that facilitate gambling, games of skill (whether or not
              it is legally defined as a lottery) and sweepstakes unless the
              operator has obtained prior approval from Aella and the operator
              and customers are located exclusively in jurisdictions where such
              activities are permitted by law.
            </li>
            <li className="ttext-[16px] lg:leading-[24px] font-[300]">
              Payment for ransom, proceeds of crime, war or illegal transaction
              under any law.
            </li>
          </ul>
        </div>
        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Registration:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            To use Aella, you have to create a Aella account by registering. To
            register, you will provide us with certain information such as your
            email, first name, last name, business name and phone number and we
            may seek to verify your information, (by ourselves or through third
            parties), after which we will approve your account unless deemed
            risky. You give us permission to do all these.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Actions and Rectification:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You understand and agree that where we, in our sole discretion
            suspect, believe or perceive a violation of our acceptable use we
            may take such actions as we deem appropriate to mitigate risk to us
            and any impacted third parties and to ensure compliance including
            but not limited to
          </p>

          <ul className="list-square list-outside mx-6">
            <li className="text-[16px] lg:leading-[24px] font-[300] ">
              Blocking the settlement or completion of one or more payments;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              Suspending, restricting or terminating your access to and use of
              the Aella’s Services;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              Terminating our business relationship with you, including
              termination without liability to Aella of any payment service
              agreement between you and Aella;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              Taking legal action against you;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              Contacting and disclosing information related to such violations
              to (i) persons who have sold/purchased goods or services from you,
              (ii) any banks or Card Networks involved with your business or
              transactions, (iii) law enforcement or regulatory agencies, and
              (iv) other third parties that may have been impacted by such
              violations; or
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              Contacting and disclosing information related to such violations
              to (i) persons who have sold/purchased goods or services from you,
              (ii) any banks or Card Networks involved with your business or
              transactions, (iii) law enforcement or regulatory agencies, and
              (iv) other third parties that may have been impacted by such
              violations; or
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              Assessing against you any fees, penalties, assessments or expenses
              (including reasonable attorneys’ fees) that we may incur as a
              result of such violations, which you agree to pay promptly upon
              notice
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              Report to appropriate law enforcement authorities
            </li>
          </ul>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            MERCHANT’S OBLIGATIONS
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            A merchant using this platform shall make available its technical
            personnel in a timely fashion to work with Aella to implement all
            necessary integration procedures, provide all necessary assistance
            as may be reasonably required by Aella in relation to the provision
            of the Services; provide Aella with all its regulatory permits and
            licences prior to the integration of its platform; utilize the
            integration solely for the Purpose of this Agreement; inform Aella
            of any maintenance activity on its platform, breach or perceived
            threat that it becomes aware of that may have an adverse effect on
            the Aella; maintain Industry Standard in collecting, storing and
            transmitting the Transaction Data in a secure manner and protecting
            the privacy of such data; inform Aella once it becomes aware of any
            breach of information security that may have an adverse effect on
            Aella, Upon completion of an online transaction with theCardholder,
            including the completion of all necessary and requisite procedure,
            the Merchant undertakes to close the transaction period and not
            retain sensitive Cardholder’s information.Subject to applicable
            laws, retain for not less than one hundred and twenty (120) days or
            such other period as Aella may stipulate from time to time details
            of all transactions and documents, including, but not limited to,
            receipts, personal identification, etc, related to the transactions
            carried out by each Cardholder. The Merchant agrees to furnish Aella
            with the above information within three (3) days from the date of
            request. Where the Merchant fails to furnish the information, the
            Merchant undertakes to bear all liability, damages, costs, losses,
            or claims relating thereto and shall be bound to pay the Acquirer
            the amount or value of any such transaction plus all costs including
            attorney or solicitors’ fees. The merchant shall not to sell,
            divulge (whether will fully or negligently), release, misuse,
            negligently handle, provide or exchange any information relating to
            Cards to third parties without the prior written consent of the
            Acquirer; Subject to applicable laws where specifically required by
            PaymentSchemes and regulations, authorise the Acquirer in writing to
            debit the Merchant’s nominated bank account with the Acquirer for
            the full value plus other lawful charges in respect of all lawful
            transactions where the Cardholder is adjudged by the Payment Scheme
            (Visa andMasterCard) to be entitled to a refund; Refrain from acting
            as a payment service provider and not provide any form of
            aggregation services whilst this Agreement subsists; Where required,
            the Merchant shall provide Aella with security in such form as to be
            agreed by the Parties in order to secure the performance of the
            Merchant's obligations under this Agreement. Inappropriate cases,
            Aella may call for an increase to the level of security held subject
            to the consent of the Merchant, such consent not to be unreasonably
            withheld. Undertake that it has never had an agreement with a
            Payment Scheme which was terminated upon request and/or demand by
            the Payment Scheme or any regulatory authority; not to submit any
            transaction that the Merchant knows is, or should have known was,
            illegal or fraudulent; comply with Aella’s reasonable requests for
            action on the Merchant’s part, to the extent necessary to maintain
            security and integrity of the Services; update to the most current
            security updates and patches necessary to allow the proper operation
            of the Services and notify Aella of any update or revision to its
            enrollment and payment information; give Aella the right to contact
            the Merchant’s customers, where necessary, to investigate certain
            types of transactions, especially those that are inconsistent or
            irregular or otherwise deviate from what is standard or expected;
            comply with all applicable Anti Money Laundering/Combating the
            Financing of Terrorism laws and regulations
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            In order to ensure continued compliance with the requirements of the
            regulatory bodies, theMerchant understands and accepts that Aella
            provides its Services subject to the written approvals, directives,
            notices or authorisations as may be issued by the regulatory
            authorities from time to time and the Payment Scheme Rules. The
            Merchant further accepts that Aella may make any such changes to the
            Services or this Agreement as are strictly necessary to ensure
            compliance with such directive and the Payment Scheme Rules and the
            continuous provision of the Services to the Merchant.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            The Merchant acknowledges that the Cards created on the Aella
            Platform are the property of Aella, and will be subject to
            cancellation at any time by Aella or the Issuing Bank, as required
            by Applicable Law, or, on a case-by-case basis, where Aella and/or
            the Issuing Bank believes that the Card is being used for fraudulent
            or illegal purposes provided that Aella shall immediately notify the
            Merchant of such cancellation.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            The Merchant agrees that it will be responsible for and liable to
            Aella and Issuing Bank forall reasonable expenses associated with
            and any losses from over limit processing orreasonable expenses
            incurred by Aella or Issuing Bank in seeking fraud or
            unauthorizedtransaction recovery under Applicable Law that was a
            result of the Merchant's action orinaction including actions of the
            Merchant's employees.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Updates, Modifications & Amendments:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            We may need to update, modify or amend our Acceptable Use Policy at
            any time. We reserve the right to make changes to this Acceptable
            Use Policy. We shall endeavor to notify of any changes.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Term:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            This Agreement shall commence from the date of your
            accepting/clicking the consent iconand your continued use of the
            Platform. Aella may require from tine to time that you re-accept a
            modification or update to its time and may restrain you from further
            use until theacceptance is done.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Privacy Policy:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            We may need to update, modify or amend our Acceptable Use Policy at
            any time. We reserve the right to make changes to this Acceptable
            Use Policy. We shall endeavor to notify of any changes.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Age Restriction:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Persons who use our platform guarantee that they are not below the
            age of 18. Merchants shall ensure that the users of their services
            are not lesser than 18 years and you shall be liable for any use by
            such person lesser than 18 years.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Disputes & Reversal:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            It shall be your responsibility to notify us immediately in writing,
            providing your transactionID and/or transaction details in case of
            any unauthorized, failed, fraudulent, incomplete, duplicated or
            multiple transactions which occur on our Platform.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            All claims against us related to payments should be made within 45
            (forty-five) days after the date of such payment. It will be taken
            that you waive all claims against us, to the fullest extent of the
            law after the said period of time.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            Note that we are an independent service provider with no liability,
            obligation in respect of transactions carried out on our platforms.
            Hence, for dispute over the goods or services you purchased, we have
            no liability for such goods or services.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            We may intervene in disputes between users and merchants concerning
            payments only at our sole discretion.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Disclaimers:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            WE TRY TO KEEP THE PLATFORM AVAILABLE AT ALL TIMES, BUG-FREE AND
            SAFE, HOWEVER, WE MAKE NO GUARANTEES. YOU USE IT AT YOUR OWN RISK.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            OUR WEBSITE AND SERVICES ARE PROVIDED “AS IS” WITHOUT ANY
            EXPRESS,IMPLIED AND/OR STATUTORY WARRANTIES (INCLUDING, BUT NOT
            LIMITED TO, ANY IMPLIED OR STATUTORY WARRANTIES OF MERCHANT ABILITY,
            FITNESS FOR A PARTICULAR USE OR PURPOSE, TITLE, AND NON-INFRINGEMENT
            OF INTELLECTUAL PROPERTY RIGHTS). WITHOUT LIMITING THE GENERALITY OF
            THE FOREGOING, AELLA MAKES NO WARRANTY THAT OUR WEBSITE AND SERVICES
            WILL MEET YOUR REQUIREMENTS OR THAT OUR WEBSITE WILL BE
            UNINTERRUPTED, TIMELY, SECURE, OR ERROR FREE.NO ADVICE OR
            INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BYYOU THROUGH OUR
            WEBSITE OR FROM AELLA, ITS PARENTS, SUBSIDIARIES,OR OTHER AFFILIATED
            COMPANIES, OR ITS OR THEIR SUPPLIERS (OR THE RESPECTIVE OFFICERS,
            DIRECTORS, EMPLOYEES, OR AGENTS OF ANY SUCHEN TITIES) (COLLECTIVELY,
            "AELLA PARTIES") SHALL CREATE ANY WARRANTY.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Limitation of Liability:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            IN NO EVENT WILL ANY OF THE AELLA PARTIES BE LIABLE FOR (A) ANY
            INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES OR
            (B) ANY DAMAGES WHATSOEVER IN FOR ANY TRANSACRION WITHOUT LIMITATION
            TO THOSE RESULTING FROM LOSS OF REVENUES, LOST PROFITS,LOSS OF
            GOODWILL, LOSS OF USE, BUSINESS INTERRUPTION, OR OTHER INTANGIBLE
            LOSSES, ARISING OUT OF OR IN CONNECTION WITH AELLA’S WEBSITE OR
            SERVICES (INCLUDING, WITHOUT LIMITATION, USE, INABILITY TO USE, OR
            THE RESULTS OF USE OF AELLA’S WEBSITES OR SERVICES),WHETHER SUCH
            DAMAGES ARE BASED ON WARRANTY, CONTRACT, TORT,STATUTE, OR ANY OTHER
            LEGAL THEORY.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Data Security:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Each Party is responsible for ensuring data security on their
            platform/website and for all dataand Confidential information
            acquired pursuant to this Agreement. Each Party shall be andremain
            compliant with the Payment Card Industry Data Security Standard
            (PCI/DSS)requirement to the extent applicable to that Party and
            prior to such Party accessing anypayment cardholder data or credit
            card information, as such requirements may be amendedfrom time to
            time.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            No Assignment:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            The rights benefits or obligations under this Agreement may not be
            assigned or otherwise transferred in whole or in part without the
            prior written consent of all the Parties but shall be binding upon
            and inure to the benefit of each of the Parties and, where so
            permitted, their assigns or other transferees. Aella may
            sub-contract or delegate the performance of its obligations under
            this Agreement to third parties including any of its Affiliates;
            however, theMerchant shall remain responsible for the performance of
            its duties under this Agreement
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Waiver:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            The respective rights of the Parties (whether arising under this
            Agreement or under theapplicable law) shall not be capable of being
            waived or varied otherwise than by an expresswaiver or variation in
            writing; and in particular any failure to exercise or any delay
            inexercising any of such rights shall not operate as a waiver or
            variation of that or any othersuch right; any defective or partial
            exercise of any of such right shall not preclude any otheror further
            exercise of that or any other such right; and no act or course of
            conduct ornegotiation on the part of either Party shall preclude
            them from exercising any such right orconstitute a suspension or
            variation of such right.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Applicable Law:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            These Terms of Use shall be interpreted and governed by the laws
            currently in force in theFederal Republic of Nigeria.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Legal Disputes:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Aella shall not be involved in any disputes that may arise between
            the Merchant and any of its Customers, unless such dispute
            specifically relates to transaction settlement. We shall make an
            effort to settle all disputes amicably. Any dispute arising out of
            this Terms of Use, which cannot be settled, by mutual
            agreement/negotiation within 1 (one) month shall be referred to
            arbitration by a single arbitrator at the Lagos Multi-Door
            Courthouse (“LMDC”)and governed by the Arbitration and Conciliation
            Act, Cap A10, Laws of the Federal Republic of Nigeria. The
            arbitrator shall be appointed by both of us (we and you), where both
            of us are unable to agree on the choice of an arbitrator, the choice
            of arbitration shall be referred to the LMDC. The findings of the
            arbitrator and subsequent award shall be binding on both of us. Each
            of us shall bear our respective costs in connection with the
            Arbitration.Venue for the arbitration shall be Lagos, Nigeria.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Severability:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            If any portion of these Terms of Use is held by any court or
            tribunal to be invalid orunenforceable, either in whole or in part,
            then that part shall be severed from these Terms ofUse and shall not
            affect the validity or enforceability of any other part in this
            Terms of Use.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Change of Information:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            In the event that you change any information provided to us at
            registration including your business name, address, financial
            institution, mode of payments or the products and services that you
            offer, or where a corporate restructuring occurs you agree to notify
            us within 14 days of such change. We may be unable to respond to you
            if you contact us from an address, telephone number or email account
            that is not registered with us.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Account Security:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            You agree not to allow anyone else to have or use your password
            details and to comply withall reasonable instructions we may issue
            regarding account access and security. In the eventyou share your
            password details, Aella will not be liable to you for losses or
            damages. Youwill also take all reasonable steps to protect the
            security of the personal electronic devicethrough which you access
            Aella’s services (including, without limitation, using PIN
            and/orpassword protected personally configured device functionality
            to access Aella’s services andnot sharing your device with other
            people)..
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Data Compliance:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You agree to comply with all data privacy and security requirements
            of the Payment CardIndustry Data Security Standard (PCI DSS
            Requirements”) and under any applicable law or regulation that may
            be in force, enacted or adopted regarding confidentiality, your
            access, use, storage and disclosure of user information. Information
            on the PCI DSS can be found on the PCI Council’s website. It is your
            responsibility to comply with these standards.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            We are responsible for the security and protection of Card Holder
            Data (CHD) we collect and store. Accordingly, we implement access
            control measures, security protocols and standards including the use
            of encryption and firewall technologies to ensure that CHD is kept
            safe and secure on our servers, in compliance with the PCI DSS
            Requirement. We also implement periodical security updates to ensure
            that our security infrastructures are in compliance with reasonable
            industry standards
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            We acknowledge that you own all your customers’ data. You hereby
            grant Aella a perpetual, irrevocable, sub-licensable, assignable,
            worldwide, royalty-free license to use, reproduce, electronically
            distribute, and display your customers’ data for the following
            purposes:
          </p>

          <ul className="list-decimal list-outside mx-3.5">
            <li className="text-[16px] lg:leading-[24px] font-[300] ">
              providing and improving our services;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              internal usage, including but not limited to, data analytics and
              metrics so long as individual customer data has been anonymized
              and aggregated with other customer data;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              complying with applicable legal requirements and assisting law
              enforcement agencies by responding to requests for the disclosure
              of information in accordancewith local laws; and
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              any other purpose for which consent has been provided by your
              customer.
            </li>
          </ul>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Software License:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            We hereby grant you a revocable, non-exclusive, non-transferable
            license to use Aella’s APIs, developer’s toolkit, and other software
            applications (the “Software”) in accordance with the documentation
            accompanying the Software. This license grant includes all updates,
            upgrades, new versions and replacement software for your use in
            connection with the Aella’s services. If you do not comply with the
            documentation and any other requirements provided by Aella, then you
            will be liable for all resulting damages suffered by you, Aella and
            third parties. Unless otherwise provided by applicable law, you
            agree not to alter, reproduce, adapt, distribute, display, publish,
            reverse engineer, translate, disassemble, decompile or otherwise
            attempt to create any source code that is derived from the Software.
            Upon expiration or termination of this Agreement, you will
            immediately cease all use of any Software.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Trademark License:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Whist this Agreement subsists, we grant you a revocable,
            non-exclusive, non-transferable license to use Aella’s trademarks
            used to identify our services (the “Trademarks”) solely in
            conjunction with the use of our services. You agree that you will
            not at any time during or after this Agreement assert or claim any
            interest in or do anything that may adversely affect the validity of
            any Trademark or any other trademark, trade name or product
            designation belonging to or licensed to Aella (including, without
            limitation registering or attempting to register any Trademark or
            any such other trademark, trade name or product designation).Upon
            expiration or termination of this Agreement, you will immediately
            cease all display, advertising and use of all of the Trademarks.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Intellectual Property:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            We do not grant any right or license to any Aella intellectual
            property rights by implication, estoppel or otherwise other than
            those expressly mentioned in this Agreement. Each party shall retain
            all intellectual property rights including all ownership rights,
            title, and interest in and to its own products and services, subject
            only to the rights and licenses specifically granted herein.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Publicity:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            You hereby grant Aella permissions to use your name and logo in our
            marketing materialsincluding, but not limited to use on our website,
            in customer listings, in interviews and inpress releases. Such
            Publicity does not imply an endorsement for your products and
            services.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Confidential Information:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            The parties acknowledge that in the performance of their duties
            under this Agreement, eitherparty may communicate to the other (or
            its designees) certain confidential and proprietary information,
            including without limitation information concerning each party’s
            services, knowhow, technology, techniques, or business or marketing
            plans (collectively, the “ConfidentialInformation”) all of which are
            confidential and proprietary to, and trade secrets of, the
            disclosing party. Confidential Information does not include
            information that: (i) is public knowledge at the time of disclosure
            by the disclosing party; (ii) becomes public knowledge or known to
            the receiving party after disclosure by the disclosing party other
            than by breach of the receiving party’s obligations under this
            section or by breach of a third party’s confidentiality obligations;
            (iii) was known by the receiving party prior to disclosure by the
            disclosing party other than by breach of a third party’s
            confidentiality obligations; or (iv) is independently developed by
            the receiving party.As a condition to the receipt of the
            Confidential Information from the disclosing party, the receiving
            party shall: (i) not disclose in any manner, directly or indirectly,
            to any third party any portion of the disclosing party’s
            Confidential Information; (ii) not use the disclosing party’s
            Confidential Information in any fashion except to perform its duties
            under thisAgreement or with the disclosing party’s express prior
            written consent; (iii) disclose the disclosing party’s Confidential
            Information, in whole or in part, only to employees and agents who
            need to have access thereto for the receiving party’s internal
            business purposes; (iv) take all necessary steps to ensure that its
            employees and agents are informed of and comply with the
            confidentiality restrictions contained in this Agreement; and (v)
            take all necessary precautions to protect the confidentiality of the
            Confidential Information received here under and exercise at least
            the same degree of care in safeguarding the Confidential Information
            as it would with its own confidential information, and in no event
            shall apply less than a reasonable standard of care to prevent
            disclosure.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Know Your Customer:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You agree that, you are solely responsible for verifying the
            identities of your customers, ensuring that they are authorised to
            carry out the transactions on your platform, and determining their
            eligibility to purchase your products and services.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            You are also required to maintain information and proof of service
            or product delivery to your customer. Where a dispute occurs needing
            resolution, you may be required to provide Aella with these.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Fraudulent Transactions:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            Fraudulent transactions shall include but not be limited to:
          </p>
          <ul className="list-square list-outside mx-6">
            <li className="text-[16px] lg:leading-[24px] font-[300] ">
              Any purchase of goods and services and/or transaction arising from
              the use of a card by a person other than the authorized
              cardholder.
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              Use of a card that is not authorised in terms of the rules
              governing the issuance and use of cards.
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              In the event that fraudulent transactions account for more than
              0.10% of the Merchant’s sales turnover in any one (1) month, Aella
              may, in addition to any other remedy available to it, be entitled
              to unilaterally terminate this Agreement forthwith and/or request
              theMerchant to delist the affected customer from the use of the
              platform and terminate theMerchant Agreement with the affected
              Customer.
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              Merchant agrees that it will always be responsible for the actions
              of the Merchant’s customers; and The Merchant’s employees
              including fraudulent acts or omission not traceable to the
              contributory negligence of Aella
            </li>
          </ul>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Security/Deposit:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Where applicable, Aella reserves the right to require that the
            Merchant provides or procures the provision of security in such form
            as to be agreed by the Parties to secure the performance of the
            Agreement or otherwise in connection with the Services. Such
            security may take the form of a deposit, a rolling reserve, a
            guarantee or indemnity. Aella reserves the right to unilaterally
            call for an increase to the level of security held. Aella may
            require that any security provided be supplemented or replaced at
            any time. At the time of termination, Aella may retain such amount
            from the security (if any) and settlement payable to the Merchant as
            may be determined by Aella to cover Chargeback risk, Refund risk or
            any potential loss, damages, penalties, cost that may be incurred by
            Aella for a period of one hundred and eighty days (180) Business
            Days. In the event that such retained amount is not sufficient to
            cover all outstanding amounts of the Merchant post termination,
            theMerchant shall ensure that it pays Aella all pending amounts
            within ten (10) Business Days of receiving the demand notice and
            shall at all times keep Aella indemnified in this respect.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Force Majeure:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            If any of the Parties hereto is prevented from fulfilling its
            obligations under this Agreement by reason of any supervening event
            beyond its control (including but not limited to an Act ofGod,
            Natural Disaster, or Civil Disorder) the Party unable to fulfil its
            obligations shall immediately give notice in writing of this to the
            other Party and shall do everything in its power, including but not
            limited to accepting assistance from third parties or the other
            Party, to resume full performance. If the period of incapacity
            exceeds two (2) months, then this Agreement shall automatically
            terminate unless Parties expressly agree otherwise in writing.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Notices:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Any notice required or permitted by this Agreement to be given to
            either Party by the othershall be given by personal delivery to such
            Party or by registered or certified mail, electronicmail, postage
            prepaid, return receipt requested, and addressed to:
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Anti-Corruption:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            Each Party hereby undertakes that, at the date of this Agreement,
            itself, its directors, officers, employees or Affiliates have not
            offered, promised, given, authorized, solicited or accepted any
            undue pecuniary or other advantage of any kind (or implied that they
            will or might do any such thing at any time in the future) in any
            way connected with this Agreement and that ithas taken reasonable
            measures to prevent subcontractors, agents or any other third
            parties, subject to its control or determining influence, from doing
            so.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Each Party shall comply with all applicable anti-bribery and
            anti-corruption Laws in Nigeria(including the Economic and Financial
            Crimes commission laws and the Foreign CorruptPractice Act of the
            United States of America and the Bribery Act 2010 of the
            UnitedKingdom) and all applicable anti-bribery and anti-corruption
            regulations and codes of practice.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Card Network Rules:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Each card network has its own rules, regulations and guidelines. You
            are required to complywith all applicable Network Rules that are
            applicable to merchants. You can review portionsof the Network Rules
            at Mastercard, Visa, Verve and other payment cards. The CardNetworks
            reserve the right to amend the Network Rules.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Customer Payments:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You may only process payments when authorised to do so by your
            customer. We will onlyprocess transactions that have been authorised
            by the applicable Card Network or card issuer.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            We do not guarantee or assume any liability for transactions
            authorised and completed thatare later reversed or charged back (see
            Chargebacks below). You are solely responsible forall reversed or
            charged back transactions, regardless of the reason for, or timing
            of, thereversal or chargeback. Aella may add or remove one or more
            payment types or networks atany time. If we do so we will use
            reasonable efforts to give you prior notice of the removal.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Our Fees & Pricing Schedule:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You agree to pay us for the services we render as a payment gateway
            for your goods and services. Our Fees will be calculated as
            demonstrated on the Pricing page on the website and can be
            calculated on the same page using the “little calculator” we
            provided. The Fees on ourPricing page is integral to and forms part
            of this Agreement.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            We reserve the right to revise our Fees. In the event that we revise
            our fees we will notify you within 5 days of such change.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Payouts:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            Subject to the terms of this Agreement, Aella will send to your
            designated bank or card settlement account (“Bank Account”) all
            amounts settled and due to you from your transactions, minus our
            fees as stated in the Fee Schedule, any Reversals,
            InvalidatedPayments, Chargebacks, Refunds or other amounts that you
            owe to Aella under thisAgreement (“Payout”). If the Payout is not
            sufficient to cover the amounts due, you agree that we may debit
            your Bank Account for the applicable amounts, and/or set-off the
            applicable amounts against future Payouts. Upon our request, you
            agree to provide us with all necessary bank account and related
            information and grant us permission to debit amounts due from your
            Bank Account.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            After transfer of funds is initiated to your Bank Account, we will
            update information on your Aella Dashboard to reflect settlement.
            Information regarding your transactions that are processed and
            settled using Aella (“Transaction History”) will be available to you
            when you login to your Aella Dashboard. While we will provide
            Transaction History in your Aella Dashboard, you are solely
            responsible for compiling and retaining permanent records of all
            transactions and other data associated with your Aella account as
            may be required for yourbusiness. Aella is not responsible for
            maintaining Transaction History or other records in a manner
            consistent with your record retention obligations.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Payout Schedule:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Your Payout Schedule, which is the time it takes us to initiate a
            transfer to your BankAccount settled funds from card transactions
            processed through us is on your Aella Dashboard. We reserve the
            right to change your Payout Schedule, suspend payouts to yourBank
            Account or initiate a Reversal should we deem it necessary due to
            pending disputes, excessive or anticipated excessive Chargebacks or
            Refunds, or other suspicious activity associated with your use of
            Aella, or if required by law or court order.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            How we handle your Funds:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You authorise and instruct Aella to hold, receive, and disburse
            funds on your behalf when such funds from your card transactions
            settle from the Card Networks. By accepting thisAgreement, you
            further authorise Aella on how your card transaction settlement
            funds should be disbursed to you as Payouts and the timing of such
            Payouts.
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            You agree that you are not entitled to any interest or other
            compensation associated with the settlement funds held by Aella
            pending settlement and Payout to your Bank Account.Settlement funds
            will be held in a deposit account at Aella pending Payouts to you in
            accordance with the terms of this contract. We may periodically make
            available to you information about pending settlements yet to be
            received from the Card Networks.Your authorisations will remain
            valid and be of full effect until your Aella Account is closed or
            terminated.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Aella’s Obligations:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            Aella agrees to work with Acquiring Bank to ensure that settlements
            of all monies collected bythe Merchant is handled in a timely manner
            in accordance with Applicable Law and PaymentScheme Rules. We shall
            also work with the Merchant to provide
          </p>
          <ul className="list-square list-outside mx-6">
            <li className="text-[16px] lg:leading-[24px] font-[300] ">
              the requisite integration technical specification and APIs in
              respect of the PaymentGateway subject to the Merchant’s acceptance
              of Aella Terms and Conditions;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              fraud protection and compliance support to the Merchant subject to
              the Merchant’s acceptance of the Aella Terms and Conditions;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              card & token processing API's for all Aella Payment Scheme and
              tokens in the market of scope;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              an open communication channel with the Merchant to discuss future
              joint product designs for future initiatives that both Parties can
              benefit from, and to discuss joint press announcements;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              settlement of all monies collected by Merchants to Merchant’s bank
              account in Merchant’s preferred bank;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              to the Merchant by email on the start date, upon successful
              completion of the pre-go liveUAT, to carry out a controlled end to
              end test in the live environment with selected internal users only
              within Merchant’s organization for a period of one or two weeks in
              order to certify that the entire project implementation is
              successful and satisfactory with sign off by Merchant;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              adequate technical support for the test duration where applicable
              during the implementation of the task.
            </li>
          </ul>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Security and Fraud Controls:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            Aella is responsible for protecting the security of Payment Data
            including CHD in our possession and will maintain commercially
            reasonable administrative, technical, and physical procedures to
            protect all the personal information regarding you and your
            customers that is stored in our servers from unauthorised access and
            accidental loss or modification. Although, we cannot guarantee that
            unauthorised third parties will never be able to defeat those
            measures or use such personal information for improper purposes. We
            will however take all reasonable and commercially achievable
            measures to address any security breach as soon as we become aware
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            You agree to use other procedures and controls provided by us and
            other measures that are appropriate for your business to reduce the
            risk of fraud.In the event that you suspect any fraudulent activity
            by a customer, you agree to notify Aella immediately and quit the
            delivery of the service. In addition, where we suspect that there
            have been frequent fraudulent transactions on your account, we
            reserve the right to cancel our service to you and/or your account
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Notification of Errors:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You agree to notify us immediately any error is detected while
            reconciling transactions that have occurred using Aella. We will
            investigate and rectify the errors where verified. In the event that
            we notice any errors, we will also investigate and rectify such
            errors
          </p>
          <p className="text-[16px] lg:leading-[24px] font-[100]">
            Where we owe you money as a result of such errors, we will refund
            the amounts owed to you by a bank transfer to the Bank Account from
            which the transaction originated.If a transaction is erroneously
            processed through your platform, report to us immediately. We will
            investigate any such reports and attempt to rectify the errors by
            crediting or debiting yourBank Account as appropriate.Failure to
            notify us within 45 (forty-five) days of the occurrence of an error
            will be deemed a waiver of your rights to amounts that are owed to
            you due to an error.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Chargebacks:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            A Chargeback usually happens when a customer files directly with or
            disputes through his orher credit or debit card issuer a payment on
            their bill. It may result in the reversal of atransaction. You may
            be assessed Chargebacks for (i) customer disputes; (ii) unauthorised
            orimproperly authorised transactions; (iii) transactions that do not
            comply with Card NetworkRules or the terms of this Agreement or are
            allegedly unlawful or suspicious; or (iv) anyreversals for any
            reason by the Card Network, our processor, or the acquiring or
            issuingbanks. Where a Chargeback occurs, you are immediately liable
            for all claims, expenses, finesand liability we incur arising out of
            that Chargeback and agree that we may recover theseamounts by
            debiting your Bank Account. Where these amounts are not recoverable
            throughyour Bank Account, you agree to pay all such amounts through
            any other means
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Reserves:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            In our sole discretion, we may place a Reserve on a portion of your
            Payouts by holding for a certain period such portion where we
            believe there is a high level of risk associated with your business.
            If we take such steps, we will provide you with the terms of the
            Reserve which may include the percentage of your Payouts to be held
            back, period of time and any other such restrictions that Aella may
            deem necessary. Where such terms are changed, we will notify you.
            You agree that you will remain liable for all obligations related to
            your transactions even after the release of any Reserve. In
            addition, we may require you to keep your Bank Account available for
            any open settlements, Chargebacks and other adjustments.To secure
            your performance of this Agreement, you grant Aella a legal claim to
            the fund sheld in the Reserve as a lien or security interest for
            amounts payable by you
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Refunds:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You agree that you are solely responsible for accepting and
            processing returns of your products and services. We are under no
            obligation to process returns of your products and services, or to
            respond to your customers’ inquiries about returns of your products
            and services. You agree to submit all Refunds for returns of your
            products and services that were paid for through Aella to your
            customers in accordance with this Agreement and relevantCard Network
            Rules.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Termination:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You may terminate this Agreement by closing your Aella Account.We
            may suspend your Aella Account and your access to Aella services and
            any funds, orterminate this Agreement, if;
          </p>
          <ul className="list-decimal list-outside mx-3.5">
            <li className="text-[16px] lg:leading-[24px] font-[300] ">
              you do not comply with any of the provisions of this Agreement;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              we are required to do so by a Law;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              we are directed by a Card Network or issuing financial
              institution;
            </li>
            <li className="text-[16px] lg:leading-[24px] font-[300]">
              or where a suspicious or fraudulent transaction occurs
            </li>
          </ul>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Indemnity:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You agree to defend, indemnify, and hold Aella, its officers,
            directors, employees, agents,licensors, and suppliers, harmless from
            and against any claims, actions or demands, liabilitiesand
            settlements including without limitation, reasonable legal and
            accounting fees, resultingfrom, or alleged to result from, your
            violation of these Agreement.
          </p>
        </div>

        <div className="pb-4">
          <h1 className="text-[18px] lg:leading-[24px] font-[400] pb-4">
            Miscellaneous:
          </h1>
          <p className="text-[16px] lg:leading-[24px] font-[100] pb-2">
            You agree that all agreements, notices, disclosures and other
            communications that we provide to you electronically satisfy any
            legal requirement that such communications be in writing. Assigning
            or sub-contracting any of your rights or obligations under these
            Terms of Use to any third party is prohibited. We reserve the right
            to transfer, assign or sub-contract the benefit of the whole or part
            of any rights or obligations under these Terms of Use to any third
            party
          </p>
        </div>

        <p className="text-[16px] lg:leading-[24px] font-[400] pb-4">
          Where you negotiate a Merchant Services Agreement before or after
          accepting theterms of this E-Agreement, the executed Agreement will
          take precedence over this E-Agreement.
        </p>
      </div>
    </main>
  );
};
export default TermsOfUse;
