import { usePosRequests } from "../../../hooks/usePosRequests";
import { usePosAnalytics } from "../../../hooks/usePosAnalytics";
import { ReactComponent as CalendarIcon } from "../../../assets/images/svg/calendar-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/black-arrow-down-icon.svg";
import moment from "moment";
import {
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";
import PosRequestModal from "../Modal/PosRequestModal";
import { useEffect, useState } from "react";
import DateRangeModal from "../../../modal/DateRangeModal";
import { SearchStateType } from "../../../interfaces/home";
import Pagination from "../../../component/Pagination/Pagination.component";
import { truncateString } from "../../../helpers/formatter";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import NoDataState from "../../../component/NoDataState/NoDataState";
import transactionIcon from "../../../assets/images/svg/transaction-icon.svg";
import { useSearchParams } from "react-router-dom";

export default function Requests() {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();

  const [modalType, setModalType] = useState("");
  const [showDateRangeModal, setShowDateRangeModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [request, setRequest] = useState<any>();

  const [searchParams, setSearchParams] = useSearchParams({});

  const [searchState, setSearchState] = useState<SearchStateType>({
    startDateForTrnx: new Date(year, month, 1),
    endDateForTrnx: new Date(),
  });

  const { data: posRequests, isLoading: posRequestsLoading } = usePosRequests({
    page: currentPage,
    size: postsPerPage,
  });
  const { data: posAnalytics, isLoading: posAnalyticsLoading } =
    usePosAnalytics({
      startDate: searchState.startDateForTrnx,
      endDate: searchState.endDateForTrnx,
    });

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.startDateForTrnx || currentParams?.endDateForTrnx) {
      setSearchState({
        startDateForTrnx: new Date(currentParams?.startDateForTrnx),
        endDateForTrnx: new Date(currentParams?.endDateForTrnx),
      });
    }
    if (currentParams?.page || currentParams?.size) {
      setCurrentPage(JSON.parse(currentParams?.page));
      setPostsPerPage(JSON.parse(currentParams?.size));
    }
  }, [searchParams, currentPage]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (
      !currentParams?.startDateForTrnx ||
      !currentParams?.endDateForTrnx ||
      !currentParams?.page ||
      !currentParams?.size
    ) {
      setSearchParams({
        startDateForTrnx: moment(searchState.startDateForTrnx).format(
          "YYYY-MM-DD"
        ),
        endDateForTrnx: moment(searchState.endDateForTrnx).format("YYYY-MM-DD"),
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDateForTrnx, searchState.endDateForTrnx, searchParams]);
  if (posAnalyticsLoading) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[120px]">
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
      </div>
    );
  }
  return (
    <>
      <div className="bg-aellaDimGray w-full px-10 py-[32px]">
        <div className="flex flex-col bg-white px-0 md:px-10 pt-[24px] pb-10">
          <div className="flex flex-col lg:flex-row items-center justify-between pb-[13.5px] w-full border-b border-[#EDEFF5] mb-6">
            <h1 className="text-[24px] lg:leading-[29px] font-[300]  items-center">
              Analytics
            </h1>
            <div
              className="text-[13px] font-[300] pt-4 lg:pl-6 flex items-center cursor-pointer"
              onClick={() => setShowDateRangeModal(true)}
            >
              <span className="py-2 pr-4 lg:leading-[18px] text-aellaGray">
                Showing:
              </span>
              <span className="border rounded py-2 flex flex-wrap px-2.5">
                <CalendarIcon />
                <span className="pl-1 lg:leading-[20px] tracking-[0.2px] pr-1">
                  {moment(searchState.startDateForTrnx).format("DD MMM YYYY")} -{" "}
                  {moment(searchState.endDateForTrnx).format("DD MMM YYYY")}
                </span>
                <div>
                  <ArrowIcon />
                </div>
              </span>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col py-4 px-6 lg:border-r border-[#EDEFF5] w-full shadow-[0_20px_60px_20px_rgba(197, 212, 246, 0.15)]">
              <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                TOTAL OF NUMBER POS Terminal
              </h1>

              <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                {posAnalytics?.total_pos_machine}
              </h1>
              <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                This is the total number of POS <br></br>Machines distributed to
                merchant.
              </p>
            </div>
            <div className="flex flex-col py-4 px-6 lg:border-r border-[#EDEFF5] w-full">
              <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                TOTAL ACTIVE
              </h1>

              <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                {posAnalytics?.total_active_pos_machine}
              </h1>
              <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                This is the total number of POS Terminal Terminals approved and
                active.
              </p>
            </div>
            <div className="flex flex-col py-4 px-6 w-full">
              <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                TOTAL INACTIVE
              </h1>

              <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                {posAnalytics?.total_inactive_pos_machine}
              </h1>
              <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                This is the total number of POS Terminal Terminals approved but
                inactive.
              </p>
            </div>
          </div>
        </div>
        <div className="py-6 border border-aellaBorderGray  bg-white mt-[42px] mb-5">
          <div className="flex justify-between items-center pb-[18px] px-6 lg:px-10 ">
            <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350]">
              POS Request
            </h1>
          </div>
          <div className="pt-10 border-t border-[#F1F1F1] px-6 lg:px-10">
            <div className="overflow-x-auto">
              {posRequests?.data?.length === 0 ? (
                <NoDataState
                  icon={transactionIcon}
                  textHeader="No Pos Request"
                  firstParaText="There is no Pos Request."
                />
              ) : (
                <div className="">
                  <div className="flex items-center bg-[#F7F9FC] w-[380%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b">
                    <p className="w-[20%]">Terminal Id</p>
                    <p className="w-[20%]"> Customer Name</p>
                    <p className="w-[20%]"> Request Date</p>
                    <p className="w-[25%] text-center"> Status</p>
                    <p className="w-[10%]"> Quantity</p>
                    <p className="flex-1 text-right">Address</p>
                  </div>

                  {posRequests?.data?.map((data: any) => {
                    return (
                      <div
                        key={data?.id}
                        onClick={() => {
                          setRequest(data);
                          setModalType("request");
                        }}
                        className="flex items-center bg-white w-[380%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
                      >
                        <p className="w-[20%]">
                          {data?.tid ? truncateString(data?.tid, 10) : "-"}
                        </p>
                        <p className="w-[20%]">
                          {truncateString(data?.full_name, 10)}
                        </p>
                        <p className="w-[20%] ">
                          {moment(data?.created_at).format("DD MMM YYYY | LT")}
                        </p>

                        <p className={`w-[25%] text-center`}>
                          <span
                            className={`w-full text-center ${attachClassNameToTag(
                              data?.request_status
                            )} `}
                          >
                            {getStatusName(data?.request_status)}
                          </span>
                        </p>

                        <p className="w-[10%]">{data?.quantity}</p>
                        <p className="flex-1 text-right">
                          {truncateString(data?.delivery_address, 10)}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={posRequests?.totalItems}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={posRequests?.totalPages}
            />
          </div>
        </div>
      </div>
      {modalType && (
        <PosRequestModal
          modalType={modalType}
          setModalType={setModalType}
          request={request}
        />
      )}
      {showDateRangeModal && (
        <DateRangeModal
          startDate={searchState.startDateForTrnx}
          setSearchState={setSearchState}
          endDate={searchState.endDateForTrnx}
          searchState={searchState}
          setCloseModal={setShowDateRangeModal}
          startDateName="startDateForTrnx"
          endDateName="endDateForTrnx"
          showModal={showDateRangeModal}
        />
      )}
    </>
  );
}
