import { Player } from "@lottiefiles/react-lottie-player";
import AnimationData from "../../assets/files/loader.json";
import SpinnerAnimationData from "../../assets/files/loader-spinner.json";
export const Loader = () => {
  return (
    <Player
      autoplay
      controls
      loop
      src={AnimationData}
      style={{
        width: "20px",
        height: "20px",
        color: "#FFF",
      }}
    ></Player>
  );
};
export const SpinLoader = () => {
  return (
    <Player
      autoplay
      controls
      loop
      src={SpinnerAnimationData}
      style={{
        width: "20px",
        height: "20px",
        color: "#2054D2",
        margin: 0,
      }}
    ></Player>
  );
};
