import moment from "moment";
import React from "react";
import CloseIcon from "../../../assets/images/svg/close-icon.svg";
import {
  attachClassNameToTag,
  getStatusName,
} from "../../../helpers/attachClassNameToTag";

interface TPosRequestModal {
  modalType: string;
  setModalType: (newState: string) => void;
  request: any;
}

const PosRequestModal: React.FC<TPosRequestModal> = ({
  modalType,
  setModalType,
  request,
}) => {
  const singleTid = request?.tid?.split(",");
  return (
    <>
      <div className="fixed justify-center items-center flex overflow-x-hidden inset-0 z-50 outline-none focus:outline-none">
        <div className="relative mt-2 mx-auto w-[464px]">
          <div className="flex mb-2 justify-between">
            <div></div>
            <button
              onClick={() => {
                setModalType("");
              }}
              type="button"
              className="cursor-pointer"
            >
              <img src={CloseIcon} alt="close modal" />
            </button>
          </div>
          {/*content*/}
          <div className="border-0 bg-white rounded-lg p-10 flex flex-col w-screen max-h-[85vh] overflow-y-auto md:w-full bg-colors-white outline-none focus:outline-none ">
            {(() => {
              switch (modalType) {
                case "request":
                  return (
                    <>
                      {/*header*/}
                      <div className="flex flex-col">
                        <h1 className="leading-6 font-[400] text-[20px] tracking[0.4px] pb-[17px] border-b">
                          POS Request Details
                        </h1>
                        <div className="pt-8">
                          <div className="flex flex-col md:flex-row py-[18px] md:border-y justify-between items-top">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Status
                              </p>
                              <p
                                className={`${attachClassNameToTag(
                                  request?.request_status
                                )} leading-[19px] font-[200] text-[16px] tracking[0.2px] mt-2`}
                              >
                                {getStatusName(request?.request_status)}
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Terminal ID
                              </p>
                              {request?.tid && (
                                <ul className="list-decimal list-inside">
                                  {singleTid?.map((data: string) => {
                                    return (
                                      <li className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                        {data}
                                      </li>
                                    );
                                  })}
                                </ul>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row py-[18px] md:border-b justify-between md:items-center">
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Request Date
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {moment(request?.created_at).format(
                                  "MMM. DD, YYYY"
                                )}
                              </p>
                            </div>
                            <div className="flex flex-col">
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                                Delivery Date
                              </p>
                              <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] pt-2">
                                {request?.delivery_date
                                  ? moment(request?.created_at).format(
                                      "MMM. DD, YYYY"
                                    )
                                  : "N/A"}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="pt-[18px]">
                          <p className="leading-[19px] font-[200] text-[16px] tracking[0.2px] text-aellaGray">
                            Delivery address
                          </p>
                          <div className="mt-2 border border-[#EDEFF5] rounded-lg px-[16px] py-[22px] leading-[17px] font-[200] text-[14px] text-[#5B5B5B]">
                            {request?.delivery_address}
                          </div>
                        </div>
                        {request?.declined_reason && (
                          <div className="pt-[38px]">
                            <p className="leading-[20px] font-[100] text-[16px] tracking[0.03em] ">
                              Reason for Decline
                            </p>
                            <div className="mt-2 border border-[#D8A28F] bg-[#F9EEE9] rounded-lg px-[16px] py-[22px] font-[100] leading-[24px] text-[14px] tracking[0.2px]">
                              {request?.declined_reason}
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  );
                default:
                  return null;
              }
            })()}
          </div>
        </div>
      </div>
      <div
        className="bg-opacity-25 fixed inset-0 z-40 bg-[#0B1E4B]"
        onClick={() => {
          setModalType("");
        }}
      ></div>
    </>
  );
};
export default PosRequestModal;
