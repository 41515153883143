export enum apiEndpoints {
  LOGIN = "/user/login",
  CREATE_MERCHANT = "/merchant/createmerchant",
  RESEND_OTP = "/user/resend-otp",
  VERIFY_MERCHANT_PHONE_NUMBER = "/merchant/verifyphone",
  VERIFY_USER_PHONE_NUMBER = "/user/verifyphone",
  GET_ALL_SERVICES = "/service",
  VALIDATE_OTP = "/user/activate",
  UPDATE_USER_OTP = "/user/update-phone-number",
  CREATE_MERCHANT_SERVICE = "/merchant/createmerchantservice",
  TERMS = "/merchant/acceptterm",
  GET_MERCHANT_DETAILS = "/merchant/getbyuserid",
  GET_MERCHANT_SERVICES = "/service/getservicesbymerchantId",
  GET_ALL_COUNTRIES = "/country",
  GET_ALL_STATES = "/state",
  GET_MERCHANT_PROFILE = "/merchant/profile/",
  INITIATE_RESET_PASSWORD = "/user/reset-password/initiate",
  RESET_PASSWORD = "/user/reset-password/update",
  CREATE_BUSINESS_PROFILE = "/merchant/createbusinessprofile",
  GET_WITHDRAWAL_BANKS = "/withdrawal/banks",
  VALIDATE_BANKS = "/withdrawal/resolve",
  CREATE_TRANSACTION_PIN = "/user/pin/create",
  CREATE_MERCHANT_TRANSACTION_PIN = "/merchant/transactionpin",
  GET_WALLET_BALANCE = "/wallet/balance",
  GET_WALLET_TRANSACTION_SUMARRY = "/wallet/transaction/summary",
  GET_WALLET_TRANSACTIONS = "/wallet/transaction",
  ENVIRONMENT = "/merchant/environment",
  GET_MERCHANT_WITHDRAWAL_ACCOUNTS = "/withdrawal",
  // WITHDRAW_FROM_WALLET = "/walletbank/withdrawal/run",
  WITHDRAW_FROM_WALLET = "/transfer/banks",
  MERCHANT_ACCOUNT = "/bank-account/merchant",
  VERIFY_PIN = "/internal/user/verify-secret",
  ADD_WITHDRAWAL_ACCOUNT = "/bank-account/register",
  WALLET_TO_WALLET_TRANSFER = "/wallet/transfer",
  API_SECRET_KEY = "/merchant/merchantkey",
  POS_ANALYTICS = "/pos/requestanalytics",
  POS_REQUESTS = "/pos/request",
  POS_TRANSACTIONS = "/pos/postransactions",
  GET_INFLOW_TRANSACTIONS = "/inflow/internal/search",
  GET_INFLOW_TRANSACTIONS_SUM = "/inflow/internal/value",
  GET_ALL_MERCHANT_SERVICES = "/service/allmerchantwallets",
  GET_MERCHANT_BANK_ACCOUNT = "/bank-account/merchant",
  GENERATE_ACCOUNT = "/bank-account/generate",
  TRANSFER_TO_BANK = "/transfer/banks",
  TRANSFER_TO_WALLET = "/transfer/wallets",
  GET_WALLET_DETAILS = "/transfer/walletdetails",
  GET_TRANSFER_LIST = "/transfer/list",
  GET_TRANSFER_FEE = "/transferfee",
  POS_DISPUTES = "/dispute/merchant/dispute",
  UPLOAD_MERCHANT_PROFILE = "/merchant/socialslogo",
  CHANGE_PASSWORD = "/user/reset-password/change",
  UPDATE_NOTIFICATION = "/merchant/notifications",
  GET_BULK_TRANSFER_LIST = "/transfer/bulk/transactions",
  RESOLVE_BULK_ACCOUNTS = "/transfer/bulk/resolve-accounts",
  GET_BANK_SORT_CODES = "/transfer/bulk/sort_codes",
  INITIATE_BULK_TRANSFER = "/transfer/bulk/disburse",
  GET_AGENTS_LIST = "/agent",
  GET_ALL_SUBACCOUNTS = "/agent",
  GET_ALL_SUBACCOUNTS_DETAILS = "/agentid",
  GET_SUBACCOUNTS_ACCOUNT_DETAILS = "/merchant/agentbalance",
  CREATE_SUBACCOUNT = "/merchant/createagent",
  SUSPEND_ACCOUNT = "/bank-account/suspend",
}
