import { FC, useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";

import { ReactComponent as BackIcon } from "../../assets/images/svg/back-icon.svg";

import { formatMoney } from "../../helpers/formatMoney";
import BlueButton from "../../component/Button/BlueButton";
import BulkTransferDetails from "./BulkTransferDetails";
import { useGroupedList } from "../../hooks/useTransfersList";
import { truncateString } from "../../helpers/formatter";
import { SecondaryLoader } from "../../component/Loader/SecondaryLoader";

type ViewTransferProps = {
  setViewTransfer: (newState: boolean) => void;
  selectedBulkTransfer: any;
};

const ViewBulkTransfer: FC<ViewTransferProps> = ({
  setViewTransfer,
  selectedBulkTransfer,
}) => {
  const [limit, setLimit] = useState<any>("");

  const { data: groupedList, isLoading } = useGroupedList({
    page: 1,
    limit: limit,
    selected: selectedBulkTransfer?.group_id,
  });

  useEffect(() => {
    if (groupedList) {
      setLimit(Math.round(Number(groupedList?.data?.total)) + 10);
    }
  }, [groupedList]);

  const itemsPerPage = 13;
  const [itemOffset, setItemOffset] = useState(0);
  const items = groupedList?.data?.data;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items?.length / itemsPerPage);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items?.length;
    setItemOffset(newOffset);
  };
  const startOfDisplay = itemOffset + 1;
  const endOfDisplay = itemOffset + currentItems?.length;

  const [selectedTransfer, setSelectedTransfer] = useState({});
  const [showModal, setShowModal] = useState(false);
  if (isLoading) {
    return <SecondaryLoader count={10} />;
  }

  return (
    <div className="h-screen w-full bg-[#F6F8FA]">
      <BulkTransferDetails
        selectedTransfer={selectedTransfer}
        setSelectedTransfer={setSelectedTransfer}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <div
        onClick={() => setViewTransfer(false)}
        className="h-20 px-4 md:px-10 bg-white flex items-center gap-x-4 text-[18px] text-[#011C34] font-light border-t cursor-pointer"
      >
        <BackIcon />
        Back
      </div>
      <div className="mx-4 md:mx-10 mt-4 mb-14 2xl:mb-20 overflow-x-auto hide_scrollbar">
        <div className="flex items-center bg-[#F7F9FC] w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b">
          {/* <input type="checkbox" className="w-4 h-4 border-red mr-4" /> */}
          <p className="w-[16%]">Account Name</p>
          <p className="w-[18%]">Account Number</p>
          <p className="w-[15%]">Recipient Bank</p>
          <p className="w-[17%]">Amount</p>
          <p className="w-[%]">Status</p>
          <p className="flex-1 text-right">Time Stamp</p>
        </div>
        {currentItems?.map((list: any) => {
          return (
            <div
              key={list?.user_id + Math.random()}
              onClick={() => {
                setSelectedTransfer(list);
                setShowModal(true);
              }}
              className="flex items-center bg-white w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
            >
              {/* <input type="checkbox" className="w-4 h-4 border-red mr-4" /> */}
              <p className="w-[16%] capitalize">
                {" "}
                {truncateString(list?.account?.name?.toLowerCase(), 14) || "-"}
              </p>
              <p className="w-[18%]">{list?.account?.number}</p>
              <div className="w-[15%] capitalize">
                {list?.bank?.name?.toLowerCase()}
              </div>
              <p className="w-[17%]">{`₦${formatMoney(list?.amount)}`}</p>
              <p
                className={`w-[6%] inline-flex justify-center py-[2px] rounded-[4px] text-[0.75rem] ${
                  list?.status?.toLowerCase() === "success"
                    ? "text-[#14804A] bg-[#E1FCEF]"
                    : list?.status?.toLowerCase() === "pending"
                    ? "text-[#AA5B00] bg-[#FCF2E6]"
                    : list?.status?.toLowerCase() === "declined"
                    ? "text-[#BD6242] bg-[#FCF2E6]"
                    : "text-[#D1293D] bg-[#FFEDEF]"
                }`}
              >
                {list?.status}
              </p>
              <p className="flex-1 text-right">
                {moment(list?.transaction_date).format("DD MMM YYYY, LT")}
              </p>
            </div>
          );
        })}
        <div className="flex justify-between items-center py-6 px-2 md:px-4 mb-32 bg-white w-[95vw] overflow-hidden md:w-full">
          <p className="text-[0.75rem] md:text-sm text-[#232323] font-light">
            {startOfDisplay}-{endOfDisplay} of {items?.length || 0} Total
            Records
          </p>
          <ReactPaginate
            className={`flex items-center gap-x-2 text-[0.8rem] font-light`}
            activeClassName="flex items-center justify-center bg-primary-red text-aellaBlack px-6 h-10 font-medium bg-[#F6F8FA] border-[2px] border-[#EDEFF5] rounded-[4px]"
            disabledClassName="opacity-30"
            breakLabel="..."
            nextLabel={<BlueButton title="Next" className="h-10" />}
            previousLabel={<BlueButton title="Prev" className="h-10" />}
            nextClassName="text-base flex flex-col gap-x-3"
            nextLinkClassName=""
            previousClassName="text-base"
            pageCount={pageCount}
            onPageChange={handlePageClick}
            renderOnZeroPageCount={() => null}
            marginPagesDisplayed={0}
            pageRangeDisplayed={0}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewBulkTransfer;
