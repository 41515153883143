import { useState } from "react";
import LandingLayout from "../../component/Layouts/LandingLayout.component";
import ResetStepOne from "./ResetStepOne";
import ResetStepThree from "./ResetStepThree";
import ResetStepTwo from "./ResetStepTwo";

export default function ResetPassword() {
  const [step, setStep] = useState<number>(1);
  const [state, setState] = useState({
    email: "",
    otp: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  return (
    <LandingLayout firstText="Business Management" secondText="Simplified ✨">
      <>
        {(() => {
          switch (step) {
            case 1:
              return (
                <ResetStepOne
                  setStep={setStep}
                  state={state}
                  setState={setState}
                />
              );

            case 2:
              return (
                <ResetStepTwo
                  setStep={setStep}
                  state={state}
                  setState={setState}
                />
              );

            case 3:
              return <ResetStepThree state={state} />;
            default:
              return null;
          }
        })()}
      </>
    </LandingLayout>
  );
}
