import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getAllCountries = async () => {
  const { data } = await getData(
    `${CONFIG.BASE_URL2}${apiEndpoints.GET_ALL_COUNTRIES}`
  );
  return data;
};

export const useAllCountries = () => {
  return useQuery(
    "getAllCountries",

    getAllCountries,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
