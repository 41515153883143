import { useNavigate } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../assets/images/svg/back-icon.svg";

const HeaderBackNavigation = () => {
  const navigate = useNavigate();

  return (
    <div className=" w-full px-6 lg:px-10 bg-white">
      <div className="py-[12px] lg:py-[14px] flex justify-between">
        <div
          className="flex items-center cursor-pointer"
          onClick={() => navigate(-1)}
        >
          <BackIcon />
          <span className="pl-[10px] text-[18px] font-[100] lg:leading-[30px] tracking-[0.3px]">
            Back
          </span>
        </div>
        <div></div>
      </div>
    </div>
  );
};

export default HeaderBackNavigation;
