import { useEffect, useState } from "react";
import { currencyFormat, truncateString } from "../../../helpers/formatter";
import {
  attachClassNameToStatus,
  getStatusNameByNumber,
} from "../../../helpers/attachClassNameToTag";
import moment from "moment";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { useWalletTransactions } from "../../../hooks/useWalletTransactions";

import { ReactComponent as CalendarIcon } from "../../../assets/images/svg/calendar-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../assets/images/svg/black-arrow-down-icon.svg";
import DateRangeModal from "../../../modal/DateRangeModal";
import { SearchStateType } from "../../../interfaces/home";
import { useWalletTransactionSummary } from "../../../hooks/useWalletTransactionSummary";
import { useWalletBalance } from "../../../hooks/useWalletBalance";
import InflowTransactionDrawer from "../../InflowTransactions/Drawer/InflowTransactionDrawer";
import Pagination from "../../../component/Pagination/Pagination.component";
import { usePosContext } from "../Pos";
import { useSearchParams } from "react-router-dom";

export default function PosWallet() {
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [transaction, setTransaction] = useState<any>(null);
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const [searchState, setSearchState] = useState<SearchStateType>({
    startDateForTrnx: new Date(year, month, 1),
    endDateForTrnx: new Date(),
  });
  const [searchParams, setSearchParams] = useSearchParams({});
  const { walletId } = usePosContext();

  const {
    data: posWalletTransactions,
    isLoading: posWalletTransactionsLoading,
  } = useWalletTransactions({
    walletID: walletId,
    mode: "wallet",
    page: currentPage,
    size: postsPerPage,
  });

  const { data: outFlowSummary } = useWalletTransactionSummary({
    type: "dr",
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
    walletId,
    mode: "wallet",
  });

  const { data: inFlowSummary } = useWalletTransactionSummary({
    type: "cr",
    startDate: searchState.startDateForTrnx,
    endDate: searchState.endDateForTrnx,
    walletId,
    mode: "wallet",
  });

  const { data: totalWalletData } = useWalletBalance({
    walletID: walletId,
    mode: "wallet",
  });

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.startDateForTrnx || currentParams?.endDateForTrnx) {
      setSearchState({
        startDateForTrnx: new Date(currentParams?.startDateForTrnx),
        endDateForTrnx: new Date(currentParams?.endDateForTrnx),
      });
    }
    if (currentParams?.page || currentParams?.size) {
      setCurrentPage(JSON.parse(currentParams?.page));
      setPostsPerPage(JSON.parse(currentParams?.size));
    }
  }, [searchParams, currentPage]);

  //update the url params with the initial query
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (
      !currentParams?.startDateForTrnx ||
      !currentParams?.endDateForTrnx ||
      !currentParams?.page ||
      !currentParams?.size
    ) {
      setSearchParams({
        startDateForTrnx: moment(searchState.startDateForTrnx).format(
          "YYYY-MM-DD"
        ),
        endDateForTrnx: moment(searchState.endDateForTrnx).format("YYYY-MM-DD"),
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.startDateForTrnx, searchState.endDateForTrnx, searchParams]);

  if (posWalletTransactionsLoading) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[120px]">
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
        <CardSkeletonLoader width={370} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="bg-aellaDimGray w-full px-10 py-[32px]">
        <div className="flex flex-col bg-white px-0 md:px-10 pt-[24px] pb-10">
          <div className="flex flex-col lg:flex-row items-center justify-between pb-[13.5px] w-full border-b border-[#EDEFF5] mb-6">
            <h1 className="text-[24px] lg:leading-[29px] font-[300]  items-center">
              Analytics
            </h1>
            <div
              className="text-[13px] font-[300] flex items-center cursor-pointer"
              onClick={() => setShowTransactionModal(true)}
            >
              <span className="py-2 pr-4 lg:leading-[18px] text-aellaGray">
                Showing:
              </span>
              <span className="border rounded py-2 flex flex-wrap px-2.5">
                <CalendarIcon />
                <span className="pl-1 lg:leading-[20px] tracking-[0.2px] pr-1">
                  {moment(searchState.startDateForTrnx).format("DD MMM YYYY")} -{" "}
                  {moment(searchState.endDateForTrnx).format("DD MMM YYYY")}
                </span>
                <div>
                  <ArrowIcon />
                </div>
              </span>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col py-4 px-6 lg:border-r border-[#EDEFF5] w-full shadow-[0_20px_60px_20px_rgba(197, 212, 246, 0.15)]">
              <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em] uppercase">
                Total Available Balance
              </h1>

              <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                {totalWalletData?.available_balance
                  ? currencyFormat(totalWalletData?.available_balance)
                  : currencyFormat(0)}
              </h1>
              <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                This is the total amount available to be spent in your wallet.
              </p>
            </div>
            <div className="flex flex-col py-4 px-6 lg:border-r border-[#EDEFF5] w-full">
              <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                TOTAL INFLOW TRANSACTIONS
              </h1>

              <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                {inFlowSummary?.sum
                  ? currencyFormat(inFlowSummary?.sum)
                  : currencyFormat(0)}
              </h1>
              <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                This is the total amount of money that has been credited to your
                POS wallet.
              </p>
            </div>
            <div className="flex flex-col py-4 px-6 w-full">
              <h1 className="text-[13px] lg:leading-[16px] font-[300] tracking-[0.03em]">
                TOTAL OUTFLOW TRANSACTIONS
              </h1>

              <h1 className="text-[32px] lg:leading-[38px] font-[300] tracking-[-0.2px] pt-[18px]">
                {outFlowSummary?.sum
                  ? currencyFormat(outFlowSummary?.sum)
                  : currencyFormat(0)}
              </h1>
              <p className="text-[13px]  lg:leading-[20px] font-[200] text-aellaGray pt-2 pb-[30px]">
                This is the total amount of money that has been debited from
                your POS wallet.
              </p>
            </div>
          </div>
        </div>
        <div className="py-6 border border-aellaBorderGray  bg-white mt-[42px] mb-5">
          <div className="flex justify-between items-center pb-[18px] px-6 lg:px-10 ">
            <h1 className="text-[16px] lg:text-[24px] lg:leading-[29px] font-[350]">
              Transaction History
            </h1>
          </div>

          <div className="pt-10 border-t border-[rgb(241,241,241)] px-6 lg:px-10 overflow-x-auto w-full">
            <div>
              <div className="flex items-center bg-[#F7F9FC] w-[350%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b">
                <p className="w-[25%]">Transaction Type</p>
                <p className="w-[25%]">Description</p>
                <p className="w-[20%]"> Transaction Ref</p>
                <p className="w-[30%]"> Amount</p>
                <p className="w-[25%]"> Time Stamp</p>
                <p className="w-[20%] text-right"> Status</p>
              </div>

              {posWalletTransactions?.data?.map(
                (trxn: {
                  amount: number;
                  date: string;
                  status: number;
                  id: string;
                  type: string;
                  description: string;
                  transaction_reference: string;
                  transaction_type: { name: string; description: string };
                }) => {
                  const {
                    amount,
                    date,
                    status,
                    id,
                    transaction_reference,
                    transaction_type,
                    type,
                  } = trxn;
                  return (
                    <div
                      className="flex items-center bg-white w-[350%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
                      key={id}
                      onClick={() => {
                        setIsOpen(true);
                        setTransaction(trxn);
                      }}
                    >
                      <p
                        className={`w-[25%] capitalize  text-[13px] lg:text-[16px] lg:leading-[19px] tracking-[0.2px] font-[300] py-[15px] ${
                          type === "cr"
                            ? "text-[#219653]"
                            : type === "dr"
                            ? "text-[#EB5757]"
                            : "text-aellaGray"
                        }`}
                      >
                        {type === "dr"
                          ? "Debit"
                          : type === "cr"
                          ? "Credit"
                          : ""}
                      </p>

                      <p className="w-[25%]">
                        {transaction_type?.description || "-"}
                      </p>
                      <p className="w-[20%]">
                        {truncateString(transaction_reference, 10)}
                      </p>
                      <p className="w-[30%]">{currencyFormat(amount)}</p>
                      <p className="w-[25%]">
                        {date ? moment(date).format("DD MMM YYYY | LT") : "-"}
                      </p>

                      <p className={`w-[20%] text-right`}>
                        <span
                          className={`w-full text-center ${attachClassNameToStatus(
                            status
                          )} `}
                        >
                          {getStatusNameByNumber(status)}
                        </span>
                      </p>
                    </div>
                  );
                }
              )}
            </div>
          </div>
          {posWalletTransactions?.data?.length > 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={posWalletTransactions?.meta?.total}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              totalPages={posWalletTransactions?.meta?.total}
            />
          )}
        </div>
      </div>

      {showTransactionModal && (
        <DateRangeModal
          startDate={searchState.startDateForTrnx}
          setSearchState={setSearchState}
          endDate={searchState.endDateForTrnx}
          searchState={searchState}
          setCloseModal={setShowTransactionModal}
          startDateName="startDateForTrnx"
          endDateName="endDateForTrnx"
          showModal={showTransactionModal}
        />
      )}
      {/* <wallet transaction Modal /> */}
      <InflowTransactionDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        transaction={transaction}
      />
    </>
  );
}
