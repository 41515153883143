import React from "react";

const ConfirmDelete = () => {
  return (
    <>
      <div className="p-[40px] text-center">
        <p className="text-[24px] mb-[16px] ">Delete Bank Acount</p>
        <p className="text-[#5B5B5B] font-thin mb-[20px] w-[80%] mx-auto">
          You are about to delete this bank account{" "}
          <span className="text-[#0B1E4B]"> (Ecoabnk 3433003038) </span> Note
          that this action cannot be undone.. Would you like to proceed?
        </p>

        <div className="flex justify-center gap-[35px]">
          <button className="px-[16px] py-[16px] text-[14px] mt-[50px] rounded-[4px] text-[#2054D2] border border-[#2054D2]">
            Keep Bank Account
          </button>
          <button className="px-[50px] py-[16px] text-[14px] mt-[50px] rounded-[4px] text-white bg-[#2054D2]">
            Proceed
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmDelete;
