import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { yupValidators } from "../../../helpers/yupValidators";
import { useForm } from "react-hook-form";
import {
  IProfileStepOne,
  ProfileSetUpFormData,
} from "../../../interfaces/profileSetUp";
import { Loader } from "../../../component/Loader/Loader.component";
import CustomSelectDropdown from "../../../component/CustomHTMLElements/CustomSelectDropdown";
import { useAllCountries } from "../../../hooks/useAllCountries";
import { useAllStates } from "../../../hooks/useAllStates";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import { useEffect } from "react";

const schema = yup.object().shape({
  country: yupValidators.genericRequired({}),
  streetNumber: yupValidators.genericRequired({}),
  state: yupValidators.genericRequired({}),
  streetName: yupValidators.genericRequired({
    message: "Please enter your address",
    min: 1,
    max: 100,
  }),
  city: yupValidators.genericRequired({
    message: "Please enter your city",
    min: 1,
    max: 30,
  }),
});

const ProfileStepOne: React.FC<IProfileStepOne> = ({
  submitHandler,
  state,
  merchantProfileData,
}) => {
  const { data: countryData } = useAllCountries();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<ProfileSetUpFormData>({
    resolver: yupResolver(schema),
  });
  const countryId = watch("country");
  const stateId = watch("state");
  const { data: statesData } = useAllStates({ id: countryId });
  useEffect(() => {
    if (merchantProfileData) {
      setValue("country", merchantProfileData?.country_id);
      setValue("state", merchantProfileData?.state_id);
      setValue("city", merchantProfileData?.city);
      setValue("streetNumber", merchantProfileData?.street_number);
      setValue("streetName", merchantProfileData?.street_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantProfileData]);

  const onSubmit = async ({
    country,
    city,
    state,
    streetName,
    streetNumber,
  }: {
    country: string;
    state: string;
    streetName: string;
    streetNumber: string;
    city: string;
  }) => {
    const reqBody = {
      merchant: {
        stage: 1,
        merchant_id: getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID),
      },
      stage_one: {
        country_id: country,
        state_id: state,
        city: city,
        street_name: streetName,
        street_number: streetNumber,
      },
    };
    await submitHandler(reqBody);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex  pb-[26px] items-center">
          <h3 className="text-[20px] lg:leading-[24px] font-[300] tracking-[0.03px]">
            Business Address
          </h3>
          <h1 className="ml-1 text-[12px] leading-[24px] text-[#EB5757]">*</h1>
        </div>

        <CustomSelectDropdown
          label="Country"
          errors={errors.country}
          {...register("country")}
        >
          <option value="">Select Country</option>
          {countryData?.map((data: any) => {
            const { id, name } = data;
            return <option value={id}>{name}</option>;
          })}
        </CustomSelectDropdown>

        <CustomSelectDropdown
          label="State"
          defaultValue={stateId}
          errors={errors.state}
          {...register("state")}
          readOnly={!countryId}
        >
          <option value="">Select State</option>
          {statesData?.map((data: any) => {
            const { id, name } = data;
            return (
              <option value={id} key={id}>
                {name}
              </option>
            );
          })}
        </CustomSelectDropdown>

        <CustomInputField
          type="text"
          maxLength={128}
          label="City"
          errors={errors.city}
          {...register("city")}
        />
        <div className="flex flex-col lg:flex-row">
          <div className=" w-full lg:pr-6">
            <CustomInputField
              type="text"
              maxLength={10}
              label="Street Number"
              errors={errors.streetNumber}
              {...register("streetNumber")}
            />
          </div>
          <div className="w-full">
            <CustomInputField
              type="text"
              maxLength={128}
              label="Street Name"
              errors={errors.streetName}
              {...register("streetName")}
            />
          </div>
        </div>
        <div className="flex justify-between">
          <div></div>
          <div></div>
          <div></div>

          <button
            type="submit"
            className="bg-aellaBlue disabled:opacity-75 rounded text-white p-[15.5px] items-center flex xxl:mt-7 mb-28 lg:mb-0 justify-center cursor-pointer"
            disabled={state.loading}
          >
            Save and Continue
            {state.loading && <Loader />}
          </button>
        </div>
      </form>
    </>
  );
};
export default ProfileStepOne;
