import { ReactComponent as EmptyTransferIcon } from "../../assets/images/svg/empty-transfer-icon.svg";

const EmptyTransfer = ({
  setShowAction,
}: {
  setShowAction: (newState: boolean) => void;
}) => {
  return (
    <div
      onClick={() => setShowAction(false)}
      className="w-full flex-1 bg-[#F6F8FA] flex flex-col justify-center items-center text-[#232323] text-base"
    >
      <div className="w-14 h-16 -mt-14">
        <EmptyTransferIcon />
      </div>
      <p className="font-medium mt-8">No Transfer Has Been Made</p>
      <p className="text-[#5B5B5B] mt-2 font-light">
        You are yet to make a transfer. <br /> When you do, it will appear here.
      </p>
    </div>
  );
};

export default EmptyTransfer;
