export const formatMoney = (text: any) => {
  return Intl.NumberFormat("NGN", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(text));
};

export const formatAmount = (text: any) => {
  return Intl.NumberFormat("NGN", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  }).format(Number(text));
};
