import { useEffect, useState } from "react";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

import { truncateString } from "../../helpers/formatter";
import { useTransfersList } from "../../hooks/useTransfersList";
import { useTransfersData } from "./Transfers";
import { formatMoney } from "../../helpers/formatMoney";
import Pagination from "../../component/Pagination/Pagination.component";
import { SecondaryLoader } from "../../component/Loader/SecondaryLoader";
import EmptyTransfer from "./EmptyTransfer";

const SingleTransfer = () => {
  const {
    setSelectedList,
    setShowListDetails,
    setShowAction,
    singleTransfersList,
    singleTransfersListLoading,
    searchState,
    setSearchState,
    postsPerPage,
    setPostsPerPage,
    currentPage,
    setCurrentPage,
  } = useTransfersData();
  const [listRes, setListRes] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams({});

  //update the  react-query with the url params
  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.from || currentParams?.to) {
      setSearchState({
        from: new Date(currentParams?.from),
        to: new Date(currentParams?.to),
      });
    }
    if (currentParams?.page || currentParams?.size) {
      setCurrentPage(JSON.parse(currentParams?.page));
      setPostsPerPage(JSON.parse(currentParams?.size));
    }
  }, [searchParams, currentPage]);

  useEffect(() => {
    const newData = [];
    if (singleTransfersList?.data.length > 0) {
      for (let data of singleTransfersList?.data) {
        if (
          data?.transaction_type === null ||
          data?.transaction_type === undefined
        ) {
          if (data?.description) {
            let transaction_type = {} as any;
            let desObj = JSON.parse(data?.description);
            transaction_type.name = desObj?.mode;
            data.transaction_type = transaction_type;
          } else {
            data.transaction_type = "N/A";
          }
        }
        newData.push(data);
      }
      singleTransfersList.data = newData;
      setListRes(singleTransfersList);
    }
  }, [singleTransfersList]);

  useEffect(() => {
    const currentParams: any = Object.fromEntries([...searchParams]);

    if (currentParams?.from) {
      setSearchParams({
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
        from: moment(searchState.from).format("YYYY-MM-DD"),
      });
    }
    if (currentParams?.to) {
      setSearchParams({
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
        to: moment(searchState.to).format("YYYY-MM-DD"),
      });
    }
    if (currentParams?.from && currentParams?.to) {
      setSearchParams({
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
        from: moment(searchState.from).format("YYYY-MM-DD"),
        to: moment(searchState.to).format("YYYY-MM-DD"),
      });
    }

    if (searchState.from || searchState.to) {
      setSearchParams({
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
        from: moment(searchState.from).format("YYYY-MM-DD"),
        to: moment(searchState.to).format("YYYY-MM-DD"),
      });
    } else {
      setSearchParams({
        page: JSON.stringify(currentPage),
        size: JSON.stringify(postsPerPage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState, searchParams]);
  if (singleTransfersListLoading) {
    return <SecondaryLoader count={10} />;
  }

  if (!singleTransfersListLoading && !singleTransfersList?.data?.length) {
    return <EmptyTransfer setShowAction={setShowAction} />;
  }

  return (
    <div
      onClick={() => setShowAction(false)}
      className="bg-[#F6F8FA] md:w-full max-h-screen h-auto overflow-y-auto overflow-x-auto md:overflow-x-hidden hide_scrollbar mt-3 md:mt-0"
    >
      <div className="mx-4 md:mx-10 mt-4 mb-14 2xl:mb-20">
        <div className="flex items-center bg-[#F7F9FC] w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full px-4 h-10 text-aellaBlack text-sm border-b">
          <p className="w-[16%]">Transfer Type</p>
          <p className="w-[20%]">Account Name</p>
          <p className="w-[15%] md:w-[20%]">Account Number</p>
          <p className="w-[10%] md:w-[18%]">Amount</p>
          <p className="w-[6%] 2xl:w-[5%] text-center">Status</p>
          <p className="flex-1 text-right">Time Stamp</p>
        </div>
        {singleTransfersList?.data?.map((transfer: any) => {
          return (
            <div
              key={transfer?.reference}
              onClick={() => {
                setSelectedList(transfer);
                setShowListDetails(true);
              }}
              className="flex items-center bg-white w-[280%] gap-x-4 overflow-x-auto md:overflow-x-hidden md:gap-x-0 md:w-full p-4 text-[#5B5B5B] text-sm font-light border-b cursor-pointer"
            >
              <p className="w-[16%]">{transfer?.type}</p>
              <p className="w-[20%] capitalize">
                {truncateString(transfer?.account?.name?.toLowerCase(), 14) ||
                  "-"}
              </p>
              <p className="w-[15%] md:w-[20%]">
                {transfer?.account?.number || "-"}
              </p>
              <p className="w-[10%] md:w-[18%]">
                &#x20A6;{formatMoney(transfer?.amount)}
              </p>
              <p
                className={`w-[6%] 2xl:w-[5%] text-sm py-1 rounded-[4px] text-center capitalize ${
                  transfer?.status?.toLowerCase() === "success"
                    ? "text-[#14804A] bg-[#E1FCEF]"
                    : transfer?.status?.toLowerCase() === "pending"
                    ? "text-[#AA5B00] bg-[#FCF2E6]"
                    : "text-[#D1293D] bg-[#FFEDEF]"
                } `}
              >
                {transfer?.status?.toLowerCase()}
              </p>
              <p className="flex-1 text-right">
                {moment(transfer?.transaction_date).format("DD MMM YYYY, LT")}
              </p>
            </div>
          );
        })}
        <Pagination
          postsPerPage={postsPerPage}
          totalPosts={listRes?.totalItems}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalPages={listRes?.totalItems}
        />
      </div>
    </div>
  );
};

export default SingleTransfer;
