import { currencyFormat } from "../../../helpers/formatter";
import fileIcon from "../../../assets/images/svg/file-blue-icon.svg";
import logIcon from "../../../assets/images/svg/activitylog-icon.svg";
import { getStatusName } from "../../../helpers/attachClassNameToTag";
import { format, formatDistance } from "date-fns";

export default function DisputeActivityLog({
  posDisputeActivity,
}: {
  posDisputeActivity: any;
}) {
  return (
    <>
      <div className="w-full px-6 py-4 bg-white">
        <div className="w-full flex justify-end ">
          {/* <a
                className="rounded-[5px] bg-gray-100 flex items-center gap-3 px-3 py-3 cursor-pointer"
                onClick={toggleFilterModal}
              >
                <img
                  src="/icons/filter-icon.svg"
                  alt=""
                  width={20}
                  height={20}
                />
                <p className="opacity-80">Filter Date</p>
              </a> */}
        </div>
        <div className="w-full overflow-x-auto mt-6">
          {/* <div className="w-full flex gap-3">
                <div className="flex flex-col items-center">
                  <div className="w-10 h-10 flex justify-center items-center rounded-full bg-gray-100">
                    <img src={logIcon} alt="" width={20} height={20} />
                  </div>
                  <div className="w-[2px] flex-1 bg-black" />
                </div>
                <div className="pt-2 w-full pb-3">
                  <div>
                    <p className="text-[1.1rem]">Dispute</p>
                    <p className="text-[0.95rem] mt-1">
                      <span className="opacity-70">Status:</span>
                      <span className="text-primary-green opacity-80 ml-2">
                        Successful
                      </span>
                      <span className="opacity-60 ml-3">
                        * &nbsp;&nbsp; 2 days ago | 4:00 PM
                      </span>
                    </p>
                  </div>
                </div>
              </div> */}
          {/* <div className="w-full flex gap-3">
                <div className="flex flex-col items-center">
                  <div className="w-10 h-10 flex justify-center items-center rounded-full bg-gray-100">
                    <img src={logIcon} alt="" width={20} height={20} />
                  </div>
                  <div className="w-[2px] flex-1 bg-black" />
                </div>
                <div className="pt-2 w-full pb-3">
                  <div>
                    <p className="text-[1.1rem]">Dispute</p>
                    <p className="text-[0.95rem] mt-1">
                      <span className="opacity-70">Status:</span>
                      <span className="text-primary-green opacity-80 ml-2">
                        Successful
                      </span>
                      <span className="opacity-60 ml-3">
                        * &nbsp;&nbsp; 2 days ago | 4:00 PM
                      </span>
                    </p>
                  </div>
                </div>
              </div> */}
          {posDisputeActivity?.length > 0
            ? posDisputeActivity?.map((activity: any) => (
                <div className="w-full flex gap-3" key={activity?.id}>
                  <div className="flex flex-col items-center">
                    <div className="w-10 h-10 flex justify-center items-center rounded-full bg-gray-100">
                      <img src={logIcon} alt="" width={20} height={20} />
                    </div>
                    <div className="w-[2px] flex-1 bg-black" />
                  </div>
                  <div className="pt-2 w-full pb-3">
                    <div>
                      <p className="tracking-[0.2px] text-[16px] leading-[19px] font-[400]">
                        Dispute
                      </p>
                      <p className="tracking-[0.2px] text-[14px] leading-[17px] font-[350] mt-1 flex items-center">
                        <span className="opacity-70">Status:</span>
                        <span
                          className={`${
                            activity?.status.toLowerCase() === "in_progress"
                              ? "text-[#45858B]"
                              : activity?.status.toLowerCase() === "pending"
                              ? "text-[#AA5B00]"
                              : activity?.status.toLowerCase() ===
                                "accept dispute"
                              ? "text-[#14804A]"
                              : activity?.status.toLowerCase() ===
                                "awaiting feedback"
                              ? "text-[#5E5ADB]"
                              : activity?.status.toLowerCase() === "reopened"
                              ? "text-[#5E5ADB]"
                              : "text-[#EB5757]"
                          } ml-2`}
                        >
                          {getStatusName(activity?.status)}
                        </span>
                        <p className="rounded-full w-1 h-1 ml-2 mr-2 bg-aellaGray "></p>

                        <span
                          className={`text-[#232323] tracking-[0.2px] text-[14px] leading-[17px] font-[350]`}
                        >
                          {activity?.authorized_by}
                        </span>
                        <p className="rounded-full w-1 h-1 ml-2 mr-2 bg-aellaGray "></p>
                        <span
                          className={`text-aellaGray tracking-[0.2px] text-[14px] leading-[17px] font-[350]`}
                        >
                          {" "}
                          {formatDistance(
                            new Date(activity?.created_at),
                            new Date(),
                            {
                              addSuffix: true,
                            }
                          )}{" "}
                          | {format(new Date(activity?.created_at), "hh:mm 	a")}
                        </span>
                      </p>
                      {(activity?.metadata?.refundOption ||
                        activity?.metadata?.amount ||
                        activity?.metadata?.attachments?.length ||
                        activity?.metadata?.reason) && (
                        <div className="w-[90%] bg-[#EDEFF5] p-3 rounded-[5px] mt-4">
                          {activity?.metadata?.refundOption && (
                            <p className="tracking-[0.03em] text-[17px] leading-[19px] font-[400] capitalize">
                              {activity?.metadata?.refundOption}
                            </p>
                          )}
                          <div className="w-full tracking-[0.03em] text-aellaGray text-[14px] leading-[17px] font-[400] mt-4 flex flex-col gap-y-4">
                            {activity?.metadata?.customer_name && (
                              <div className="w-full flex flex-wrap items-start gap-3">
                                <p className="w-[45%]">Customer Name:</p>
                                <p className="flex-1 ">
                                  {activity?.metadata?.customer_name}
                                </p>
                              </div>
                            )}
                            {activity?.metadata?.customer_email && (
                              <div className="w-full flex flex-wrap items-start gap-3">
                                <p className="w-[45%]">Customer Email:</p>
                                <p className="flex-1 ">
                                  {activity?.metadata?.customer_email}
                                </p>
                              </div>
                            )}
                            {activity?.metadata?.phone_number && (
                              <div className="w-full flex flex-wrap items-start gap-3">
                                <p className="w-[45%]">
                                  Customer Phone Number:
                                </p>
                                <p className="flex-1 ">
                                  {activity?.metadata?.phone_number}
                                </p>
                              </div>
                            )}
                            {activity?.metadata?.delivery_date && (
                              <div className="w-full flex flex-wrap items-start gap-3">
                                <p className="w-[45%]">
                                  Date item was delivered to:
                                </p>
                                <p className="flex-1 ">
                                  {format(
                                    new Date(activity?.metadata?.delivery_date),
                                    "dd/MM/yyyy"
                                  )}
                                </p>
                              </div>
                            )}
                            {activity?.metadata?.details && (
                              <div className="w-full flex flex-wrap items-start gap-3">
                                <p className="w-[45%]">
                                  Details of product/service provided:
                                </p>
                                <p className="flex-1 ">
                                  {activity?.metadata?.details}
                                </p>
                              </div>
                            )}
                            {activity?.metadata?.delivery_address && (
                              <div className="w-full flex flex-wrap items-start gap-3">
                                <p className="w-[45%]">
                                  Address item was delivered to:
                                </p>
                                <p className="flex-1 ">
                                  {activity?.metadata?.delivery_address}
                                </p>
                              </div>
                            )}
                            {activity?.metadata?.amount && (
                              <div className="w-full flex flex-wrap items-start gap-3">
                                <p className="w-[45%]">Amount:</p>
                                <p className="flex-1 ">
                                  {currencyFormat(activity?.metadata?.amount)}
                                </p>
                              </div>
                            )}
                            {activity?.metadata?.attachments?.length > 0 && (
                              <div className="w-full flex flex-wrap items-start gap-3">
                                <p className="w-[45%]">Attachments:</p>
                                <p className="flex-1 ">
                                  <div className="flex items-center gap-x-4">
                                    {activity?.metadata?.attachments?.map(
                                      (each: any, index: number) => (
                                        <a
                                          href={each.location}
                                          target="_blank"
                                          rel="noreferrer"
                                          className="flex items-center gap-x-1 cursor-pointer"
                                          key={index}
                                        >
                                          <img
                                            src={fileIcon}
                                            alt="File Icon"
                                            width={15}
                                            height={17}
                                          />
                                          <p className="text-aellaBlue text-[14px] leading-[16px] font-[200]">
                                            file{" "}
                                            {activity?.metadata?.attachments
                                              ?.length === 1
                                              ? " "
                                              : index + 1}
                                          </p>
                                        </a>
                                      )
                                    )}
                                  </div>
                                </p>
                              </div>
                            )}
                            {activity?.metadata?.reason && (
                              <div className="w-full flex flex-wrap items-start gap-3">
                                <p className="w-[45%]">Reason:</p>
                                <p className="flex-1 ">
                                  {activity?.metadata?.reason || "N/A"}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))
            : "No Activity Available"}
        </div>
      </div>
    </>
  );
}
