import React from "react";
type SignUpProgressProps = {
  active: boolean;
  step: number;
  stepText: string;
};
export const SignUpProgress = ({
  active,
  step,
  stepText,
}: SignUpProgressProps) => {
  return (
    <>
      <div className="flex ">
        <div
          className={`inline-flex items-center justify-center justify-self-center h-[23px] w-[23px] font-[300] text-[14px] leading-[16px] ${
            active
              ? "bg-aellaBlue text-white border-aellaBlue"
              : "text-aellaGray bg-white border border-aellaGray"
          } rounded-full mr-2`}
        >
          {step}
        </div>
        <p
          className={`${
            active ? " text-aellaBlack font-[400]" : "text-aellaGray font-[300]"
          } text-[16px]  leading[16px] capitalize`}
        >
          {stepText}
        </p>
      </div>
    </>
  );
};
