import React, { useState } from "react";
import CustomInputField from "../../../component/CustomHTMLElements/CustomInputField";
import CustomTextArea from "../../../component/CustomHTMLElements/CustomTextArea";
import { ReactComponent as FileIcon } from "../../../assets/images/svg/file-icon.svg";
import SocialLinkInput from "../Components/SocialLinkInput";
import { ReactComponent as Instagram } from "../../../assets/images/svg/instagram-icon.svg";
import { ReactComponent as Twitter } from "../../../assets/images/svg/twitter-icon.svg";
import { ReactComponent as Facebook } from "../../../assets/images/svg/facebook-icon.svg";
import { useMerchantProfile } from "../../../hooks/useMerchantProfile";
import { getFromLocalStorage } from "../../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../helpers/localStorageKeys";
import CardSkeletonLoader from "../../../component/Skeleton/CardSkeletonLoader.component";
import { useForm } from "react-hook-form";
import { postData } from "../../../apis/apiMethods";
import CONFIG from "../../../helpers/config";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { toast } from "react-toastify";
import { errorHandler } from "../../../helpers/errorHandler";
import { Loader } from "../../../component/Loader/Loader.component";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { getFirstLetter } from "../../../helpers/capitalizeFirstLetter";

interface IUpdateProfile {
  instagram: string;
  facebook: string;
  twitter: string;
  profile: string;
}

const BusinessForm = () => {
  const [currentFile, setCurrentFile] = useState<any>("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("undefined");
  const [ext, setExt] = useState("");
  const merchantId = getFromLocalStorage(LOCAL_STORAGE_KEYS.MERCHANTID);
  const {
    data: merchantProfileData,
    isLoading: ProfileDetailsLoading,
    refetch,
  } = useMerchantProfile({ merchantId });

  const regexInstagram =
    /(?:(?:http|https):\/\/)?(?:www.)?(?:instagram.com|instagr.am)\/([A-Za-z0-9-_]+)/im;

  const regexTwitter =
    /(?:(?:http|https):\/\/)?(?:www.)?(?:twitter.com|twitr.am)\/([a-zA-Z0-9_]+)/im;

  const regexFacebook =
    /(?:(?:http|https):\/\/)?(?:www.)?(?:facebook.com|fb.am)\/([a-zA-Z0-9_]+)/im;

  const schema = yup.object().shape({
    profile: yup.mixed(),

    instagram: yup
      .string()
      .matches(regexInstagram, "Enter a valid instagram url"),
    facebook: yup.string().matches(regexFacebook, "Enter a valid facebook url"),
    twitter: yup.string().matches(regexTwitter, "Enter a valid twitter url"),
  });

  const convertToBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      //@ts-ignore
      reader.onload = () => resolve(reader.result?.split("base64,")[1]);
      reader.onerror = (error) => reject(error);
    });

  const handleFileUpload = async (e: any) => {
    // const file = e.target.files[0];
    const files = e.target.files[0];

    const fileAcceptType = e.target.files[0].type;
    const isValidFile =
      fileAcceptType.startsWith("image/png") ||
      fileAcceptType.startsWith("image/jpg") ||
      fileAcceptType.startsWith("image/jpeg") ||
      fileAcceptType.startsWith("image/svg");

    if (!isValidFile) {
      toast.error(
        "Invalid file extension. Kindly upload a png, jpeg or svg file"
      );
      return (e.target.value = null);
    }
    if (e.target.files[0].size > 3000000) {
      toast.error("File size is too large. Max file allowed is 3mb");
      return (e.target.value = null);
    }

    const base64 = await convertToBase64(files);
    const extension = files.name.split(".").pop();
    // const extenstion = files.split(".").pop();
    setCurrentFile(base64);
    setFile(URL.createObjectURL(e.target.files[0]));
    setExt(extension);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUpdateProfile>({
    resolver: yupResolver(schema),
  });

  const submitForm = async (data: IUpdateProfile) => {
    setLoading(true);
    const reqBody = {
      socials: {
        facebookurl: data?.facebook,
        twitterurl: data?.twitter,
        instagramurl: data?.instagram,
      },
      extension: ext,
      photo: currentFile,
    };

    try {
      const res = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.UPLOAD_MERCHANT_PROFILE}`,
        reqBody
      );
      refetch();
      toast.success(res.message);
    } catch (error) {
      toast.error(errorHandler(error));
    }
    setLoading(false);
  };

  if (ProfileDetailsLoading) {
    return (
      <div className="flex flex-col md:flex-row justify-between px-10 pt-[32px] lg:pt-[30px]">
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
        <CardSkeletonLoader width={320} height={458} />
      </div>
    );
  }

  return (
    <>
      <div className="xl:w-[65%]">
        <form onSubmit={handleSubmit(submitForm)}>
          <div className="flex gap-[25px] lg:flex-row xxs:flex-col">
            <div className="h-[78px] w-[78px] rounded-[50%] bg-[#2054D2] flex justify-between items-center overflow-hidden">
              {merchantProfileData?.logo === null ? (
                <div className="w-full h-full flex justify-center items-center">
                  <p className="uppercase text-white text-[37px] font-thin">
                    {getFirstLetter(merchantProfileData?.first_name)}
                    {getFirstLetter(merchantProfileData?.last_name)}
                  </p>
                </div>
              ) : file !== "undefined" ? (
                <img src={file} alt="profile" className="w-full h-full" />
              ) : (
                <img
                  src={merchantProfileData?.logo}
                  alt="profile"
                  className="w-full h-full"
                />
              )}
            </div>
            <div className="">
              <input
                type="file"
                {...register("profile")}
                id="logo"
                className="hidden"
                onChange={(e) => handleFileUpload(e)}
              />
              <label
                htmlFor="logo"
                className="p-[7px] text-[#093990] cursor-pointer inline rounded-[3px] font-thin border border-[#2054D2]"
              >
                Upload Logo
              </label>

              <div className="flex flex-col gap-[5px] mt-[10px]">
                <p className="font-thin">
                  Upload a custom logo of your business
                </p>
                <p className="text-[#7B7B7B] font-thin">
                  We accepts JPEGs, PNGs and SVGs. 3mb maximum size
                </p>
              </div>
            </div>
          </div>

          {errors.profile && (
            <div className="flex gap-[9px] items-center">
              <div className="w-[5px] h-[5px] bg-[#2054D2] rounded-[50%]"></div>

              <p className="text-[12px] font-thin text-[#6A7581]">
                {errors.profile?.message}
              </p>
            </div>
          )}

          <div className="mt-[3rem] flex flex-col gap-[16px]">
            <CustomInputField
              type="text"
              readOnly
              label="Business Name"
              defaultValue={merchantProfileData?.slug}
            />

            <CustomInputField
              type="email"
              readOnly
              label="Business Email"
              defaultValue={merchantProfileData?.email}
            />

            <CustomTextArea
              readOnly
              label="Business Description"
              defaultValue={merchantProfileData?.description}
            />

            <CustomInputField
              type="text"
              readOnly
              label="BVN"
              defaultValue={merchantProfileData?.bvn}
            />

            <CustomInputField
              type="text"
              readOnly
              label="Business Type"
              placeholder="627389..."
              defaultValue={merchantProfileData?.business_type}
            />

            <p className="font-thin">Business Attachments</p>

            <div className="flex gap-[25px] lg:flex-row xxs:flex-col">
              <div className="flex gap-[11px]">
                <FileIcon />
                <p className="text-[#2054D2] text-[14px] font-thin">
                  {merchantProfileData?.documents[0]?.doc_name}
                </p>
              </div>

              <div className="flex gap-[11px]">
                <FileIcon />
                <p className="text-[#2054D2] text-[14px] font-thin">
                  {merchantProfileData?.documents[1]?.doc_name}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-[50px]">
            <p className="text-[20px] text-[#0B1E4B]">Social media Links</p>
            <p className="text-[14px] font-thin text-[#0B1E4B]">
              Add your social media links that will be visible to your users
            </p>

            <div className="lg:flex justify-between mt-[25px] xss:block ">
              <div className="lg:w-[32%] xss:w-[100%] xxs:mt-[10px]">
                <SocialLinkInput
                  {...register("instagram")}
                  label="Instagram"
                  name="instagram"
                  defaultValue={merchantProfileData?.socials?.instagramurl}
                  icon={<Instagram />}
                />
                <p className="text-[12px] font-thin text-red-600">
                  {errors.instagram?.message}
                </p>
              </div>
              <div className="lg:w-[32%] xss:w-[100%] xxs:mt-[10px]">
                <SocialLinkInput
                  {...register("twitter")}
                  label="Twitter"
                  name="twitter"
                  defaultValue={merchantProfileData?.socials?.twitterurl}
                  icon={<Twitter />}
                />
                <p className="text-[12px] text-red-600 font-thin">
                  {errors.twitter?.message}
                </p>
              </div>
              <div className="lg:w-[32%] xss:w-[100%] xxs:mt-[10px]">
                <SocialLinkInput
                  {...register("facebook")}
                  label="Facebook"
                  name="facebook"
                  defaultValue={merchantProfileData?.socials?.facebookurl}
                  icon={<Facebook />}
                />
                <p className="text-[12px] text-red-600 font-thin">
                  {errors.facebook?.message}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-[32px]">
            <button className="py-[14px] flex gap-[10px] px-[60px] text-white bg-[#2054D2] rounded-[4px]">
              Update
              {loading && <Loader />}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default BusinessForm;
