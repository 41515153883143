import { Dispatch, SetStateAction } from "react";
import Drawer from "../../../component/Drawer/Drawer";
import inputIcon from "../../../assets/images/svg/input-icon.svg";
import houseIcon from "../../../assets/images/svg/gray-house-icon.svg";
import humanIcon from "../../../assets/images/svg/human-icon.svg";
import editIcon from "../../../assets/images/svg/white-edit-icon.svg";
import { Loader } from "../../../component/Loader/Loader.component";

export default function WithdrawalAccountDrawer({
  isOpen,
  setIsOpen,
  detail,
  setModalType,
  setWithdrawalAmount,
  setWithdrawalDescription,
  loading,
  withdrawalAmount,
}: {
  isOpen: boolean;
  setIsOpen: (newState: boolean) => void;
  setModalType: (newState: any) => void;
  setWithdrawalAmount: Dispatch<SetStateAction<any>>;
  setWithdrawalDescription: Dispatch<SetStateAction<string>>;
  loading: boolean;
  withdrawalAmount: number | null;
  detail: any;
}) {
  return (
    <>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className="w-full">
          <div className="flex justify-between items-center border-b pb-[17px]">
            <div className="text-[#0B1E4B] font-[200]  text-[24px] leading-[29px] tracking-[0.2px]">
              Withdraw Funds
            </div>
            <div onClick={() => setIsOpen(false)} className="cursor-pointer">
              <svg
                width="32"
                height="33"
                viewBox="0 0 32 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="0.5" width="32" height="32" rx="16" fill="white" />
                <rect
                  x="11.7305"
                  y="18.6338"
                  width="2.13343"
                  height="2.13343"
                  fill="#6D6D6D"
                />
                <rect
                  x="9.59766"
                  y="20.7671"
                  width="2.13343"
                  height="2.13343"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 11.7305 14.3672)"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 9.59766 12.2334)"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 13.8633 16.5005)"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 15.9961 18.6338)"
                  fill="#6D6D6D"
                />
                <rect
                  x="18.1328"
                  y="12.2334"
                  width="2.13343"
                  height="2.13343"
                  fill="#6D6D6D"
                />
                <rect
                  x="20.2617"
                  y="10.1001"
                  width="2.13343"
                  height="2.13343"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 18.1328 20.7671)"
                  fill="#6D6D6D"
                />
                <rect
                  width="2.13343"
                  height="2.13343"
                  transform="matrix(1 0 0 -1 20.2617 22.9009)"
                  fill="#6D6D6D"
                />
              </svg>
            </div>
          </div>

          <div className="mt-8 flex flex-col w-full">
            <div>
              <p className="pb-2 text-aellaBlack lg:leading-[16px] text-[16px] font-[300]">
                Enter Amount
              </p>

              <div className="flex">
                <span className="inline-flex items-center px-3 text-sm text-aellaGray bg-[#E5B7A0] rounded-l-md border border-r-0 border-[#D9D9D9]">
                  &#8358;
                </span>
                <input
                  type="number"
                  placeholder="0.00"
                  name="amount"
                  className=" placeholder:text-eallaGray rounded-none rounded-r bg-white border min-w-0 w-full text-[16px] lg:leading-[20px] font-[300] border-[#D9D9D9] p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-aellaGray dark:text-white dark:focus:ring-aellaBlue dark:focus:border-aellaBlue"
                  onChange={(e) => setWithdrawalAmount(Number(e.target.value))}
                />
              </div>
            </div>
            <div className="mt-[28px] w-full">
              <p className="pb-2 text-aellaGray lg:leading-[16px] text-[16px] font-[100]">
                Account Number
              </p>
              <div className="relative flex">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <img className="w-3" src={inputIcon} alt="" />
                </div>
                <input
                  type="text"
                  value={detail?.account_number}
                  className="text-aellaGray bg-[#F1F1F1] rounded  border w-full pl-10 py-3 text-[14px] lg:leading-[20px] font-[100] border-[#D9D9D9] p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-aellaGray dark:text-white dark:focus:ring-aellaBlue dark:focus:border-aellaBlue"
                  disabled
                />
              </div>
            </div>
            <div className="mt-[28px] ">
              <p className="pb-2 text-aellaGray lg:leading-[16px] text-[16px] font-[100]">
                Bank
              </p>
              <div className="relative flex ">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <img className="w-5" src={houseIcon} alt="" />
                </div>
                <input
                  type="text"
                  value={detail?.bank_name}
                  className="text-aellaGray bg-[#F1F1F1] rounded  border  min-w-full text-[14px] lg:leading-[20px] font-[100] border-[#D9D9D9] pl-10 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-aellaGray dark:text-white dark:focus:ring-aellaBlue dark:focus:border-aellaBlue"
                  disabled
                />
              </div>
            </div>
            <div className="mt-[28px] ">
              <p className="pb-2 text-aellaGray lg:leading-[16px] text-[16px] font-[100]">
                Account Name
              </p>
              <div className="relative flex ">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <img className="w-5" src={humanIcon} alt="" />
                </div>
                <input
                  type="text"
                  value={detail?.account_name}
                  className="text-aellaGray bg-[#F1F1F1] rounded  border  min-w-full text-[14px] lg:leading-[20px] font-[100] border-[#D9D9D9] pl-10 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-aellaGray dark:text-white dark:focus:ring-aellaBlue dark:focus:border-aellaBlue"
                  disabled
                />
              </div>
            </div>
            <div className="mt-[28px] ">
              <p className="pb-2 text-aellaGray lg:leading-[16px] text-[16px] font-[100]">
                Short description (optional)
              </p>
              <div className="relative flex ">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <img className="w-5" src={editIcon} alt="" />
                </div>
                <input
                  type="text"
                  placeholder="Enter here"
                  className="placeholder:text-eallaGray  rounded bg-white border  min-w-full text-[16px] lg:leading-[20px] font-[100] border-[#D9D9D9] pl-10 py-3 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-aellaGray dark:text-white dark:focus:ring-aellaBlue dark:focus:border-aellaBlue"
                  onChange={(e) => setWithdrawalDescription(e.target.value)}
                />
              </div>
            </div>
            <button
              type="submit"
              className="bg-aellaBlue disabled:opacity-75 rounded text-white py-4 px-6 items-center w-full flex xxl:mt-7 justify-center cursor-pointer mt-[33px]"
              disabled={loading || !withdrawalAmount}
              onClick={() => {
                setModalType("step2");
                setIsOpen(false);
              }}
            >
              Continue
              {loading && <Loader />}
            </button>
          </div>
        </div>
      </Drawer>
    </>
  );
}
