import { useQuery } from "react-query";
import { getData } from "../apis/apiMethods";
import CONFIG from "../helpers/config";

export const getApiKey = async () => {
  const { data } = await getData(`${CONFIG.BASE_URL2}/merchant/merchantkey`);
  return data;
};

export const useApiKey = () => {
  return useQuery(
    "apiKey",

    getApiKey,
    {
      refetchOnWindowFocus: false, // Refetch on window focus
    }
  );
};
