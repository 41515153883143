import { useState, useEffect } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useQueryClient } from "react-query";

import { ReactComponent as ChevronIcon } from "../../assets/images/svg/chevron-down.svg";
import { ReactComponent as FilterIcon } from "../../assets/images/svg/filter-transaction-icon.svg";
import { ReactComponent as BankIcon } from "../../assets/images/svg/transfer-to-bank-icon.svg";
import { ReactComponent as WalletIcon } from "../../assets/images/svg/transfer-to-wallet-icon.svg";
import TransferModal from "./TransferModal";
import TransferSummary from "./TransferSummary";
import TransferDetailsModal from "./TransferDetailsModal";
import TransferListDetailsModal from "./TransferListDetailsModal";
import TransferStatus from "./TransferStatus";
import { postData } from "../../apis/apiMethods";
import CONFIG from "../../helpers/config";
import { errorHandler } from "../../helpers/errorHandler";
import { apiEndpoints } from "../../apis/apiEndpoints";
import { useWalletDetails } from "../../hooks/useWalletDetails";
import { TransferFormData } from "../../interfaces/transfer";
import { useMerchantDetails } from "../../hooks/useMerchantDetails";
import { getFromLocalStorage } from "../../helpers/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../helpers/localStorageKeys";
import { useTransfersList } from "../../hooks/useTransfersList";
import { yupValidators } from "../../helpers/yupValidators";
import Pin from "./Pin";
import BulkTransfers from "./BulkTransfers";
import ViewBulkTransfer from "./ViewBulkTransfer";
import { useAgentList } from "../../hooks/useAgentList";
import Tab from "../../component/Tab/Tab.component";
import { ROUTES } from "../../helpers/routes";
import { DateType } from "../../interfaces/home";
import DateFilterModal from "../../modal/DateFilterModal";

type ContextType = {
  setSelectedList: any;
  setShowListDetails: (newState: boolean) => void;
  setShowAction: (newState: boolean) => void;
  setViewTransfer: (newState: boolean) => void;
  setSelectedBulkTransfer: (newState: object) => void;
  merchantDetails: any;
  uploadStage: string;
  viewTransfer: boolean;
  singleTransfersListLoading: boolean;
  singleTransfersList: any;
  searchState: any;
  setSearchState: any;
  setPostsPerPage: any;
  currentPage: number;
  setCurrentPage: any;
  postsPerPage: number;
};

const Transfers = () => {
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showTransferSummary, setShowTransferSummary] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [type, setType] = useState("");
  const [formInfo, setFormInfo] = useState({});
  const [validateReceiver, setValidateReceiver] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiver, setReceiver] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");
  const [amount, setAmount] = useState<any | undefined>();
  const [transactionData, setTransactionData] = useState({});
  const [bankId, setBankId] = useState("");
  const [accountName, setAccountName] = useState("");
  const [bank, setBank] = useState({});
  const [selectedTransfer, setSelectedTransfer] = useState<any>({});
  const [nameLoading, setNameLoading] = useState(false);
  const [narration, setNarration] = useState("");
  const [transactionReference, setTransactionReference] = useState("");
  const [loadFee, setLoadFee] = useState(false);
  const [selectedList, setSelectedList] = useState<any>({});
  const [showListDetails, setShowListDetails] = useState(false);
  const [amountToSend, setAmountToSend] = useState<any>();
  const [pin, setPin] = useState("");
  const [showPinModal, setShowPinModal] = useState(false);
  const [listType, setListType] = useState("single");
  const [uploadStage, setUploadStage] = useState("");
  const [viewTransfer, setViewTransfer] = useState(false);
  const [selectedBulkTransfer, setSelectedBulkTransfer] = useState({});
  const [bulkInfo, setBulkInfo] = useState({});
  const [senderId, setSenderId] = useState("");
  const [selectedAccount, setSelectedAccount] = useState("");
  const [showDateModal, setShowDateModal] = useState(false);
  const [searchState, setSearchState] = useState<any>({
    from: null,
    to: null,
  });

  const [size, setSize] = useState<any>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(13);

  const userId = getFromLocalStorage(LOCAL_STORAGE_KEYS.USERID);
  const location: any = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === ROUTES.TRANSFERS) {
      navigate(ROUTES.SINGLE_TRANSFER);
    }
  }, [navigate, location]);

  const { data: merchantDetails } = useMerchantDetails({ userId });

  interface UpdateTransferFormData {
    amount: string | number;
    bankName: string;
    accountNumber: string;
    accountName: string;
  }
  const updateFormSchema = yup.object().shape({
    amount: yupValidators.amount,
    bankName: yupValidators.bankName,
    accountNumber: yupValidators.accountNumber,
    accountName: yupValidators.accountName,
  });

  const {
    register: bulkRegister,
    handleSubmit: bulkHandleSubmit,
    reset: bulkReset,
    watch: bulkWatch,
    setValue: bulkSetValue,
    formState: { errors: bulkErrors },
  } = useForm<UpdateTransferFormData>({
    resolver: yupResolver(updateFormSchema),
  });

  const {
    data: singleTransfersList,
    isLoading: singleTransfersListLoading,
    refetch: refetchTransfersList,
  } = useTransfersList({
    page: currentPage,
    size: postsPerPage,
    from: searchState.from,
    to: searchState.to,
  });

  const queryClient = useQueryClient();

  const walletSchema = yup.object().shape({
    amount: yupValidators.amount,
    destinationEmail: yupValidators.email,
  });
  const bankSchema = yup.object().shape({
    amount: yupValidators.amount,
    destinationAccountNumber: yupValidators.accountNumber,
    bankName: yupValidators.bankName,
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm<TransferFormData>({
    resolver: yupResolver(type === "Bank" ? bankSchema : walletSchema),
  });

  const [form, setForm] = useState<any>(null);
  const resetMyForm = () => {
    setForm({
      amount: "",
      bankName: "",
      destinationAccountNumber: "",
      destinationEmail: "",
      transferReference: "",
      narration: "",
      accountNumber: "",
      accountName: "",
    });
    setBankId("");
    setAccountName("");
    setReceiver("");
    setReceiverEmail("");
    setFormInfo({});
    setNarration("");
    setPin("");
    setSelectedAccount("");
  };

  useEffect(() => {
    reset(form);
  }, [form]);

  useEffect(() => {
    bulkReset(form);
  }, [form]);

  const { data: receiverDetails, isLoading: receiverDetailsLoading } =
    useWalletDetails({
      email_or_phone: receiverEmail,
    });

  const { data: agentList, isLoading: subAccountDetailsLoading } =
    useAgentList();

  const selectedAccountName =
    agentList?.data?.find((agent: any) => agent?.wallet_id === selectedAccount)
      ?.name ||
    agentList?.data?.find((agent: any) => agent?.wallet_id === selectedAccount)
      ?.trading_name;

  const watchedAmt: any = watch("amount");
  const summaryAmount = Number(watchedAmt?.replace(/,/g, ""));

  const handleWalletTransfer = async () => {
    if (pin.length !== 4) {
      toast.error("Invalid Pin");
      return;
    }
    try {
      setTransactionData({});
      setLoading(true);
      const walletReqBody = {
        narration,
        amount: Number(watchedAmt?.replace(/,/g, "")),
        receiver_wallet_id: receiverDetails?.wallet_id,
        transaction_reference: transactionReference,
        wallet_id: selectedAccount
          ? selectedAccount
          : merchantDetails?.wallet_id,
        code: pin,
      };

      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.TRANSFER_TO_WALLET}`,
        walletReqBody
      );
      setLoading(false);
      setTransactionData(res);
      setValidateReceiver(false);
      setShowPinModal(false);
      setShowStatus(true);
    } catch (error: any) {
      setTransactionData(error?.res);
      if (
        error?.response?.data?.message?.toLowerCase() ===
        "transaction pin validation failed"
      ) {
        toast.error("Incorrect Pin");
      } else toast.error(errorHandler(error));
      setLoading(false);
      return;
    } finally {
      queryClient.invalidateQueries("getTransferList");
    }
  };

  const validateBankDetails = async (id: string) => {
    try {
      setNameLoading(true);
      const reqBody = {
        account_number: receiver,
        bank_id: bankId,
      };
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.VALIDATE_BANKS}`,
        reqBody
      );
      setAccountName(res?.data?.account_name);
      setBank(res?.data);
    } catch (error) {
      // toast.error(errorHandler(error));
      setAccountName("");
    } finally {
      setNameLoading(false);
    }
  };

  const handleBankTransfer = async () => {
    if (pin.length !== 4) {
      toast.error("Invalid Pin");
      return;
    }
    try {
      setTransactionData({});
      setLoading(true);
      const bankReqBody = {
        narration,
        amount: Number(watchedAmt?.replace(/,/g, "")),
        account_number: receiver,
        bank_id: bankId,
        account_name: accountName,
        transaction_reference: transactionReference,
        wallet_id: selectedAccount
          ? selectedAccount
          : merchantDetails?.wallet_id,
        code: pin,
      };

      const res: any = await postData(
        `${CONFIG.BASE_URL2}${apiEndpoints.TRANSFER_TO_BANK}`,
        bankReqBody
      );
      setLoading(false);
      setTransactionData(res);
      setValidateReceiver(false);
      setShowPinModal(false);
      setShowStatus(true);
    } catch (error: any) {
      setTransactionData(error?.res);
      if (
        error?.response?.data?.message?.toLowerCase() ===
        "transaction pin validation failed"
      ) {
        toast.error("Incorrect Pin");
      } else toast.error(errorHandler(error));
      setLoading(false);
    } finally {
      queryClient.invalidateQueries("getTransferList");
    }
  };

  const ActionNav = () => {
    return (
      <div className="bg-white absolute top-[7rem] right-6 ml-6 md:ml-0 md:right-10 md:top-[5.5rem] h-auto p-[6px] flex flex-col rounded-[4px] z-10 shadow-md">
        <div
          onClick={() => {
            setType("Bank");
            setShowAction(false);
            setShowTransferModal(true);
          }}
          className="h-[45%] flex p-3 gap-x-3 text-[#232323] hover:text-[#232323]  hover:bg-[#F3F5FC] rounded-[4px] cursor-pointer"
        >
          <div className="shrink-0 h-10 w-10">
            <BankIcon />
          </div>
          <div className="">
            <p className="font-medium">Transfer To Bank</p>
            <p className="font-light text-sm">
              Transfer funds from any of your wallet to a bank account.
            </p>
          </div>
        </div>
        <div
          onClick={() => {
            setType("Wallet");
            setShowAction(false);
            setShowTransferModal(true);
          }}
          className="h-[45%] flex p-3 gap-x-3 text-[#232323] hover:text-[#232323] hover:bg-[#F3F5FC] rounded-[4px] cursor-pointer"
        >
          <div className="shrink-0 h-10 w-10">
            <WalletIcon />
          </div>
          <div className="">
            <p className="font-medium">Transfer To Wallet</p>
            <p className="font-light text-sm">
              Transfer funds from one wallet to another.
            </p>
          </div>
        </div>
      </div>
    );
  };

  const singleTransfer = location.pathname.startsWith(
    `${ROUTES.TRANSFERS}/${ROUTES.SINGLE_TRANSFER}`
  );

  const bulkTransfer = location.pathname.startsWith(
    `${ROUTES.TRANSFERS}/${ROUTES.BULK_TRANSFER}`
  );

  const initiateTransfer = () => {
    if (singleTransfer) {
      setShowAction(!showAction);
    } else setUploadStage("initial");
  };

  const renderBody = () => {
    if (bulkTransfer && !uploadStage && viewTransfer) {
      return (
        <ViewBulkTransfer
          setViewTransfer={setViewTransfer}
          selectedBulkTransfer={selectedBulkTransfer}
        />
      );
    }
  };

  return (
    <div className="relative h-screen">
      {showAction && <ActionNav />}
      {!uploadStage && (
        <>
          <TransferModal
            showModal={showTransferModal}
            setShowModal={setShowTransferModal}
            setShowTransferSummary={setShowTransferSummary}
            type={type}
            formInfo={formInfo}
            setFormInfo={setFormInfo}
            setValidateReceiver={setValidateReceiver}
            onSubmit={
              type === "Bank" ? handleBankTransfer : handleWalletTransfer
            }
            receiver={receiver}
            setReceiver={setReceiver}
            receiverEmail={receiverEmail}
            setReceiverEmail={setReceiverEmail}
            setAmount={setAmount}
            validateBankDetails={validateBankDetails}
            bankId={bankId}
            setBankId={setBankId}
            accountName={accountName}
            nameLoading={nameLoading}
            setNarration={setNarration}
            setTransactionReference={setTransactionReference}
            resetMyForm={resetMyForm}
            transactionData={transactionData}
            register={register}
            handleSubmit={handleSubmit}
            errors={errors}
            setLoadFee={setLoadFee}
            setAccountName={setAccountName}
            setValue={setValue}
            amount={amount}
            setAmountToSend={setAmountToSend}
            selectedAccount={selectedAccount}
            setSelectedAccount={setSelectedAccount}
            setSenderId={setSenderId}
          />
          <TransferSummary
            showModal={showTransferSummary}
            setShowModal={setShowTransferSummary}
            formInfo={formInfo}
            onSubmit={
              type === "Bank" ? handleBankTransfer : handleWalletTransfer
            }
            loading={loading}
            bank={bank}
            type={type}
            source={
              selectedAccount ? selectedAccountName : merchantDetails?.name
            }
            loadFee={loadFee}
            receiverDetails={receiverDetails}
            resetMyForm={resetMyForm}
            amount={amount}
            amountToSend={amountToSend}
            setShowPinModal={setShowPinModal}
            summaryAmount={summaryAmount}
          />
          <TransferDetailsModal
            type={type}
            showModal={showDetails}
            setShowModal={setShowDetails}
            selectedTransfer={selectedTransfer}
            setSelectedTransfer={setSelectedTransfer}
            receiverDetails={receiverDetails}
            id={selectedTransfer?.id}
            bank={bank}
            transactionReference={transactionReference}
            resetMyForm={resetMyForm}
            source={merchantDetails?.name}
          />
          <TransferListDetailsModal
            type={type}
            showModal={showListDetails}
            setShowModal={setShowListDetails}
            selectedTransfer={selectedList}
            setSelectedTransfer={setSelectedList}
            receiverDetails={receiverDetails}
            id={selectedList?.id}
            bank={bank}
            transactionReference={transactionReference}
            resetMyForm={resetMyForm}
            source={merchantDetails?.name}
          />
          <TransferStatus
            type={type}
            showModal={showStatus}
            setShowModal={setShowStatus}
            setShowDetails={setShowDetails}
            transactionData={transactionData}
            receiver={receiver}
            receiverEmail={receiverEmail}
            receiverDetails={receiverDetails}
            setSelectedTransfer={setSelectedTransfer}
            onSubmit={
              type === "Bank" ? handleBankTransfer : handleWalletTransfer
            }
            loading={loading}
            bank={bank}
            resetMyForm={resetMyForm}
            refetch={refetchTransfersList}
            formInfo={formInfo}
            amountToSend={amountToSend}
            setSelectedAccount={setSelectedAccount}
          />
          <Pin
            setPin={setPin}
            showModal={showPinModal}
            setShowModal={setShowPinModal}
            resetMyForm={resetMyForm}
            setSelectedTransfer={setSelectedTransfer}
            onSubmit={
              type === "Bank" ? handleBankTransfer : handleWalletTransfer
            }
            loading={loading}
            pin={pin}
            transactionData={transactionData}
          />
        </>
      )}
      {uploadStage && (
        <BulkTransfers
          uploadStage={uploadStage}
          setUploadStage={setUploadStage}
          formInfo={bulkInfo}
          setFormInfo={setBulkInfo}
          register={bulkRegister}
          handleSubmit={bulkHandleSubmit}
          setValue={bulkSetValue}
          watch={bulkWatch}
          errors={bulkErrors}
          reset={bulkReset}
          resetForm={resetMyForm}
          setViewTransfer={setViewTransfer}
          selectedAccount={selectedAccount}
          setSelectedAccount={setSelectedAccount}
          setSenderId={setSenderId}
        />
      )}
      {showDateModal && (
        <DateFilterModal
          startDate={searchState.from}
          setSearchState={setSearchState}
          endDate={searchState.to}
          searchState={searchState}
          setCloseModal={setShowDateModal}
          startDateName="from"
          endDateName="to"
          showModal={showDateModal}
        />
      )}
      {!uploadStage && (
        <>
          <hr className="" />
          <div className="bg-white flex flex-col h-screen w-full overflow-hidden">
            {!viewTransfer && (
              <div className="h-auto sm:h-16 flex items-center justify-between flex-col-reverse sm:flex-row  bg-white md:mx-10 w-screen sm:w-auto gap-y-2 sm:mt-0 sm:mb-1 md:mb-4 pt-9">
                <div className="flex items-center gap-x-10 sm:gap-0">
                  <Tab
                    text="Single Transfer"
                    path={`${ROUTES.SINGLE_TRANSFER}`}
                  />

                  <Tab text="Bulk Transfers" path={`${ROUTES.BULK_TRANSFER}`} />
                </div>
                <div className="flex sm:items-center gap-x-6 gap-y-4 items-stretch flex-col sm:flex-row">
                  {singleTransfer && (
                    <button
                      type="button"
                      onClick={() => setShowDateModal(true)}
                      className={`flex  items-center justify-center w-[20rem] sm:w-[10rem] h-10 px-4 text-[#5b5b5b] bg-[#F5F5F5] border border-[#EDEFF5] rounded-[4px] cursor-pointer gap-x-3`}
                    >
                      <FilterIcon />
                      Filter Transfer
                    </button>
                  )}
                  <button
                    type="button"
                    onClick={initiateTransfer}
                    className={`flex  items-center justify-center w-[20rem] sm:w-[10rem] h-10 px-4 bg-aellaBlue text-white rounded-[4px] cursor-pointer ${
                      listType === "single" ? "gap-x-4 sm:gap-x-2" : "gap-x-0"
                    }`}
                  >
                    New Transfer
                    <ChevronIcon
                      className={singleTransfer ? "block" : "hidden"}
                    />
                  </button>
                </div>
              </div>
            )}

            {renderBody()}
            <Outlet
              context={{
                singleTransfersList,
                singleTransfersListLoading,
                setSelectedList,
                setShowListDetails,
                setShowAction,
                merchantDetails,
                setViewTransfer,
                setSelectedBulkTransfer,
                uploadStage,
                viewTransfer,
                currentPage,
                setCurrentPage,
                searchState,
                setSearchState,
                postsPerPage,
                setPostsPerPage,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Transfers;

export function useTransfersData() {
  return useOutletContext<ContextType>();
}
